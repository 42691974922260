import http from "../httpService";
import { useQuery, useMutation, useQueryClient } from "react-query";

// GET maincategory for food menu.
const getMainCategories = () => {
	return http.get("/additional-info/cuisine-catagories");
};

export const useGetMainCategories = () => {
	return useQuery("cuisine-category", getMainCategories, {
		// select: (data) => data.data,
		staleTime: 5000,
	});
};

// GET subCategory for food menu
const getSubCategory = (id) => {
	// console.log(id);
	return http.get(`/restaurant-profile/${id}/menu/categories`);
};

export const useGetSubCategory = (id) => {
	return useQuery("sub-category", () => getSubCategory(id), {});
};

// Create or Update Sub Category

const putSubcategory = ({ id, data }) => {
	return http.put(`/restaurant-profile/${id}/menu/category`, {
		userName: id,
		...data,
	});
};

export const useUpdateSubcategory = () => {
	const queryClient = useQueryClient();

	return useMutation(putSubcategory, {
		onSuccess: () => {
			queryClient.invalidateQueries("sub-category");
		},
	});
};

// Delete Sub Category

const delSubcategory = ({ id, categoryId }) => {
	return http.delete(`/restaurant-profile/${id}/menu/category/${categoryId}`);
};

export const useDelSubcategory = () => {
	const queryClient = useQueryClient();

	return useMutation(delSubcategory, {
		onSuccess: () => {
			queryClient.invalidateQueries("sub-category");
		},
	});
};

// Create & Modify food item
const createFoodItem = ({ id, data }) => {
	const temp = {
		userName: id,
		...data,
	};
	const formData = new FormData();
	for (var key in temp) {
		formData.append(key, temp[key]);
	}
	return http.put(`/restaurant-profile/${id}/menu/food`, formData, {
		Headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

export const useCreateAndUpdateFoodItem = () => {
	const queryClient = useQueryClient();
	return useMutation(createFoodItem, {
		onSuccess: () => {
			queryClient.invalidateQueries("get-all-food");
		},
	});
};

// Get Food Item
export const getAllFood = (vendorId) => {
	return http.get(`/restaurant-profile/${vendorId}/menu/foods`);
};

export const useGetAllFood = (vendorId) => {
	return useQuery(["get-all-food", vendorId], () => getAllFood(vendorId), {
		select: (data) => data.data,
		enabled: !!vendorId,
	});
};

// DELETE food item API
const deleteFoodItem = ({ id, vendorId }) => {
	console.log(id, vendorId);
	return http.delete(`/restaurant-profile/${vendorId}/menu/food/${id}`);
};

export const useDeleteFoodItem = () => {
	const queryClient = useQueryClient();
	return useMutation(deleteFoodItem, {
		onSuccess: () => {
			queryClient.invalidateQueries("get-all-food");
		},
	});
};
