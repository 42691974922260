import React, { useState } from "react";
import {
	Button,
	Stack,
	Box,
	Grid,
	Paper,
	List,
	InputBase,
	ListItemButton,
	ListItemText,
	Hidden,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	IconButton,
	Divider,
	ListItem,
	ListItemSecondaryAction,
	Avatar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// component

// icons
import { FiSearch } from "react-icons/fi";
import RoleDrawer from "./RoleDrawer";

// component
import ShowAccessListItem from "./ShowAccessListItem";
import { useDeleteRole, useGetRoles } from "../../service/accesslist";
import userContext from "../../context/userInfo";
import { MdClose, MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { responseHandler } from "../../utilities/responseHandler";
import snackContext from "../../context/SnackProvider";
import AreYouSure from "../../components/AreYouSure";

const CreateACL = ({}) => {
	const theme = useTheme();
	const smallDown = useMediaQuery(theme.breakpoints.down("md"));

	const [openUpdateAclDrawer, setOpenUpdateAclDrawer] = useState(false);

	// state for acl
	const [user, setUser] = useState("");

	const { userInfo } = React.useContext(userContext);
	const snack = React.useContext(snackContext);

	const { data: roles } = useGetRoles(userInfo.VendorId);

	const [updateRoleId, setUpdateRoleId] = React.useState();

	const [selectedItem, setSelectedItem] = React.useState();
	// console.log(roles, selectedItem);
	const [open, setOpen] = React.useState(false);
	const [drawerOpen, setDrawerOpen] = useState(false);
	React.useEffect(() => {
		if (!updateRoleId) return;
		setDrawerOpen(!!updateRoleId);
	}, [updateRoleId]);

	React.useEffect(() => {
		if (selectedItem)
			setSelectedItem(
				roles?.filter?.((r) => r.roleID === selectedItem.roleID)[0]
			);
	}, [roles]);

	const { mutateAsync: deleteRole, isLoading: deleteLoading } = useDeleteRole();

	const [dlt, setDlt] = React.useState(false);
	const onDeleteRole = async () => {
		const res = await responseHandler(() =>
			deleteRole({
				roleId: selectedItem?.roleID,
			})
		);
		if (res.status) {
			setDlt(false);
			setOpen(false);
			snack.setSaverity("success");
			snack.setMessage("Updated successfully.");
			snack.handleClick();
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	return (
		<>
			<AreYouSure
				cancelOpen={dlt}
				handleFunc={onDeleteRole}
				handleOpen={() => setDlt((d) => !d)}
				message={`You want to delete role "${
					selectedItem?.roleName?.split?.(" ### ")[0]
				}"?`}
			/>
			<Stack
				direction="column"
				alignItems={"flex-end"}
				spacing={2}
				sx={{ width: "98%", margin: "0 auto", mt: 1 }}
			>
				<Button
					variant="contained"
					onClick={() => setDrawerOpen(true)}
				>
					Add new role
				</Button>

				<Grid
					container
					spacing={1}
					sx={{ height: `calc(100vh - 9rem)` }}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={3}
						lg={3}
					>
						<Paper
							elevation={10}
							sx={{
								borderRadius: 0,
								// border: "1px solid",
								borderColor: "primary.main",
								height: "100%",
								display: "flex",
								flexDirection: "column",
								boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
							}}
						>
							{/* Box for Margin */}
							<Box sx={{ p: 1.5 }}>
								{/* Search Input */}
								<InputBase
									startAdornment={
										// Start Icon of Search Field
										<FiSearch
											style={{
												marginRight: 10,
												fontSize: "1.5rem",
												color: theme.palette.primary.main,
											}}
										/>
									}
									name={"search"}
									autoComplete="off"
									placeholder={"Search Role"}
									fullWidth
									sx={{
										py: 1,
										px: 1.5,
										boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.12)",
										borderRadius: "100px",
									}}
									onChange={(e) => setUser(e.target.value)}
								/>
							</Box>
							{/* Divider */}

							{/* 
              A List Will be added here
              with a scrollable list container.
              *
              *
              *
             
            */}
							{/*  SideMenuLists */}
							<List
								sx={{
									// height: "75vh",
									flex: 1,
									overflowY: "auto",
									msOverflowStyle: "none",
									scrollbarWidth: "none",
									"&::-webkit-scrollbar": {
										display: "none",
									},
								}}
							>
								{/* User list for ACL */}

								{roles?.map?.((data) => {
									if (user === "") {
										return (
											<React.Fragment key={data?.roleID}>
												<ListItemButton
													variant="body1"
													color="initial"
													sx={{
														width: "100%",
														justifyContent: "flex-start",
														"&.Mui-selected": {
															background: theme.palette.primary.main,
															color: "#fff",
															"& .MuiListItemText-secondary": {
																color: "#ffffff99",
															},
														},
														"&.Mui-selected:hover": {
															background: theme.palette.primary.main,
														},
													}}
													selected={data.roleID === selectedItem?.roleID}
													onClick={() => {
														setSelectedItem(data);
														setOpen(true);
													}}
												>
													<ListItemText
														primary={data.roleName?.split?.(" ### ")[0]}
													/>
												</ListItemButton>
											</React.Fragment>
										);
									} else if (
										data.roleName.toUpperCase().includes(user.toUpperCase())
									) {
										return (
											<React.Fragment key={data?.roleID}>
												<ListItemButton
													variant="body1"
													color="initial"
													sx={{
														width: "100%",
														justifyContent: "flex-start",
														"&.Mui-selected": {
															background: theme.palette.primary.main,
															color: "#fff",
															"& .MuiListItemText-secondary": {
																color: "#ffffff99",
															},
														},
														"&.Mui-selected:hover": {
															background: theme.palette.primary.main,
														},
													}}
													selected={data.roleID === selectedItem?.roleID}
													onClick={() => {
														setSelectedItem(data);
														if (smallDown) {
															setOpen(true);
														} else {
															setOpen(false);
														}
													}}
												>
													<ListItemText
														primary={data.roleName?.split?.(" ### ")[0]}
													/>
												</ListItemButton>
											</React.Fragment>
										);
									}
								})}
								{/* component for SideMenuLists */}
							</List>
						</Paper>
					</Grid>
					<Hidden
						smDown
						mdDown
					>
						<Grid
							item
							container
							direction={"column"}
							xs={0}
							sm={7}
							md={9}
							sx={{}}
						>
							<Paper
								elevation={10}
								sx={{
									borderRadius: 0,
									// border: "1px solid",
									borderColor: "primary.main",
									height: "100%",
									boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
								}}
							>
								{selectedItem ? (
									<Stack
										direction={"column"}
										spacing={1}
										sx={{
											mx: "auto",
											mt: 2,
											width: "95%",
											// width: { xs: "90%", sm: "90%", md: "70%" },
										}}
									>
										<ListItem disablePadding>
											<ListItemText
												primary={selectedItem?.roleName?.split?.(" ### ")[0]}
												secondary={
													selectedItem?.isEditable ? "Editable" : "Not Editable"
												}
											/>
											{selectedItem?.isEditable && (
												<>
													<IconButton
														color={"primary"}
														onClick={() =>
															setUpdateRoleId({
																roleID: selectedItem?.roleID,
																roleName: selectedItem?.roleName,
																accessRightIDs: Array.from(
																	selectedItem?.rolesConfiguration || [],
																	(ar) => ar.arid
																),
															})
														}
													>
														<MdModeEditOutline />
													</IconButton>
													<IconButton
														color={"error"}
														onClick={() => setDlt((d) => !d)}
														disabled={deleteLoading}
													>
														<MdDeleteOutline />
													</IconButton>
												</>
											)}
											<IconButton
												onClick={() => {
													setOpen(false);
													setSelectedItem();
												}}
											>
												<MdClose />
											</IconButton>
										</ListItem>
										<Divider />
										{selectedItem
											? selectedItem?.rolesConfiguration?.map?.((data, i) => (
													<ShowAccessListItem
														data={data}
														key={data?.arid}
													/>
											  ))
											: null}
									</Stack>
								) : (
									<Stack
										direction={"column"}
										sx={{
											mx: "auto",
											minHeight: "70vh",
											mt: 2,
											width: "90%",
											alignItems: "center",
											justifyContent: "center",
											// width: { xs: "90%", sm: "90%", md: "70%" },
										}}
									>
										<Avatar
											src={"/rules-lineal.gif"}
											variant={"square"}
											sx={{
												width: "10rem",
												height: "10rem",
											}}
										/>
										<Typography color={"#888"}>Select Role to View</Typography>
									</Stack>
								)}
							</Paper>
							{/* <Grid item>
								{selectedItem ? (
									<Stack
										direction={"row"}
										spacing={1}
										sx={{
											display: "flex",
											justifyContent: "flex-end",
											alignItems: "flex-end",
											width: "100%",
											// mb: 2,
											mt: 2,
										}}
									>
										<Button
											variant="contained"
											color="error"
											disabled={!selectedItem?.isEditable}
										>
											Delete Role
										</Button>
										<Button
											variant="contained"
											color="success"
											disabled={!selectedItem?.isEditable}
											onClick={() =>
												setOpenUpdateAclDrawer(!openUpdateAclDrawer)
											}
										>
											update Role
										</Button>
									</Stack>
								) : null}
							</Grid> */}
						</Grid>
					</Hidden>
				</Grid>
			</Stack>
			{smallDown && (
				<Dialog
					open={open}
					onClose={() => {
						setSelectedItem();
						setOpen(!open);
					}}
					PaperProps={{ style: { width: "100%" } }}
					sx={{
						zIndex: (theme) => theme.zIndex.drawer - 1,
					}}
				>
					<DialogTitle
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							py: 1,
						}}
					>
						<ListItemText
							primary={selectedItem?.roleName}
							secondary={selectedItem?.isEditable ? "Editable" : "Not Editable"}
						/>
						{/* <Typography variant="caption">{}</Typography> */}
						<IconButton
							onClick={() => {
								setSelectedItem();
								setOpen(!open);
							}}
							size={"small"}
						>
							<MdClose />
						</IconButton>
					</DialogTitle>
					<Divider />
					<DialogContent>
						<Stack
							direction={"column"}
							spacing={1}
							sx={{
								py: 1,
							}}
						>
							{selectedItem
								? selectedItem.rolesConfiguration?.map?.((data) => (
										<ShowAccessListItem
											data={data}
											key={data?.arid}
										/>
								  ))
								: null}
						</Stack>
					</DialogContent>
					<Divider />
					<DialogActions>
						<Stack
							direction={"row"}
							spacing={1}
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "flex-end",
								width: "100%",
								// mb: 2,
								// mt: 2,
							}}
						>
							<Button
								variant="contained"
								color="error"
								disabled={!selectedItem?.isEditable}
								onClick={() => setDlt((d) => !d)}
							>
								Delete Role
							</Button>
							<Button
								variant="contained"
								color="success"
								disabled={!selectedItem?.isEditable}
								onClick={() =>
									setUpdateRoleId({
										roleID: selectedItem?.roleID,
										roleName: selectedItem?.roleName,
										accessRightIDs: Array.from(
											selectedItem?.rolesConfiguration || [],
											(ar) => ar.arid
										),
									})
								}
							>
								Update Role
							</Button>
						</Stack>
					</DialogActions>
				</Dialog>
			)}
			<RoleDrawer
				open={drawerOpen}
				setOpen={setDrawerOpen}
				roleId={updateRoleId}
				setRoleId={setUpdateRoleId}
			/>
		</>
	);
};

export default CreateACL;

/**
 *
 *  Developed by noman :)
 */
