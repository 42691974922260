import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { IoIosEye } from "react-icons/io";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import CampaignDrawer1 from "./CampaignDrawer1";

const FoodLists = () => {
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState([
    {
      id: 1,
      CampaignName: "Food day",
      Count: 10,
      created: "Wed, Jun 24, 2021 ",
      createdBy: "Manager",
    },
    {
      id: 2,
      CampaignName: "Valentine day",
      Count: 15,
      created: "Wed, Jun 24, 2021 ",
      createdBy: "Manager",
    },
    {
      id: 3,
      CampaignName: "Food day",
      Count: 5,
      created: "Wed, Jun 24, 2021 ",
      createdBy: "Manager",
    },
  ]);
  return (
    <>
      <Stack
        direction={"row"}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          width: "90vw",
          margin: "0 auto",
          // mr: { lg: 24, xs: 4.5, sm: 7.8, xms: 7.2 },
        }}
        spacing={1}
        mb={1}
      >
        <Button
          variant="contained"
          color="primary"
          open={open}
          onClick={() => setOpen(true)}
          // sx={{ height: "38px" }}
        >
          ADD NEW
        </Button>
      </Stack>
      <Box sx={{ width: "90vw", margin: "0 auto", mt: 2 }}>
        <TableContainer
          component={Paper}
          sx={
            {
              /*"-ms-overflow-style": { xs: "none", md: "unset" },
          scrollbarWidth: { xs: "none", md: "unset" },
          "&::-webkit-scrollbar": { display: { xs: "none", md: "unset" } },*/
            }
          }
        >
          <Table
            sx={{
              width: "100%",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#E3E3E3" }}>
                <TableCell align="center">ID</TableCell>
                <TableCell align="left">Food List Name</TableCell>
                <TableCell align="center">Count of Food item</TableCell>
                <TableCell align="center">Created On</TableCell>
                <TableCell align="center">Created By</TableCell>
                <TableCell align="center">View Details</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {info.map((info) => (
                <TableRow
                  key={info.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {info.id}
                  </TableCell>
                  <TableCell align="left">{info.CampaignName}</TableCell>
                  <TableCell align="center">{info.Count}</TableCell>
                  <TableCell align="center">{info.created}</TableCell>
                  <TableCell align="center">{info.createdBy}</TableCell>
                  <TableCell
                    sx={{
                      color: "#C4C4C4",
                      textAlign: "center",
                    }}
                  >
                    <IconButton>
                      <IoIosEye style={{ fontSize: "20px" }} />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <Stack
                      direction={"row"}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1.5,
                        color: "#C4C4C4",
                      }}
                    >
                      <IconButton>
                        <BiEdit style={{ fontSize: "18px" }} />
                      </IconButton>
                      <IconButton>
                        <RiDeleteBin5Line style={{ fontSize: "18px" }} />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CampaignDrawer1 open={open} setOpen={setOpen} />
    </>
  );
};

export default FoodLists;
/**
 * create by md masum
 */
