import { Alert, Collapse } from "@mui/material";
import React from "react";

const ShowErr = ({ obj, fieldName }) => {
	const [open, setOpen] = React.useState(false);
	React.useEffect(() => setOpen(!!obj), [obj]);
	return (
		<Collapse
			in={open}
			sx={{
				width: "100%",
				my: 0,
			}}
		>
			<Alert
				severity="error"
				sx={{
					width: "100%",
					mt: 1,
				}}
				onClose={() => setOpen(false)}
			>
				{obj?.type === "required"
					? `${fieldName || "This Field"} is Required!`
					: obj?.type === "pattern"
					? `Invalid ${fieldName || "data"}!`
					: obj?.message || "Something wrong here!"}
			</Alert>
		</Collapse>
	);
};

export default ShowErr;
