import http from "./httpService";
import { useMutation, useQueryClient } from "react-query";

// CREATE table for floor.
const createTable = ({ vendorId, floorId, data }) => {
	return http.post(
		`/restaurant-profile/${vendorId}/create-table/${floorId}`,
		data
	);
};

export const useCreateTable = () => {
	const queryClient = useQueryClient();
	return useMutation(createTable, {
		onSuccess: () => {
			queryClient.invalidateQueries("get-floor-info");
		},
	});
};

// UPDATE table for floor.
const updateTable = ({ vendorId, floorId, data }) => {
	return http.put(
		`/restaurant-profile/${vendorId}/update-table/${floorId}`,
		data
	);
};

export const useUpdateTable = () => {
	const queryClient = useQueryClient();
	return useMutation(updateTable, {
		onSuccess: () => {
			queryClient.invalidateQueries("get-floor-info");
		},
	});
};

// DELETE  all tables for specific floor.
const deleteTable = ({ vendorId, floorId, tableId }) => {
	return http.delete(
		`/restaurant-profile/${vendorId}/delete-table/${floorId}/${tableId}`
	);
};

export const useDeleteTable = () => {
	const queryClient = useQueryClient();
	return useMutation(deleteTable, {
		onSuccess: () => {
			queryClient.invalidateQueries("get-floor-info");
		},
	});
};
