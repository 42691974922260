import React from "react";
import { Box, Stack } from "@mui/material";
import { Link, useLocation, Routes, Route } from "react-router-dom";

// Components
import CTab1 from "../../components/CTab1";
import CustomisedTabs1 from "../../components/CustomisedTabs1";
import RestaurantContact from "./restaurantContact";
import PhotosTabForRestaurantProfile from "./photos";
import RestaurantProfileMenu from "./menu";
import RestaurantProfileOverview from "./overview";
import RestaurantProfileOpeningHours from "./openingHours";
import { AccessMargin, checkAccess } from "@tam11a/react-use-access";

const labels = [
	{
		label: "Overview",
		value: "/restaurant-profile",
		permissions: ["VendorOverviewReadOnly", "VendorOverviewRead&Write"],
	},
	{
		label: "Contact",
		value: "/restaurant-profile/contact",
		permissions: ["VendorContactReadOnly", "VendorContactRead&Write"],
	},
	{
		label: "Menu",
		value: "/restaurant-profile/menu",
		permissions: ["VendorMenuReadOnly", "VendorMenuRead&Write"],
	},
	{
		label: "Photos",
		value: "/restaurant-profile/image",
		permissions: ["VendorPhotosRead&Write", "VendorPhotosReadOnly"],
	},
	{
		label: "Opening Hours",
		value: "/restaurant-profile/opening-hours",
		permissions: ["VendorOpeningHoursRead&Write", "VendorOpeningHoursReadOnly"],
	},
];

const RestaurantProfile = () => {
	const location = useLocation().pathname;

	return (
		<Stack
			direction={"column"}
			alignItems={"center"}
			justifyContent={"flex-start"}
			minHeight={"90vh"}
			minWidth={"100vw"}
		>
			<CustomisedTabs1
				value={location}
				// onChange={handleInfoTabChange}
				sx={{
					width: "100%",
					maxWidth: { xs: "95vw", md: "800px" },
					my: 3,
				}}
				variant="scrollable"
				scrollButtons="auto"
			>
				{labels.map((level, id) => (
					<CTab1
						key={id}
						label={level.label}
						value={level.value}
						component={Link}
						to={level.value}
						disabled={!checkAccess(level.permissions)}
					/>
				))}
			</CustomisedTabs1>
			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"flex-start"}
				width={"95vw"}
				maxWidth={{ xs: "95vw", md: "810px" }}
			>
				<Routes path={"restaurant-profile"}>
					<Route
						path={"contact/*"}
						element={
							<AccessMargin
								to={["VendorContactReadOnly", "VendorContactRead&Write"]}
								defaultFallback
							>
								<RestaurantContact />
							</AccessMargin>
						}
					/>
					<Route
						path={"menu/*"}
						element={
							<AccessMargin
								to={["VendorMenuReadOnly", "VendorMenuRead&Write"]}
								defaultFallback
							>
								<RestaurantProfileMenu />
							</AccessMargin>
						}
					/>
					<Route
						path={"image/*"}
						element={
							<AccessMargin
								to={["VendorPhotosRead&Write", "VendorPhotosReadOnly"]}
								defaultFallback
							>
								<PhotosTabForRestaurantProfile />
							</AccessMargin>
						}
					/>
					<Route
						path={"opening-hours/*"}
						element={
							<AccessMargin
								to={[
									"VendorOpeningHoursRead&Write",
									"VendorOpeningHoursReadOnly",
								]}
								defaultFallback
							>
								<RestaurantProfileOpeningHours />
							</AccessMargin>
						}
					/>
					<Route
						index
						element={
							<AccessMargin
								to={["VendorOverviewReadOnly", "VendorOverviewRead&Write"]}
							>
								<RestaurantProfileOverview />
							</AccessMargin>
						}
					/>
				</Routes>
			</Box>
		</Stack>
	);
};

export default RestaurantProfile;
