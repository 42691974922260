import { InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
const CustomisedInput1 = styled(InputBase)(({ theme }) => ({
	border: "1px solid #ddd",
	// minHeight: "48px",
	padding: 1.5,
	paddingLeft: "0.5rem",
	paddingRight: "0.5rem",
	borderRadius: "5px",
	alignItems: "center",
	display: "flex",
	// boxShadow: "0px 0px 4px rgba(130, 107, 193, 0.25)",
}));
export default CustomisedInput1;
/**
 * customisedinput1 component for whole application.
 * used in login page .
 *
 * createed by masum :)
 *
 */
