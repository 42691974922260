import React from "react";
import { Button } from "@mui/material";
import { Popover, Box } from "@mui/material";
import TimeKeeper from "react-timekeeper";
import theme from "../../../components/theme";

const OpeningHoursButton = ({ toggle, time, setTime }) => {
  const [value, setValue] = React.useState(time);
  const [openClock, setOpenClock] = React.useState(false);
  React.useEffect(() => {
    if (openClock) setValue(time);
  }, [openClock]);
  document.documentElement.style.setProperty(
    "--top-selected-color",
    theme.palette.primary.main
  );
  document.documentElement.style.setProperty("--hand-circle-outer", "#EFECF2");
  document.documentElement.style.setProperty(
    "--meridiem-selected-bg-color",
    "#EFECF2"
  );
  document.documentElement.style.setProperty("--hand-circle-center", "#EFECF2");
  document.documentElement.style.setProperty("--hand-line-color", "#EFECF2");

  return (
    <>
      <Button
        disabled={toggle}
        variant="outlined"
        sx={{
          fontSize: "0.8rem",
          p: 0.5,
        }}
        onClick={() => setOpenClock(true)}
        noWrap={"wrap"}
      >
        {time}
      </Button>

      <Popover
        BackdropProps={{
          style: {
            background: "#00000066",
          },
          onClick: () => setOpenClock(false),
        }}
        open={openClock}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "& .react-timekeeper": {
              boxShadow: "none !important",
            },
          },
        }}
      >
        <TimeKeeper
          switchToMinuteOnHourSelect
          onDoneClick={() => {
            setTime(value);
            setOpenClock(false);
          }}
          onChange={(newTime) => setValue(newTime.formatted12)}
          time={value}
        />
        <Button
          fullWidth
          size={"large"}
          color={"background2"}
          onClick={() => {
            setTime("00:00");
            setOpenClock(false);
          }}
        >
          RESET
        </Button>
      </Popover>
    </>
  );
};

export default OpeningHoursButton;
