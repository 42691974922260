import { Stack } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import CTab1 from "../../components/CTab1";
import CustomisedTabs1 from "../../components/CustomisedTabs1";
import Help from "./help";
import Feedback from "./feedback";
import Account from "./account";

const Setting = () => {
	const location = useLocation().pathname;
	return (
		<>
			<Stack
				direction={"column"}
				alignItems={"center"}
				justifyContent={"flex-start"}
				minHeight={"90vh"}
				minWidth={"100vw"}
			>
				<CustomisedTabs1
					value={location}
					// onChange={handleInfoTabChange}
					variant="scrollable"
					scrollButtons="auto"
					sx={{
						width: "90vw",
						maxWidth: "600px",
						my: 3,
					}}
				>
					<CTab1
						label="Account"
						value="/setting"
						component={Link}
						to={"/setting"}
					/>
					<CTab1
						label="Help"
						value="/setting/help"
						component={Link}
						to={"/setting/help"}
					/>
					{/* <CTab1
            label="Feedback"
            value="/setting/feedback"
            component={Link}
            to={"/setting/feedback"}
          /> */}
				</CustomisedTabs1>{" "}
				{location === "/setting" && <Account />}
				{location === "/setting/help" && <Help />}
				{location === "/setting/feedback" && <Feedback />}
			</Stack>
		</>
	);
};

export default Setting;
/**
 * create by md masum
 */
