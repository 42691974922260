import React from "react";
import { MdEdit, MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import {
  MenuItem,
  Select,
  Divider,
  Typography,
  IconButton,
  Button,
  Stack,
  InputBase,
  useMediaQuery,
} from "@mui/material";
import theme from "./theme";

/***
 * placeholder (required)
 * id (required)
 *
 */

const RitSelect = ({
  menuList,
  placeholder,
  id,
  defaultValue,
  onSelect,
  onAdd,
  onUpdate,
  onDelete,
  buttonProps,
  ...others
}) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue);
  const [formAction, setFormAction] = React.useState({
    add: false,
    update: false,
  });
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [addInputValue, setAddInputValue] = React.useState("");
  const [UpdateInputValue, setUpdateInputValue] = React.useState("");
  React.useEffect(() => {
    if (value) {
      if (typeof onSelect === "function")
        onSelect(menuList.find((o) => o.value === value));
      setFormAction({
        add: false,
        update: false,
      });
    }
  }, [value]);

  // reset value on menulist change
  React.useEffect(() => {
    setValue();
  }, [menuList]);

  React.useEffect(() => {
    if (!formAction.add) setAddInputValue("");
    if (!formAction.update) setUpdateInputValue("");
  }, [formAction]);

  const [selectId, setSelectId] = React.useState(id || Date.now());

  return (
    <>
      <Button
        id={selectId}
        onClick={() => setOpen(!open)}
        variant={"outlined"}
        sx={{
          fontWeight: "bold",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        endIcon={
          open ? (
            <MdArrowDropUp style={{ fontSize: "1.5rem" }} />
          ) : (
            <MdArrowDropDown style={{ fontSize: "1.5rem" }} />
          )
        }
        // fullWidth
        size={mdDown ? "small" : "large"}
        {...buttonProps}
      >
        {(value &&
          menuList.find((o) => o.value === value) &&
          menuList.find((o) => o.value === value).title) ||
          placeholder}
      </Button>
      <Select
        size={"small"}
        open={open}
        // name={name}
        onOpen={() => setOpen(!open)}
        onKeyDown={(e) => e.preventDefault()}
        sx={{
          display: "none",
        }}
        MenuProps={{
          anchorEl: document.getElementById(selectId),
          disableAutoFocusItem: true,
          PaperProps: {
            sx: {
              mt: 1,
              border: "1px solid #999",
              background: "#F3F3F3",
            },
          },
          MenuListProps: {
            sx: {
              minWidth: "190px",
              "& button": {
                textTransform: "none",
              },
              "& .MuiMenuItem-root.Mui-selected": {
                background: "transparent",
              },
            },
          },
          onKeyDown: () => {},
          onBackdropClick: () => {
            setOpen(!open);
            setFormAction({
              add: false,
              update: false,
            });
          },
        }}
        // value={value}
        {...others}
      >
        {menuList?.map((item) => (
          <MenuItem
            sx={{
              justifyContent: "space-between",
              columnGap: 4,
            }}
            value={item.value}
            key={item.value}
          >
            <Typography
              sx={{
                width: "100%",
              }}
              onClick={() => {
                setValue(item.value);
                setOpen(!open);
              }}
            >
              {item.title}
            </Typography>
            <IconButton
              size={"small"}
              onClick={() => {
                setUpdateInputValue(item);
                setFormAction({
                  add: false,
                  update: true,
                });
              }}
            >
              <MdEdit />
            </IconButton>
          </MenuItem>
        ))}
        <Divider />

        <Stack
          direction="column"
          width="90%"
          sx={{
            marginX: "auto",
            rowGap: 0.7,
          }}
        >
          {!formAction.add && !formAction.update ? (
            <Button
              variant="contained"
              onClick={() =>
                setFormAction({
                  ...formAction,
                  add: true,
                })
              }
              fullWidth
            >
              Add
            </Button>
          ) : formAction.add ? (
            <form
              id={"add" + selectId}
              onSubmit={async (e) => {
                e.preventDefault();
                if (typeof onAdd === "function") {
                  const data = new FormData(e.target);
                  await onAdd({ title: data.get("title") });
                }
                setFormAction({
                  ...formAction,
                  add: false,
                });
                setAddInputValue("");
                //  setValue();
              }}
            >
              <Stack
                direction={"column"}
                sx={{
                  rowGap: 0.7,
                }}
              >
                <InputBase
                  sx={{
                    border: "1px solid",
                    borderColor: "primary.main",
                    borderRadius: "4px",
                    px: 1,
                  }}
                  form={"add" + selectId}
                  name={"title"}
                  value={addInputValue}
                  onChange={(e) => setAddInputValue(e.target.value)}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button
                  variant="contained"
                  form={"add" + selectId}
                  type={"submit"}
                  fullWidth
                >
                  Add
                </Button>
                <Button
                  variant="outlined"
                  color={"error"}
                  onClick={() =>
                    setFormAction({
                      add: false,
                      update: false,
                    })
                  }
                  fullWidth
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          ) : formAction.update ? (
            <form
              id={"update" + selectId}
              onSubmit={async (e) => {
                e.preventDefault();
                if (typeof onUpdate === "function") {
                  const data = new FormData(e.target);
                  await onUpdate({
                    title: data.get("title"),
                    info: UpdateInputValue,
                  });
                }
                setUpdateInputValue("");
                setValue();
                setFormAction({
                  ...formAction,
                  update: false,
                });
              }}
            >
              <Stack
                direction={"column"}
                sx={{
                  rowGap: 0.7,
                }}
              >
                <InputBase
                  sx={{
                    border: "1px solid",
                    borderColor: "primary.main",
                    borderRadius: "4px",
                    px: 1,
                  }}
                  form={"update" + selectId}
                  name={"title"}
                  value={UpdateInputValue.title}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    setUpdateInputValue({
                      ...UpdateInputValue,
                      title: e.target.value,
                    })
                  }
                />
                <Button
                  variant="contained"
                  form={"update" + selectId}
                  fullWidth
                  type="submit"
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  color={"error"}
                  onClick={async () => {
                    if (typeof onDelete === "function") {
                      await onDelete(UpdateInputValue);
                    }
                    setFormAction({
                      ...formAction,
                      update: false,
                    });
                    setUpdateInputValue("");
                    setValue();
                  }}
                  fullWidth
                >
                  Delete
                </Button>
                <Button
                  variant="outlined"
                  color={"error"}
                  onClick={() =>
                    setFormAction({
                      add: false,
                      update: false,
                    })
                  }
                  fullWidth
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          ) : (
            <></>
          )}
        </Stack>
      </Select>
    </>
  );
};

export default RitSelect;
