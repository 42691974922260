const Joi = require("joi");

export const createPasswordValidation = Joi.object({
  //   phone: Joi.string().required(),
  password: Joi.string()
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)
    .error((errors) => {
      errors.forEach((err) => {
        // console.log(err.code)
        switch (err.code) {
          case "string.pattern.base":
            err.message =
              "Password must be contain at least 8 characters, including UPPER/lowercase and number";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
  ConfirmPassword: Joi.ref("password"),
});
