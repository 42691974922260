import React from "react";
import {
  Divider,
  MenuItem,
  Stack,
  Typography,
  Select,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { throttle } from "throttle-debounce";

// component
import CustomisedInput1 from "../../components/CustomisedInput1";

// API
import { useCreateTable } from "../../service/tableService";
import { responseHandler } from "../../utilities/responseHandler";

//context
import snackContext from "../../context/SnackProvider";

/**
 *
 * Here need a props, comming from Floor&TableCreation.js file
 * Using this props upload some table information through api end point.
 *
 */

const CreateTableFormForFloor = ({ vendorId, floor, onClose }) => {
  const snack = React.useContext(snackContext);

  const { mutateAsync: createTable } = useCreateTable();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors: err },
  } = useForm();

  const createTableFunc = async (data) => {
    const res = await responseHandler(
      () =>
        createTable({
          vendorId: vendorId,
          floorId: floor,
          data: {
            ...data,
            isBlocked: false,
          },
        }),
      201
    );
    if (res.status) {
      onClose();
      snack.setSaverity("success");
      snack.setMessage("Created successfully.");
      snack.handleClick();
    } else {
      snack.setSaverity("error");
      snack.setMessage(res.data);
      snack.handleClick();
    }
    reset();
  };

  return (
    <>
      {/* <form> */}
      <Stack component={"form"} onSubmit={handleSubmit(createTableFunc)}>
        <Typography variant="cardHeader">Table Properties</Typography>
        <Divider sx={{ my: 1 }} />
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="cardHeader">Table No.</Typography>
          <CustomisedInput1
            sx={{ width: "3rem" }}
            {...register("tableNo", { required: true, valueAsNumber: true })}
            type={"number"}
            required
          />
        </Stack>
        <Divider sx={{ my: 1 }} />

        {/* <Stack spacing={2}>
          <Typography variant="cardHeader">Table Type</Typography>
          <Select fullWidth size={"small"} >
            <MenuItem value="regular">Regular</MenuItem>
            <MenuItem value="special">Special</MenuItem>
            <MenuItem value="irregular">Irregular</MenuItem>
          </Select>
        </Stack> 
        <Divider sx={{ my: 1 }} /> */}

        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="cardHeader">Min Party</Typography>
          <CustomisedInput1
            sx={{ width: "3rem" }}
            {...register("minParty", { required: true, valueAsNumber: true })}
            type={"number"}
            required
          />
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="cardHeader">Max Party</Typography>
          <CustomisedInput1
            sx={{ width: "3rem" }}
            {...register("maxParty", { required: true, valueAsNumber: true })}
            type={"number"}
            required
          />
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Button variant="contained" fullWidth type="submit">
          Add
        </Button>
      </Stack>
      {/* </form> */}
    </>
  );
};

export default CreateTableFormForFloor;

/**
 *
 * this file export for create table section for floor management section.
 *
 * developed by noman :)
 */
