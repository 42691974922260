import {
	AppBar,
	Box,
	IconButton,
	Stack,
	Toolbar,
	Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { BiMenuAltLeft } from "react-icons/bi";
import { Link, Route, Routes } from "react-router-dom";
import AppLogo from "../../assets/Reserve it-01 1.svg";
import NavigationDrawer from "./NavigationDrawer";
import { useLocation } from "react-router-dom";
import userContext from "../../context/userInfo";

const Header = () => {
	const [headerTitles] = React.useState([
		{
			path: "/",
			title: "Dashboard",
		},
		{
			path: "/dashboard/*",
			title: "Dashboard",
		},
		{
			path: "/setting/*",
			title: "Settings",
		},
		{
			path: "/restaurant-profile/*",
			title: "Restaurant Profile",
		},
		{
			path: "/floor-and-table-creation/*",
			title: "Floor Table Creation",
		},
		{
			path: "/create-acl/*",
			title: "Access List",
		},
		{
			path: "/create-user-acl/*",
			title: "User Accounts",
		},
		{
			path: "/discount/*",
			title: "Discount",
		},
		{
			path: "/activity-log/*",
			title: "Activity Log",
		},
	]);
	// State For drawer component
	const [open, setOpen] = React.useState(false);
	const toggleDrawer = () => {
		setOpen(!open);
	};
	return (
		<>
			<AppBar sx={{ pt: 0.5, px: { xs: 0, sm: 1, md: 2 } }}>
				<Toolbar
					disableGutters
					sx={{
						justifyContent: "space-between",
					}}
				>
					<Stack
						direction={"row"}
						alignItems={"center"}
						columnGap={1}
					>
						{/* Open Menu Icons */}
						<IconButton
							color="white"
							onClick={toggleDrawer}
						>
							<BiMenuAltLeft />
						</IconButton>
						{/* Route Name */}
						<Typography variant={"normal"}>
							{/* {headerTitles[window.location.pathname].title} */}
							{/* {headerTitles[`/${location.pathname.split("/")[1]}`].title} */}
							<Routes>
								{headerTitles?.map((perRoute) => (
									<Route
										key={perRoute.path}
										path={perRoute.path}
										element={perRoute.title}
									/>
								))}
							</Routes>
						</Typography>
					</Stack>
					<Link to="/dashboard">
						<img
							src={AppLogo}
							style={{ height: "40px", marginRight: "10px" }}
						/>
					</Link>
				</Toolbar>
			</AppBar>
			<NavigationDrawer
				open={open}
				handleDrawer={toggleDrawer}
			/>
		</>
	);
};

export default Header;
