import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const CBox = styled(Box)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette.white.main,
  boxShadow: "0px 4px 20px rgb(0, 0, 0, 10%)",
  padding: 10,
  [theme.breakpoints.down("md")]: {
    borderRadius: "4px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.25)",
  },
}));

export default CBox;

/***
 * CBox ( Customised Box )
 *
 * - Customised Box is for the White Box Container with Box Shadow and 10px Padding.
 *
 *
 *
 * Developed by Tam.
 * ***/
