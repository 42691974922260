import React, { useCallback, useContext } from "react";
import { Stack, Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { throttle } from "throttle-debounce";

//components
import CustomisedInput1 from "../CustomisedInput1";

//themes
import theme from "../theme";

//image
import svg3 from "../../assets/forgot password.svg";
import toggleModal from "../../utilities/toggleModal";

// Context API
import snackContext from "../../context/SnackProvider";

// API
import { responseHandler } from "../../utilities/responseHandler";
import { sendOTP } from "../../service/userService/authService";

// forgot password area
const ForgotPassword = ({ setOpenForgetPass, setOpenVerifyPage2 }) => {
  const snack = useContext(snackContext);
  const { handleSubmit, register } = useForm();

  const sendPhoneNumber = async (data) => {
    const res = await responseHandler(() => sendOTP(data), 200);
    console.log(res);
    if (res.status) {
      snack.setSaverity("success");
      snack.setMessage("Your OTP sent successfully");
      snack.handleClick();
      sessionStorage.setItem("VendorFPT", res.data);
      sessionStorage.setItem("phone", data.phone);
      toggleModal(setOpenForgetPass, setOpenVerifyPage2);
    } else {
      snack.setSaverity("error");
      snack.setMessage(res.data);
      snack.handleClick();
    }
    // console.log(res);
  };

  const sendPhoneNumberWithThr = useCallback(
    throttle(2000, sendPhoneNumber, { noLeading: true })
  );

  return (
    <>
      {/* stack */}
      <Stack
        direction="column"
        spacing={2}
        component="form"
        onSubmit={handleSubmit(sendPhoneNumberWithThr)}
      >
        {/* Typography */}
        <Typography variant="subtitle2">
          Please enter your phone number to retrieve your verification code.
          <hr width="100%" color={theme.palette.primary.main} />
        </Typography>
        {/* image area */}
        <img
          src={svg3}
          alt="forget password"
          style={{ maxWidth: "225px", margin: "0 auto" }}
        />
        {/* Inputfield for inputField component */}

        <CustomisedInput1
          required
          placeholder="Enter your number"
          type="number"
          fullWidth
          {...register("phone")}
        />
        {/* button */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          // onClick={() => toggleModal(setOpenForgetPass, setOpenVerifyPage2)}
        >
          Send
        </Button>
      </Stack>
    </>
  );
};

export default ForgotPassword;
/*
## TODO:

1. Responsive ForgotPassword Component to View in Modal.
2. Please Enter your Email or phone No. to retrieve your Varification code.
3. then you can check you mail box.




Done by - masum {:) .
*/
