import axios from "axios";

export const backendURL = "https://api.reserveitbd.com/api";

const http = axios.create({
	baseURL: backendURL,
	timeout: 10000,
	headers: {
		Authorization: localStorage.getItem("token")
			? `Bearer ${localStorage.getItem("token")}`
			: "",
		accept: "*/*",
	},
});

export const updateInstanceAuthorization = () => {
	http.interceptors.request.use((req) => {
		req.headers["Authorization"] = localStorage.getItem("token")
			? `Bearer ${localStorage.getItem("token")}`
			: "";
		return req;
	});
};
export default http;
