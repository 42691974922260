import React from "react";
import {
  Avatar,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import svgUrl from "../../../assets/MessageIcon.svg";
import moment from "moment";

// icons
import { MdClose } from "react-icons/md";
import { ImAttachment, ImCamera, ImImage, ImMic } from "react-icons/im";
import { IoIosSend } from "react-icons/io";
const ChatBox = ({ onClose }) => {
  const [messages, setMessages] = React.useState([]);

  React.useEffect(() => {
    setMessages([
      {
        author: "system manager",
        msg: `Good!`,
      },
      {
        author: "manager",
        msg: `In publishing and graphic design, Lorem ipsum is 
    a placeholder text commonly used to 
    demonstrate the visual form of a document or.`,
      },
      {
        author: "you",
        msg: `Thank You!`,
      },
      {
        author: "system manager",
        msg: `Good!`,
      },
      {
        author: "manager",
        msg: `In publishing and graphic design, Lorem ipsum is 
    a placeholder text commonly used to 
    demonstrate the visual form of a document or.`,
      },
      {
        author: "you",
        msg: `Thank You!`,
      },
      {
        author: "system manager",
        msg: `Good!`,
      },
      {
        author: "manager",
        msg: `In publishing and graphic design, Lorem ipsum is 
    a placeholder text commonly used to 
    demonstrate the visual form of a document or.`,
      },
      {
        author: "you",
        msg: `Thank You!`,
      },
      {
        author: "system manager",
        msg: `Good!`,
      },
      {
        author: "manager",
        msg: `In publishing and graphic design, Lorem ipsum is 
a placeholder text commonly used to 
demonstrate the visual form of a document or.`,
      },
      {
        author: "you",
        msg: `Thank You!`,
      },
      {
        author: "system manager",
        msg: `Good!`,
      },
      {
        author: "manager",
        msg: `In publishing and graphic design, Lorem ipsum is 
a placeholder text commonly used to 
demonstrate the visual form of a document or.`,
      },
      {
        author: "you",
        msg: `Thank You!`,
      },
      {
        author: "system manager",
        msg: `Good!`,
      },
      {
        author: "manager",
        msg: `In publishing and graphic design, Lorem ipsum is 
a placeholder text commonly used to 
demonstrate the visual form of a document or.`,
      },
      {
        author: "you",
        msg: `Thank You!`,
      },
      {
        author: "system manager",
        msg: `Good!`,
      },
      {
        author: "manager",
        msg: `In publishing and graphic design, Lorem ipsum is 
a placeholder text commonly used to 
demonstrate the visual form of a document or.`,
      },
      {
        author: "you",
        msg: `Thank You!`,
      },
      {
        author: "system manager",
        msg: `Good!`,
      },
      {
        author: "manager",
        msg: `In publishing and graphic design, Lorem ipsum is 
a placeholder text commonly used to 
demonstrate the visual form of a document or.`,
      },
      {
        author: "you",
        msg: `Thank You!`,
      },
      {
        author: "manager",
        msg: `In publishing and graphic design, Lorem ipsum is 
a placeholder text commonly used to 
demonstrate the visual form of a document or.`,
      },
      {
        author: "you",
        msg: `Thank You!`,
      },
    ]);
  }, []);
  return (
    <>
      <DialogTitle sx={{ p: 0 }}>
        <Paper elevation={5}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              px: 2,
              py: 1,
            }}
          >
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"center"}
              spacing={1}
            >
              <Avatar src={svgUrl} variant="rounded" />
              <Typography variant={"cardHeader"}>ReserveitBD</Typography>
            </Stack>
            <IconButton color={"primary"} onClick={() => onClose()}>
              <MdClose />
            </IconButton>
          </Stack>
        </Paper>
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
        }}
      >
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          height={"70vh"}
          sx={{ overflowY: "auto", px: { xs: 1, md: 2 }, py: 1.5 }}
          spacing={2}
        >
          {messages?.map((message, index) => (
            <Stack
              direction={"column"}
              sx={{
                width: "100%",
              }}
              alignItems={message.author === "you" ? "flex-end" : "flex-start"}
            >
              <Typography
                color={"primary"}
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                  textTransform: "capitalize",
                }}
              >
                {message.author}
              </Typography>
              <Paper
                elevation={4}
                sx={{
                  boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
                  p: 1,
                }}
              >
                <Typography
                  component={"pre"}
                  variant={"normal"}
                  sx={{ fontFamily: "'Karla', sans-serif" }}
                >
                  {message.msg}
                </Typography>
                <Typography
                  component={"pre"}
                  variant={"normal"}
                  sx={{
                    fontFamily: "'Karla', sans-serif",
                    float: "right",
                    fontSize: "0.7rem",
                  }}
                >
                  {moment().calendar()}
                </Typography>
              </Paper>
            </Stack>
          ))}
        </Stack>
        <Paper elevation={6} sx={{ py: 1 }}>
          <Stack direction={"row"}>
            <IconButton color={"primary"}>
              <ImAttachment />
            </IconButton>
            <IconButton color={"primary"}>
              <ImCamera />
            </IconButton>
            <IconButton color={"primary"}>
              <ImImage />
            </IconButton>
            <IconButton color={"primary"}>
              <ImMic />
            </IconButton>
            <InputBase
              sx={{
                border: "1.8px solid",
                borderColor: "primary.light",
                borderRadius: "6px",
                background: "rgba(64, 27, 96, 0.06)",
                px: 1,
              }}
              multiline
              maxRows={2}
              fullWidth
              placeholder={"Aa.."}
            />
            <IconButton color={"primary"}>
              <IoIosSend />
            </IconButton>
          </Stack>
        </Paper>
      </DialogContent>
    </>
  );
};

export default ChatBox;

/**
 * Create by Tam
 * reused from vendor management
 * Added:md masum
 */
