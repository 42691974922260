import {
	Box,
	Button,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Chip,
	IconButton,
} from "@mui/material";
import React, { useState } from "react";

//Components
import UserProfileCration from "./UserProfileCration";
import { useGetUsers } from "../../service/accesslist";
import userContext from "../../context/userInfo";
import DataTable from "../../components/DataTable";
import moment from "moment";
import UpdateUserProfile from "./UpdateUserProfile";
import { MdModeEditOutline } from "react-icons/md";

const CreateUserForACL = () => {
	const [open, setOpen] = useState(false);

	const { userInfo } = React.useContext(userContext);

	const { data, isLoading } = useGetUsers(userInfo.VendorId);

	const [selectedUser, setSelectedUser] = React.useState();
	const handleSelectedUser = () => setSelectedUser();

	const cols = [
		{
			headerName: "Name",
			field: "firstName",
			headerAlign: "center",
			align: "center",
			// flex: 1,
			minWidth: 250,
			renderCell: (data) => `${data.row.firstName} ${data.row.lastName}`,
		},
		{
			headerName: "Role",
			field: "role",
			width: 200,
			headerAlign: "center",
			align: "center",
			renderCell: (data) =>
				data.row?.role?.roleName ? (
					<Chip label={data.row?.role?.roleName} />
				) : (
					"-"
				),
		},
		{
			headerName: "Phone",
			field: "phone",
			width: "130",
			headerAlign: "center",
			align: "center",
		},
		{
			headerName: "Email",
			field: "email",
			width: "200",
			headerAlign: "center",
			align: "center",
		},
		// {
		// 	headerName: "",
		// 	field: "space",
		// 	headerAlign: "center",
		// 	align: "center",
		// 	flex: 1,
		// },
		{
			headerName: "Created at",
			field: "createdOn",
			width: 220,
			headerAlign: "center",
			align: "center",
			renderCell: (data) => moment(data.row?.createdOn).format("llll"),
		},
		{
			headerName: "Created By",
			field: "createdBy",
			width: 170,
			headerAlign: "center",
			align: "center",
			renderCell: (data) =>
				data.row?.createdBy ? (
					<Chip
						label={data.row?.createdBy}
						sx={{
							textTransform: "uppercase",
						}}
					/>
				) : (
					"-"
				),
		},
		{
			headerName: "",
			field: "action",
			width: 60,
			headerAlign: "center",
			align: "center",
			renderCell: (data) => (
				<>
					<IconButton onClick={() => setSelectedUser(data.row)}>
						<MdModeEditOutline />
					</IconButton>
				</>
			),
		},
	];

	// console.table(data);
	return (
		<>
			<Stack
				direction={"row"}
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
					width: "90vw",
					maxWidth: "1280px",
					margin: "0 auto",
					marginTop: "20px",
				}}
				spacing={1}
				mb={1}
			>
				<Button
					variant="contained"
					color="primary"
					open={open}
					onClick={() => setOpen(true)}
				>
					Add New User
				</Button>
			</Stack>
			<Box sx={{ width: "90vw", maxWidth: "1280px", margin: "0 auto", mt: 2 }}>
				<DataTable
					rows={data || []}
					columns={cols}
					getRowId={(row) => row.userID}
					isLoading={isLoading}
				/>
			</Box>
			{open && (
				<UserProfileCration
					open={open}
					setOpen={setOpen}
				/>
			)}
			{selectedUser && (
				<UpdateUserProfile
					user={selectedUser}
					onClose={handleSelectedUser}
				/>
			)}
		</>
	);
};

export default CreateUserForACL;

/**
 * create by md.masum
 * updated by noman :)
 */
