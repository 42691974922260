import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, Stack, Alert } from "@mui/material";
import { useForm } from "react-hook-form";
import { throttle } from "throttle-debounce";
import { joiResolver } from "@hookform/resolvers/joi";
// import { useNavigate } from "react-router-dom";

//icons
import { FaUserAlt, FaLock } from "react-icons/fa";
import { IoIosEye } from "react-icons/io";
import { BsFillEyeSlashFill } from "react-icons/bs";
//svg images
import svg1 from "../../assets/applogo.svg";
//components
import theme from "../../components/theme";
import CustomisedInput1 from "../../components/CustomisedInput1";
import ModalComponent from "../../components/ModalComponent";
import ForgotPassword from "../../components/forgotPassword/ForgotPassword";
import VerifyPage2 from "../../components/forgotPassword/VerifyPage2";
import toggleModal from "../../utilities/toggleModal";
import ChangePassword from "../../components/forgotPassword/ChangePassword";
import { useAuth } from "../../hook/useAuth";

// import { SnackProvider } from "../context/SnackProvider";

// API method
import { loginUser } from "../../service/userService/authService";
import { loginSchema } from "../../service/validations/loginFormValidation";
import authContext from "../../context/authContext";

const Login = () => {
	// const navigate = useNavigate();
	// const dispatch = useDispatch();
	//headle password show

	const authCntxt = React.useContext(authContext);

	const [showPass, setShowPass] = React.useState(false);
	const [error, setError] = React.useState("");
	// Modal Handling State
	const [openLogin, setOpenLogin] = React.useState(false);
	// const [openSignUp, setOpenSignUp] = React.useState(false);
	const [openForgetPass, setOpenForgetPass] = React.useState(false);
	const [openVerifyPage2, setOpenVerifyPage2] = React.useState(false);
	const [openChangePassword, setOpenChangePassword] = React.useState(false);

	// login mechanism implementation
	// const { handleClick, setSaverity, setMessage } =
	//   React.useContext(SnackProvider);

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm({ resolver: joiResolver(loginSchema) });

	const onSubmit = async (data) => {
		setError();
		try {
			const res = await loginUser(data);
			// console.log(res);
			if (res.status === 200) {
				authCntxt.setToken(res.data);
			} else {
				setError("Something went wrong!!");
			}
		} catch (ex) {
			if (ex.response && ex.response.status >= 400) {
				if (typeof ex.response.data === "object") {
					setError(ex.response.data[Object.keys(ex.response.data)[0]][0]);
				} else setError(ex.response.data);
				// console.log("User" + ex.response.data);
			} else {
				setError("Something went wrong!!");
			}
		}
	};

	const throttleSubmition = React.useCallback(
		throttle(2000, onSubmit, { noLeading: true }),
		[]
	);

	return (
		<>
			{/* <Typography>Login page</Typography>
      <button onClick={() => dispatch(setAuth())}>Login</button>
      <Button variant="contained">login</Button> */}
			{/* box used to handle login component */}
			<Box
				width={"100%"}
				minHeight={"100vh"}
				bgcolor={theme.palette.primary.main}
				sx={{
					backgroundImage: "url(/res-doddle.svg)",
					backgroundPosition: "center",

					"& *::placeholder": {
						"-webkit-user-select": "none" /* Safari */,
						"-moz-user-select": "none" /* Firefox */,
						"-ms-user-select": "none" /* IE10+/Edge */,
						"user-select": "none" /* Standard */,
					},
				}}
			>
				<Box
					bgcolor={"#fff"}
					width={"90vw"}
					maxWidth={"520px"}
					color={"#fff"}
					border="2px solid"
					sx={{
						position: "absolute",
						left: "50%",
						top: "40%",
						transform: "translate(-50%,-50%)",
						borderRadius: "5px",
					}}
				>
					{/* stack */}
					<Stack
						direction={"column"}
						justifyContent={"center"}
						alignItems="center"
						spacing={1}
						sx={{ my: { md: 5, xms: 6, xs: 6 } }}
						component="form"
						onSubmit={handleSubmit(throttleSubmition)}
					>
						<img
							src={`/my-shop.svg`}
							alt=""
							style={{ width: "40vw", maxWidth: "274px" }}
						/>
						<Typography
							variant="h5"
							color="primary"
							fontWeight={"bold"}
						>
							Hey there, business partner!
						</Typography>
						<Typography
							variant="h6"
							color="secondary"
							fontWeight={"bold"}
						>
							Great to see you!
						</Typography>
						{/* stack */}
						<Stack
							spacing={2}
							width="80vw"
							maxWidth={"466px"}
						>
							<CustomisedInput1
								{...register("username")}
								startAdornment={
									<FaUserAlt
										color="#000"
										style={{ margin: "0 10px" }}
									/>
								}
								placeholder="Enter your phone no."
							/>
							{errors.username && (
								<Alert
									severity="warning"
									sx={{ py: 0 }}
								>
									{errors.username.message}
								</Alert>
							)}

							<CustomisedInput1
								{...register("password")}
								startAdornment={
									<FaLock
										color="#000"
										style={{ margin: "0 10px" }}
									/>
								}
								type={showPass ? "text" : "password"}
								placeholder="Password"
								endAdornment={
									showPass ? (
										<IoIosEye
											color="#000"
											fontSize={"1.8rem"}
											style={{ margin: "0 10px", cursor: "pointer" }}
											onClick={() => setShowPass(!showPass)}
										/>
									) : (
										<BsFillEyeSlashFill
											color="#000"
											fontSize={"1.5rem"}
											style={{ margin: "0 10px", cursor: "pointer" }}
											onClick={() => setShowPass(!showPass)}
										/>
									)
								}
							/>
							{errors.password && (
								<Alert
									severity="warning"
									sx={{ py: 0 }}
								>
									{errors.password.message}
								</Alert>
							)}

							{error && (
								<Alert
									severity="error"
									sx={{ py: 0 }}
								>
									{error}
								</Alert>
							)}
						</Stack>
						{/* stack */}
						{/* <Stack direction={"row"}> */}
						<Typography
							variant="body2"
							color="initial"
							textAlign="right"
							sx={{
								maxWidth: "466px",
								width: "80vw",
								cursor: "pointer",
								fontWeight: "700",
								"-webkit-user-select": "none" /* Safari */,
								"-moz-user-select": "none" /* Firefox */,
								"-ms-user-select": "none" /* IE10+/Edge */,
								"user-select": "none" /* Standard */,
							}}
							onClick={() => toggleModal(setOpenLogin, setOpenForgetPass)}
						>
							Forgot Password?
						</Typography>
						{/* </Stack> */}
						<Button
							variant="contained"
							type="submit"
							color="primary"
							sx={{
								maxWidth: "466px",
								width: "80vw",
								// minHeight: "48px",
							}}
							// component={Link}
							// to={"/dashboard"}
							disabled={isSubmitting}
						>
							Log In
						</Button>
					</Stack>
				</Box>

				{/* <Stack
					direction={"row"}
					sx={{
						position: "absolute",
						bottom: 3,
						left: "50%",
						transform: "translateX(-50%)",
					}}
				>
					<Typography
						variant="caption"
						color="#fff"
						width="100%"
						sx={{ textAlign: "center" }}
					>
						Privacy Policy | Terms of Use Cookies and Interest-Based Ads Not
						Sell My Info (Bangladesh) Copyright@ 2021 ReserveitBD - All rights
						reserved.
					</Typography>
				</Stack> */}
			</Box>
			{/* ForgotPassword Modal Component */}
			<ModalComponent
				open={openForgetPass}
				setOpen={setOpenForgetPass}
				content={
					<ForgotPassword
						setOpenForgetPass={setOpenForgetPass}
						setOpenVerifyPage2={setOpenVerifyPage2}
					/>
				}
			/>
			{/* VerifyPage2 Modal Component */}
			<ModalComponent
				open={openVerifyPage2}
				setOpen={setOpenVerifyPage2}
				content={
					<VerifyPage2
						setOpenVerifyPage2={setOpenVerifyPage2}
						setOpenChangePassword={setOpenChangePassword}
					/>
				}
			/>
			{/* Change Password Modal Component */}
			<ModalComponent
				open={openChangePassword}
				setOpen={setOpenChangePassword}
				content={
					<ChangePassword setOpenChangePassword={setOpenChangePassword} />
				}
			/>
		</>
	);
};

export default Login;

/**
 *
 *this is a login component page.
 //this login page only for vendor user .
 //id and pass provide form reserveitbd super admin.
 * CREATED BY masum :)
 */
