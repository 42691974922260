import React from "react";
import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import CreateACL from "../ACL/CreateACL";
import CreateUserForACL from "../ACL/CreateUserForACL";
import Dashboard from "../dashboard";
import Discount from "../discount";
import FloorAndTableCreation from "../floorAndTableCreation";
import Login from "../login";
import ProtectedRoute from "../pageRoute/ProtectedRoute";
import RestaurantProfile from "../restaurantProfile";
import Setting from "../settings";
import ActivityLog from "../activityLog";

// impoer from custom hook
import { useAuth } from "../../hook/useAuth";
import authContext from "../../context/authContext";

const PageRoute = () => {
	const auth = useAuth();
	const authCntxt = React.useContext(authContext);

	return (
		<Routes>
			<Route
				element={
					<>
						<ProtectedRoute />
					</>
				}
			>
				<Route
					path="/"
					element={<Dashboard />}
				/>
				<Route
					path="dashboard"
					element={<Dashboard />}
				/>
				<Route
					path="setting/*"
					element={<Setting />}
				/>
				<Route
					path="restaurant-profile/*"
					element={<RestaurantProfile />}
				/>
				<Route
					path="discount/*"
					element={<Discount />}
				/>
				<Route
					path="floor-and-table-creation"
					element={<FloorAndTableCreation />}
				/>
				<Route
					path="create-acl"
					element={<CreateACL />}
				/>
				<Route
					path="create-user-acl"
					element={<CreateUserForACL />}
				/>
				<Route
					path="activity-log"
					element={<ActivityLog />}
				/>
			</Route>
			<Route
				path="*"
				element={<>404</>}
			/>
		</Routes>
	);
};

export default PageRoute;
