import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";

// icon
import { FiEdit } from "react-icons/fi";
import { MdDone } from "react-icons/md";

// Component
import OpeningHoursButton from "./OpeningHoursButton";

// context
import userContext from "../../../context/userInfo";
import snackContext from "../../../context/SnackProvider";
import { usePostSchedule } from "../../../service/schelduleService";
import { responseHandler } from "../../../utilities/responseHandler";
import { useAccessContext } from "@tam11a/react-use-access";

const RestaurantProfileOpeningHoursInput = ({ data }) => {
	// context
	const { userInfo } = React.useContext(userContext);
	const snack = React.useContext(snackContext);
	const { checkAccess } = useAccessContext();

	const [disable, setDisable] = useState(true);
	const [status, setStatus] = useState(data.status);
	const [openingTime, setOpeningTime] = useState(data.openingTime);
	const [closingTime, setClosingTime] = useState(data.closingTime);
	const [breakStartTime, setBreakStartTime] = useState(data.breakStartTime);
	const [breakEndTime, setBreakEndTime] = useState(data.breakEndTime);

	const selectStatus = (e) => {
		setStatus(e.target.value);
	};

	// Upload Schedule
	const { mutateAsync: uploadSchedule } = usePostSchedule();

	React.useEffect(() => {
		setStatus(data.status);
		setOpeningTime(data.openingTime);
		setClosingTime(data.closingTime);
		setBreakStartTime(data.breakStartTime);
		setBreakEndTime(data.breakEndTime);
	}, [data]);

	const submit = async () => {
		if (
			(status === "Open" &&
				openingTime !== "00:00" &&
				closingTime !== "00:00") ||
			status === "Closed"
		) {
			const res = await responseHandler(
				() =>
					uploadSchedule({
						vendorId: userInfo.VendorId,
						data: {
							status: status,
							day: data.day,
							userName: userInfo.VendorId,
							openingTime: openingTime,
							closingTime: closingTime,
							...(breakStartTime !== "00:00"
								? {
										breakStartTime: breakStartTime,
								  }
								: {}),
							...(breakEndTime !== "00:00"
								? {
										breakEndTime: breakEndTime,
								  }
								: {}),
						},
					}),
				[201, 204]
			);
			if (res.status) {
				snack.setSaverity("success");
				snack.setMessage("Updated Successfully!!");
				snack.handleClick();
				setDisable(!disable);
			} else {
				snack.setSaverity("error");
				snack.setMessage(res.data);
				snack.handleClick();
			}
		} else {
			snack.setSaverity("error");
			snack.setMessage("Please Select Valid Opening or Closing Time!!");
			snack.handleClick();
		}
	};

	return (
		<TableRow>
			<TableCell align="center">{data.day}</TableCell>
			<TableCell align="center">
				<Select
					value={status}
					onChange={selectStatus}
					size={"small"}
					disabled={disable}
					// IconComponent={}
					sx={{
						width: "100%",
						py: 0.5,
						px: 1,
						border: "none",
						"& > div": {
							p: "0 !important",
							fontSize: "0.8rem",
						},
						"& > svg": { display: "none" },
						"& > fieldset": {
							p: 0,
						},
					}}
				>
					{/* <MenuItem disabled value={"unselectable"}>
            Status
          </MenuItem> */}
					<MenuItem value="Open">Open</MenuItem>
					<MenuItem value="Closed">Closed</MenuItem>
				</Select>
			</TableCell>
			<TableCell align="center">
				<OpeningHoursButton
					toggle={disable}
					time={openingTime}
					setTime={setOpeningTime}
				/>
			</TableCell>
			<TableCell align="center">
				<OpeningHoursButton
					toggle={disable}
					time={closingTime}
					setTime={setClosingTime}
				/>
			</TableCell>
			<TableCell align="center">
				<OpeningHoursButton
					toggle={disable}
					time={breakStartTime}
					setTime={setBreakStartTime}
				/>
			</TableCell>
			<TableCell align="center">
				<OpeningHoursButton
					toggle={disable}
					time={breakEndTime}
					setTime={setBreakEndTime}
				/>
			</TableCell>
			<TableCell align="center">
				<IconButton
					onClick={() => {
						if (!disable) {
							submit();
						} else {
							setDisable(!disable);
						}
					}}
					size={"small"}
					disabled={!checkAccess("VendorOpeningHoursRead&Write")}
				>
					{disable ? <FiEdit /> : <MdDone />}
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default RestaurantProfileOpeningHoursInput;
