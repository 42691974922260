import { Stack } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import CTab1 from "../../components/CTab1";
import CustomisedTabs1 from "../../components/CustomisedTabs1";
import Dashboard from "./Dashboard";
import FoodLists from "./FoodLists";

const Discount = () => {
  const location = useLocation().pathname;
  return (
    <>
      <Stack
        direction={"column"}
        alignItems={"center"}
        justifyContent="flex-start"
        minHeight={"90vh"}
        minWidth="100vw"
      >
        <CustomisedTabs1
          value={location}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            width: "90vw",
            maxWidth: "600px",
            my: 3,
          }}
        >
          <CTab1
            label="Dashboard"
            value="/discount"
            component={Link}
            to={"/discount"}
          />
          <CTab1
            label="Food list"
            value="/discount/foodlists"
            component={Link}
            to={"/discount/foodlists"}
          />
        </CustomisedTabs1>
        {location === "/discount" && <Dashboard />}
        {location === "/discount/foodlists" && <FoodLists />}
      </Stack>
    </>
  );
};

export default Discount;
/**
 * create by md masum
 */
