import {
	Box,
	Drawer,
	Grid,
	IconButton,
	Paper,
	Stack,
	Typography,
	Button,
	ToggleButtonGroup,
	ToggleButton,
} from "@mui/material";
import React from "react";
import { MdClose } from "react-icons/md";
import { CgCloseR } from "react-icons/cg";
import { BiSelectMultiple } from "react-icons/bi";
import CustomisedInput1 from "../../components/CustomisedInput1";

import { arrayRemove } from "../../utilities/array";
import {
	useCreateRole,
	useGetAccessRights,
	useUpdateRole,
} from "../../service/accesslist";
import { responseHandler } from "../../utilities/responseHandler";
import userContext from "../../context/userInfo";
import snackContext from "../../context/SnackProvider";

const RoleDrawer = ({ open, setOpen, roleId, setRoleId }) => {
	const { userInfo } = React.useContext(userContext);
	const snack = React.useContext(snackContext);

	const { data } = useGetAccessRights();
	const inputRef = React.useRef(null);

	const [selectedIDs, setSelectedIDs] = React.useState([]);
	const [deselectedIDs, setDeselectedIDs] = React.useState([]);
	const [selectedArIDs, setSelectedArIDs] = React.useState([]);

	const { mutateAsync: createRole, isLoading: createLoading } = useCreateRole();
	const { mutateAsync: updateRole, isLoading: updateLoading } = useUpdateRole();

	React.useEffect(() => {
		setSelectedArIDs(roleId?.accessRightIDs || []);
	}, [roleId]);

	// React.useImperativeHandle(
	// 	inputRef,
	// 	() => ({
	// 		value: roleId?.roleName,
	// 	}),
	// 	[roleId]
	// );

	const onCreateRole = async () => {
		const res = await responseHandler(
			() =>
				createRole({
					roleName: `${inputRef.current.value} ### ${userInfo.VendorId}`,
					// roleName: inputRef.current.value,
					accessRightIDs: selectedArIDs,
					vendorID: userInfo.VendorId,
					createdBy: userInfo.username,
				}),
			[201]
		);
		if (res.status) {
			setOpen(!open);

			setSelectedArIDs([]);
			snack.setSaverity("success");
			snack.setMessage("Created successfully.");
			snack.handleClick();
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	const onUpdateRole = async () => {
		const res = await responseHandler(
			() =>
				updateRole({
					roleId: roleId?.roleID,
					data: {
						roleName:
							`${inputRef.current.value} ### ${userInfo.VendorId}` !==
							roleId?.roleName
								? `${inputRef.current.value} ### ${userInfo.VendorId}`
								: undefined,
						accessRightIDs: selectedArIDs,
						vendorID: userInfo.VendorId,
						createdBy: userInfo.username,
					},
				}),
			[204]
		);
		if (res.status) {
			setOpen(!open);

			setSelectedArIDs([]);
			snack.setSaverity("success");
			snack.setMessage("Updated successfully.");
			snack.handleClick();
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	return (
		<>
			{" "}
			<Drawer
				anchor="right"
				open={open}
				onClose={() => {
					setOpen(false);
					setRoleId();
				}}
				PaperProps={{
					sx: {
						// drawer width
						width: { xs: "90vw", xms: 300, sm: 400, lg: 700 },
					},
				}}
				// drawer animation
				transitionDuration={100}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						backgroundColor: "#F8F8F8",
						width: "100%",
						py: 1,
					}}
				>
					<Stack
						direction={"column"}
						px={2}
					>
						<Typography
							variant="header1"
							color="initial"
						>
							{roleId ? "Update Role" : "Add New Role"}
						</Typography>
						<Typography
							variant="cardLocation1"
							color="initial"
						>
							Add your information from here
						</Typography>
					</Stack>

					<Stack
						direction={"row"}
						alignItems={"center"}
						columnGap={1}
					>
						{/* <Button
							size={"small"}
							variant={"outlined"}
							onClick={() => {
								inputRef.current.value = roleId?.roleName;
								setSelectedArIDs(roleId?.accessRightIDs || []);
							}}
						>
							reset
						</Button> */}
						<IconButton
							// Close Drawer Icon
							onClick={() => {
								setOpen(false);
								setRoleId();
							}}
							sx={{
								mr: 1,
								my: { xs: 1, sm: 1.5 },
								backgroundColor: "#fff",
								color: "#F26B8C",
							}}
							size={"small"}
						>
							<MdClose />
						</IconButton>
					</Stack>
				</Box>

				<Box
					sx={{
						width: "95%",
						margin: "0 auto",
						minHeight: "calc(100vh - 250px)",
					}}
				>
					<Stack
						direction={"column"}
						mt={1}
						spacing={1}
					>
						<Typography
							variant="tabText"
							color="initial"
						>
							Role Name
						</Typography>
						<CustomisedInput1
							placeholder="Role Name"
							sx={{ borderColor: "#FBB03B" }}
							inputProps={{
								ref: inputRef,
								defaultValue: roleId?.roleName?.split?.(" ### ")[0],
							}}
						/>
					</Stack>
					<Grid
						container
						mt={1}
						sx={{
							height: "100%",
							// flexDirection: { xs: "row-reverse", lg: "unset" },
						}}
					>
						<Grid
							item
							xs={12}
							lg={6}
						>
							<Paper
								sx={{
									boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1);",
									height: "98%",
									m: 0.5,
								}}
							>
								<Stack
									direction={"row"}
									spacing={1}
									sx={{ boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1);", p: 0.5 }}
								>
									<Button
										startIcon={
											<CgCloseR
												style={{
													fontSize: "1rem",
													color: "#444",
												}}
											/>
										}
										sx={{
											textTransform: "none",
											fontSize: "0.8rem",
											fontWeight: "bold",
											color: "#444",
										}}
										disabled={!selectedIDs.length}
										onClick={() => {
											setSelectedArIDs((sarid) =>
												sarid.filter((i) => !selectedIDs.includes(i))
											);
											setSelectedIDs([]);
										}}
									>
										Unselect
									</Button>
								</Stack>
								<ToggleButtonGroup
									orientation="vertical"
									value={selectedIDs}
									sx={{
										width: "100%",
										minHeight: "200px",
										height: "100%",
										overflowY: "auto",
										p: 1,
									}}
									onChange={(e) => {
										// console.log(deselectedIDs);
										if (!selectedIDs.includes(parseInt(e.target.value)))
											setSelectedIDs([
												...selectedIDs,
												parseInt(e.target.value),
											]);
										else
											setSelectedIDs(
												arrayRemove(selectedIDs, parseInt(e.target.value))
											);
									}}
								>
									{data?.map?.((item) => {
										if (selectedArIDs.includes(item.arid))
											return (
												<ToggleButton
													value={item.arid}
													key={item.arid}
													style={{
														p: 1,
														border: "none",
														boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
													}}
													fullWidth
												>
													{item.description}
												</ToggleButton>
											);
									})}
								</ToggleButtonGroup>
							</Paper>
						</Grid>
						<Grid
							item
							xs={12}
							lg={6}
						>
							<Paper
								sx={{
									boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1);",
									height: "98%",
									m: 0.5,
								}}
							>
								<Stack
									direction={"row"}
									spacing={1}
									sx={{ boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1);", p: 0.5 }}
								>
									<Button
										startIcon={
											<BiSelectMultiple
												style={
													{
														// fontSize: '1.4rem',
														// color: "#444",
													}
												}
											/>
										}
										sx={{
											textTransform: "none",
											fontSize: "0.8rem",
											fontWeight: "bold",
											color: "#444",
										}}
										disabled={!deselectedIDs.length}
										onClick={() => {
											setSelectedArIDs((sarid) => [...sarid, ...deselectedIDs]);
											setDeselectedIDs([]);
										}}
									>
										Select
									</Button>
								</Stack>
								<ToggleButtonGroup
									orientation="vertical"
									value={deselectedIDs}
									sx={{
										width: "100%",
										minHeight: "200px",
										height: "100%",
										overflowY: "auto",
										p: 1,
									}}
									onChange={(e) => {
										// console.log(deselectedIDs);
										if (!deselectedIDs.includes(parseInt(e.target.value)))
											setDeselectedIDs([
												...deselectedIDs,
												parseInt(e.target.value),
											]);
										else
											setDeselectedIDs(
												arrayRemove(deselectedIDs, parseInt(e.target.value))
											);
									}}
								>
									{data?.map?.((item) => {
										if (!selectedArIDs.includes(item.arid))
											return (
												<ToggleButton
													value={item.arid}
													key={item.arid}
													style={{
														p: 1,
														border: "none",
														boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
													}}
													fullWidth
												>
													{item.description}
												</ToggleButton>
											);
									})}
								</ToggleButtonGroup>
							</Paper>
						</Grid>
					</Grid>

					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "flex-end",
							mt: 2,
						}}
					>
						{roleId ? (
							<>
								<Button
									variant="contained"
									color="primary"
									size="large"
									sx={{
										// minWidth: { xs: "100%", md: "200px" },
										minWidth: "100%",
										mb: 2,
									}}
									disabled={
										(!inputRef.current?.value && !selectedArIDs.length) ||
										updateLoading
									}
									onClick={onUpdateRole}
								>
									Update
								</Button>
							</>
						) : (
							<Button
								variant="contained"
								color="primary"
								size="large"
								sx={{
									minWidth: "100%",
									mb: 2,
								}}
								disabled={
									(!inputRef.current?.value && !selectedArIDs.length) ||
									createLoading
								}
								onClick={onCreateRole}
							>
								SAVE
							</Button>
						)}
					</Box>
				</Box>
			</Drawer>
		</>
	);
};

export default RoleDrawer;
