import React, { useContext } from "react";
import BarCharts from "./BarChart";
import DateRangePicker from "../../components/DateRangePicker";
import { Grid, Paper, Stack, Typography, IconButton } from "@mui/material";
import { AiOutlineFileDone } from "react-icons/ai";
import moment from "moment";
import {
	MdDoneAll,
	MdOutlineEventSeat,
	MdOutlineUpcoming,
} from "react-icons/md";

// context api
import userContext from "../../context/userInfo";

// API
import { useGetDashboardStatus } from "../../service/dashboard";

const Dashboard = () => {
	const { userInfo } = useContext(userContext);
	const [range, setRange] = React.useState({
		startDate: moment().add(-7, "days").toDate(),
		endDate: moment().toDate(),
	});
	const date = moment(range.startDate).format("L");

	// const [startDate, setStartDate] = React.useState(moment().add(-7, "days").toDate())
	// const [endDate, setEndDate] = React.useState(moment().toDate())
	const { data, isLoading, isError, error } = useGetDashboardStatus(
		moment(date).format(`YYYY-MM-DD`),
		userInfo.VendorId
	);

	return (
		<Stack
			py={1}
			direction={"column"}
			alignItems={"flex-end"}
			maxWidth={"1080px"}
			width={"95vw"}
			mx={"auto"}
			pt={3}
		>
			{/* <DateRangePicker
				startDate={range.startDate}
				endDate={range.endDate}
				setDateRange={setRange}
				buttonProps={{ sx: { width: "fit-content", my: 2 }, color: "black" }}
				onSubmit={(start, end) => {
					// console.log("from :", start, "to :", end);
				}}
			/> */}
			<TileSection
				list={[
					{
						primaryText: `${isLoading ? "0" : data?.data?.totalReservation}`,
						primaryColor: "primary.main",
						secondaryText: `Total Reservation`,
						secondaryColor: "#444",
						tileIcon: <AiOutlineFileDone />,
						tileIconColor: "primary.main",
						backgroundColor: "#ECE8EF",
					},

					{
						primaryText: `${
							isLoading ? "0" : data?.data?.completedReservation
						}`,
						primaryColor: "#004000",
						secondaryText: "Completed Reservation",
						secondaryColor: "#006600",
						tileIcon: <MdDoneAll />,
						tileIconColor: "#004000",
						backgroundColor: "#B3FFB3",
					},

					{
						primaryText: `${isLoading ? "0" : data?.data?.seatedReservation}`,
						primaryColor: "#002559",
						secondaryText: "Seated Reservation",
						secondaryColor: "#004099",
						tileIcon: <MdOutlineEventSeat />,
						tileIconColor: "#002559",
						backgroundColor: "#B3D3FF",
					},

					{
						primaryText: `${isLoading ? "0" : data?.data?.upcomingReservation}`,
						primaryColor: "#583E15",
						secondaryText: "Upcoming Reservation",
						secondaryColor: "#976A23",
						tileIcon: <MdOutlineUpcoming />,
						tileIconColor: "#583E15",
						backgroundColor: "#FEE3BA",
					},
				]}
			/>
			{/* <Paper
        sx={{
          width: "100%",
          mt: 2,
          py: 2,
          pt: 3,
        }}
        elevation={4}
      >
        <BarCharts />
      </Paper> */}
		</Stack>
	);
};

const TileSection = ({ list, ...others }) => {
	return (
		<Grid
			container
			rowGap={0.5}
			columnGap={0.5}
			alignItems={"flex-start"}
			justifyContent={"space-between"}
			{...others}
		>
			{list?.map((item, i) => (
				<Grid
					item
					sx={{ height: "100%" }}
					xs={5.92}
					sm={5.95}
					md={2.9}
					key={i}
				>
					<Paper
						elevation={3}
						sx={{
							// border: "1px solid",
							// borderColor: "primary.light",
							background: item.backgroundColor,
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
							// p: { xs: 2, md: 1 },
							p: 0.5,
						}}
					>
						<IconButton
							disableRipple
							sx={{
								flexGrow: 0.2,
								fontSize: { xs: "3rem", md: "3.5rem", lg: "4rem" },
								color: item.tileIconColor,
							}}
						>
							{item.tileIcon}
						</IconButton>
						<Stack
							sx={{
								flexGrow: 1,
							}}
							direction={"column"}
							justifyContent={"center"}
							rowGap={{ xs: 0.5, md: 0 }}
							pt={{ xs: 1.5, sm: 2, md: 1.5 }}
							// bgcolor={"red"}
						>
							<Typography
								variant="login1"
								sx={{
									lineHeight: { xs: "1rem", sm: "1rem", md: "2.5rem" },
									color: item.primaryColor,
								}}
							>
								{item.primaryText}
							</Typography>
							<Typography
								variant="normal"
								paragraph
								sx={{
									lineHeight: { xs: "0.7rem", sm: "1rem" },
									width: "50px",
									color: item.secondaryColor,
								}}
								// noWrap={"wrap"}
							>
								{item.secondaryText}
							</Typography>
						</Stack>
					</Paper>
				</Grid>
			))}
		</Grid>
	);
};

export default Dashboard;
