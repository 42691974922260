import React, { useState, useContext } from "react";
import Typography from "@mui/material/Typography";
import { Stack, Button, Box } from "@mui/material";

//theme component
import theme from "../theme";

//react icons
import { AiOutlineReload } from "react-icons/ai";

//toggleModal component
import toggleModal from "../../utilities/toggleModal";

//VerifyPage2 component area
import AuthCode from "react-auth-code-input";

// OTP Input Component

// CSS stylesheet
import "./VerifyPage2.css";

// Context API
import snackContext from "../../context/SnackProvider";

// API
import { sendOTP, verifyOTP } from "../../service/userService/authService";
import { responseHandler } from "../../utilities/responseHandler";

const VerifyPage2 = ({ setOpenVerifyPage2, setOpenChangePassword }) => {
  const snack = useContext(snackContext);
  const [error, setError] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [seconds, setSeconds] = React.useState(59);
  const [minutes, setMinutes] = React.useState(1);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((ps) => ps - 1);
      if (seconds === 0) {
        setMinutes((pt) => pt - 1);
      }
      if (seconds === 0) {
        setSeconds(59);
      }
    }, 1000);

    if (minutes === 0 && seconds === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  });

  const [result, setResult] = useState("");
  const handleOnChange = (res) => {
    setResult(res);
  };
  const verifyOTPHandler = async () => {
    // console.log(result);

    const res = await responseHandler(
      () => verifyOTP({ code: result, phone: sessionStorage.getItem("phone") }),
      204
    );

    if (res.status) {
      snack.setSaverity("success");
      snack.setMessage("Now, you are varified.");
      snack.handleClick();
      toggleModal(setOpenVerifyPage2, setOpenChangePassword);
      // setDisable(!disable);
    } else {
      snack.setSaverity("error");
      snack.setMessage(res.data);
      snack.handleClick();
    }
  };

  return (
    <>
      {/* Stack */}
      <Stack direction="column" spacing={3}>
        <Box>
          {/* Typography */}
          <Typography variant="subtitle2" color="initial">
            Verify your phone No.
            <hr width="100%" color={theme.palette.primary.main} />
          </Typography>
          {/* Typography */}
          <Typography variant="subtitle2" color="light">
            Please enter the 6 disit code sent to your phone number.
          </Typography>
        </Box>
        {/* Stack */}
        <Stack direction="column" spacing={2}>
          <AuthCode
            onChange={handleOnChange}
            inputClassName="otp"
            containerClassName="container"
            // autoFocus={true}
            allowedCharacters="numeric"
          />
          {/* Stack */}
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
            spacing={1}
          >
            {/* Typography */}
            <Typography variant="normal">
              {minutes}m {seconds}s
            </Typography>
            {/* Button */}
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                color: "#fff",
              }}
              endIcon={<AiOutlineReload style={{ color: "#000" }} />}
              disabled={seconds === 0 && minutes === 0 ? false : true}
              onClick={() =>
                sendOTP({ phone: sessionStorage.getItem("phone") })
              }
            >
              Resend code
            </Button>
          </Stack>
          {/* Typography */}
          <Typography variant="body1" color="initial">
            if you do not recive code within 2 minutes, re-send code
          </Typography>
        </Stack>
        {/* Stack */}
        <Stack direction="column" alignItems="center" justifyContent={"center"}>
          {/* Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            // onClick={() =>
            //   // toggleModal(setOpenVerifyPage2, setOpenChangePassword)
            // }
            onClick={verifyOTPHandler}
            disableFocusRipple
            disableRipple
          >
            Verify me
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default VerifyPage2;
/*
## TODO:

1. Responsive VerifyPage2 component.
2. OTP get.input now on the field.
3.you are input OTP then you can go to click on verify button.





Done by - masum {:) .
*/
