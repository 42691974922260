import React from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";

import {
	Stack,
	Button,
	FormControl,
	Select,
	MenuItem,
	Checkbox,
	Box,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

// Icons
import { MdClose } from "react-icons/md";
import CustomisedInput1 from "../../components/CustomisedInput1";
import userContext from "../../context/userInfo";
import { useCreateUser, useGetRoles } from "../../service/accesslist";
import OTPDialog from "./ACLOTP";
import snackContext from "../../context/SnackProvider";
import { responseHandler } from "../../utilities/responseHandler";
import { useForm } from "react-hook-form";
import ShowErr from "../../components/ShorErr";

import { verifyOTP, sendOTP } from "../../service/userService/authService";

const UserProfileCration = ({ open, setOpen }) => {
	const { userInfo } = React.useContext(userContext);
	const snack = React.useContext(snackContext);

	const { data: roles } = useGetRoles(userInfo.VendorId);

	const { mutateAsync: createNewUser } = useCreateUser();

	const [openOTP, setOpenOTP] = React.useState(false);
	const handleOTP = () => setOpenOTP(!openOTP);

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm();

	const onResend = async (e) => {
		const res = await responseHandler(() => sendOTP(e));
		if (res.status) {
			snack.popSnack(`Resent OTP to ${e.phone}`);
			return true;
		} else {
			snack.popSnack(res.data, "error");
			return false;
		}
	};

	const handleOTPSubmit = async (code) => {
		const res = await responseHandler(
			() =>
				verifyOTP({
					phone: sessionStorage.getItem("sotp"),
					code,
				}),
			204
		);
		if (res.status) {
			snack.popSnack("Account Verified Successfully");
			reset();
			setOpen(false);
			return true;
		} else {
			snack.popSnack(res.data, "error");
		}
	};

	const onSubmit = async (data) => {
		const submitData = {
			...data,
			type: "Vendor",
			createdBy: userInfo.username,
			vendorID: userInfo.vendorId,
		};
		const res = await responseHandler(() => createNewUser(submitData), 201);
		if (res.status) {
			sessionStorage.setItem("sotp", submitData.phone);
			setOpenOTP(true);
		} else {
			snack.popSnack(res.data, "error");
		}
	};

	return (
		<>
			{" "}
			<Drawer
				anchor="right"
				open={open}
				onClose={() => setOpen(false)}
				PaperProps={{
					sx: {
						// drawer width
						width: { xs: "90vw", xms: "90vw", sm: "90vw", lg: "90vw" },
						maxWidth: "480px",
					},
				}}
				// drawer animation
				transitionDuration={100}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							backgroundColor: "#F8F8F8",
							margin: "0 auto",
							width: "100%",
							py: 1,
						}}
					>
						<Stack
							direction={"column"}
							px={2}
						>
							<Typography
								variant="header1"
								color="initial"
							>
								Create a New User Profile
							</Typography>
							<Typography
								variant="cardLocation1"
								color="initial"
							>
								Create your user from here
							</Typography>
						</Stack>

						<Stack>
							<IconButton
								// Close Drawer Icon
								onClick={() => setOpen(false)}
								size={"small"}
								sx={{
									mr: 1,
									my: { xs: 1, sm: 1.5 },
									backgroundColor: "#fff",
									color: "#F26B8C",
								}}
							>
								<MdClose />
							</IconButton>
						</Stack>
					</Box>
					<Box sx={{ margin: "0 auto", width: "95%", marginTop: "1rem" }}>
						<Stack
							direction={{ xs: "column", sm: "row" }}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								gap: 1,
								marginTop: ".5rem",
							}}
						>
							<Stack
								direction={"column"}
								width="100%"
								spacing={1}
							>
								<Typography
									variant="cardHeader"
									color="initial"
								>
									Email*
								</Typography>
								<CustomisedInput1
									placeholder={"Enter email address"}
									sx={{ boxShadow: "none", height: "40px" }}
									{...register("email", { required: true })}
								/>
							</Stack>

							<Stack
								direction={"column"}
								width="100%"
								spacing={1}
							>
								<Typography
									variant="cardHeader"
									color="initial"
								>
									Username*
								</Typography>
								<CustomisedInput1
									placeholder="Enter username"
									sx={{ boxShadow: "none", height: "40px" }}
									{...register("userName", { required: true })}
								/>
							</Stack>
						</Stack>
						<ShowErr
							obj={errors.email}
							fieldName={"Email"}
						/>
						<ShowErr
							obj={errors.userName}
							fieldName={"Username"}
						/>
						<Stack
							direction={{ xs: "column", sm: "row" }}
							mt={2}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								gap: 1,
							}}
						>
							<Stack
								direction={"column"}
								width="100%"
								spacing={1}
							>
								<Typography
									variant="cardHeader"
									color="initial"
								>
									First Name*
								</Typography>
								<CustomisedInput1
									placeholder={"Enter first name"}
									sx={{ boxShadow: "none", height: "40px" }}
									{...register("firstName", { required: true })}
								/>
							</Stack>
							<Stack
								direction={"column"}
								width="100%"
								spacing={1}
							>
								<Typography
									variant="cardHeader"
									color="initial"
								>
									Last Name*
								</Typography>
								<CustomisedInput1
									placeholder="Enter last name"
									sx={{ boxShadow: "none", height: "40px" }}
									{...register("lastName", { required: true })}
								/>
							</Stack>
						</Stack>
						<ShowErr
							obj={errors.firstName}
							fieldName={"First Name"}
						/>
						<ShowErr
							obj={errors.lastName}
							fieldName={"Last Name"}
						/>
						<Stack
							direction={{ xs: "column", sm: "row" }}
							mt={2}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "flex-end",
								gap: 1,
							}}
						>
							<Stack
								direction={"column"}
								width="100%"
								spacing={1}
							>
								<Typography
									variant="cardHeader"
									color="initial"
								>
									Phone Number*
								</Typography>
								<CustomisedInput1
									placeholder={"Enter phone number"}
									sx={{ boxShadow: "none", height: "40px" }}
									{...register("phone", {
										required: true,
										pattern: /01\d{9}$/,
									})}
								/>
							</Stack>
							<Stack
								direction={"column"}
								width="100%"
								spacing={1}
							>
								<Typography
									variant="cardHeader"
									color="initial"
								>
									Role*
								</Typography>
								<FormControl fullWidth>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										defaultValue={"Select"}
										size={"small"}
										{...register("roleID", {
											required: true,
											valueAsNumber: true,
										})}
									>
										<MenuItem
											disabled
											value="Select"
										>
											Select
										</MenuItem>
										{roles?.map?.((role) => (
											<MenuItem
												value={role.roleID}
												key={role.roleID}
											>
												{role.roleName}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Stack>
						</Stack>
						<ShowErr
							obj={errors.phone}
							fieldName={"Phone Number"}
						/>
						<Stack
							direction={"column"}
							width="100%"
							spacing={1}
							sx={{
								my: 1,
							}}
						>
							<Typography
								variant="cardHeader"
								color="initial"
							>
								Password*
							</Typography>
							<CustomisedInput1
								placeholder={"Enter password"}
								sx={{ boxShadow: "none", height: "40px" }}
								{...register("password", {
									required: true,
								})}
								type={"password"}
							/>
						</Stack>
						<ShowErr
							obj={errors.password}
							fieldName={"Password"}
						/>
						{/* <Stack
						direction={"row"}
						spacing={1}
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							mt: 2,
							width: "100%",
						}}
					>
						<Checkbox />
						<Typography
							variant="cardLocation1"
							color="initial"
						>
							Get notification from guest to remove their info from your
							guestbook, per the General Data Protection Regulation (GDPR)
						</Typography>
					</Stack> */}
					</Box>
					<Box
						sx={{
							margin: "0 auto",
							width: "95%",
							mt: 2,
						}}
					>
						<Button
							variant="contained"
							color="primary"
							fullWidth
							sx={{ mb: "200px" }}
							type={"submit"}
							// onClick={handleOTP}
						>
							Create
						</Button>
					</Box>
				</form>
			</Drawer>
			<OTPDialog
				open={openOTP}
				onClose={handleOTP}
				onResendOTP={onResend}
				onSubmit={handleOTPSubmit}
			/>
		</>
	);
};

export default UserProfileCration;
/**
 * create by md.masum
 */
