import {
	AppBar,
	Avatar,
	Box,
	Container,
	Fab,
	Hidden,
	IconButton,
	Toolbar,
} from "@mui/material";
import { useToggle } from "@tam11a/react-use-hooks";
import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { BiMenuAltLeft } from "react-icons/bi";
import { IoQrCodeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Icon as Iconify } from "@iconify/react";

import { List as RitList, Timeline } from "rit-icons";

import NavigationDrawer from "../header/NavigationDrawer";

const AppFooter = () => {
	const { state, toggleState } = useToggle();

	return (
		<>
			<Hidden mdUp>
				<AppBar
					sx={{
						py: 0,
						top: "auto",
						bottom: "0",
						bgcolor: "#eee1f7",
						boxShadow: "0",
					}}
				>
					<Toolbar
						disableGutters
						sx={{
							height: "fit-content",
							minHeight: "20px !important",
						}}
					>
						<Container
							maxWidth="lg"
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								py: 0.5,
							}}
						>
							<IconButton
								size={"large"}
								color={"primary"}
								onClick={toggleState}
							>
								<BiMenuAltLeft />
							</IconButton>
							<IconButton
								size={"large"}
								component={Link}
								to={"/restaurant-profile"}
								color={"primary"}
								sx={{
									color: `#401B60 !important`,
								}}
							>
								<Iconify icon="charm:book" />
							</IconButton>

							<Fab
								sx={{
									position: "absolute",
									top: "0",
									left: "50%",
									transform: "translate(-50%, -50%)",
									// fontSize: "1.8rem",
									p: 0,
								}}
								component={Link}
								to={"/"}
								color={"primary"}
							>
								<Avatar src={"/favicon.svg"} />
							</Fab>
							<Box />
							<Box />
							<Box />
							<Box />
							<IconButton
								size={"large"}
								component={Link}
								to={"/floor-and-table-creation"}
								color={"primary"}
							>
								<Iconify icon="material-symbols:table-restaurant-outline" />
							</IconButton>
							<IconButton
								size={"large"}
								component={Link}
								to={"/setting"}
								color={"primary"}
								sx={{
									color: `#401B60 !important`,
								}}
							>
								<AiOutlineUser />
							</IconButton>
						</Container>
					</Toolbar>
				</AppBar>
				{/* <Box
					sx={{
						height: "90px",
					}}
				/> */}
				<NavigationDrawer
					open={state}
					handleDrawer={toggleState}
				/>
			</Hidden>
		</>
	);
};

export default AppFooter;
