import { useAccessContext } from "@tam11a/react-use-access";
import React, { createContext, useState, useEffect } from "react";
import { useGetValidation } from "../service/userService/authService";
const authContext = createContext();

export const AuthProvider = ({ children }) => {
	const { permissions, resetPermissions } = useAccessContext();

	const [getToken, setToken] = useState(localStorage.getItem("token"));

	const { data: validateData, isError: validationError } =
		useGetValidation(getToken);

	const handleSetToken = (token) => {
		localStorage.setItem("token", token);
		setToken(token);
	};

	const handleDeleteToken = () => {
		localStorage.removeItem("token");
		setToken();
	};

	useEffect(() => {
		if (!validateData) return;
		resetPermissions(validateData?.data?.accessRights || []);
	}, [validateData]);

	useEffect(() => {
		if (!validationError) return;
		handleDeleteToken();
	}, [validationError]);

	const obj = {
		permissions,
		token: getToken,
		isLoggedIn: !!getToken,
		setToken: handleSetToken,
		logout: handleDeleteToken,
		user: validateData?.data,
	};

	return <authContext.Provider value={obj}>{children}</authContext.Provider>;
};

export default authContext;
