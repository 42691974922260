import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Switch,
  Typography,
  Button,
  ToggleButtonGroup,
  Paper,
  ToggleButton,
  Checkbox,
  TextareaAutosize,
  InputBase,
} from "@mui/material";
import React from "react";
import { MdClose } from "react-icons/md";
import { GrCalendar } from "react-icons/gr";
import { FiClock } from "react-icons/fi";
import { CgCloseR } from "react-icons/cg";
import { BiSelectMultiple } from "react-icons/bi";

/**
 *
 * Need some props to controll this drawer.
 * Props are comming from Floor&TableCreation.js file.
 *
 *
 */

const DrawerForFloorAndTableBlock = ({ open, close }) => {
  // STATE for Table and floor block
  const [show, setShow] = React.useState(false);
  const handleChange = (event) => {
    setShow(event.target.checked);
  };

  const [list, setList] = React.useState([
    {
      title: "Table-1, 4-5",
      select: false,
    },
    {
      title: "Table-1, 6-5",
      select: false,
    },
    {
      title: "Table-1, 3-5",
      select: false,
    },
    {
      title: "Table-1, 2-5",
      select: false,
    },
    {
      title: "Table-1, 2-2",
      select: false,
    },
  ]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => close(false)}
      PaperProps={{
        sx: {
          // drawer width
          width: { xs: "90vw", xms: "90vw", sm: "90vw", lg: "90vw" },
          maxWidth: "520px",
        },
      }}
      // drawer animation
      transitionDuration={100}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#F8F8F8",
          margin: "0 auto",
          width: "100%",
          py: 1,
        }}
      >
        <Stack direction={"column"} px={2}>
          <Typography variant="header1" color="initial">
            Block Reservation
          </Typography>
          <Typography variant="cardLocation1" color="initial">
            Update your information from here
          </Typography>
        </Stack>

        <Stack>
          <IconButton
            // Close Drawer Icon
            onClick={() => close(false)}
            size={"small"}
            sx={{
              mr: 1,
              my: { xs: 1, sm: 1.5 },
              backgroundColor: "#fff",
              color: "#F26B8C",
            }}
          >
            <MdClose />
          </IconButton>
        </Stack>
      </Box>
      <Box sx={{ width: "94%", margin: "0 auto" }}>
        <Stack direction={"column"} alignItems={"center"} spacing={2}>
          <Stack direction={"row"} alignItems={"center"} spacing={1} mt={1}>
            <Typography variant="tabText" color="initial">
              Block Floor
            </Typography>
            <Switch checked={show} onChange={handleChange} />
            <Typography variant="tabText" color="initial">
              Block Table
            </Typography>
          </Stack>
          {show ? (
            <Paper
              sx={{
                boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1);",
                height: "fit-content",
                width: "100%",
                m: 0.5,
              }}
            >
              <Stack
                direction={"row"}
                spacing={1}
                sx={{
                  boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1);",
                  p: 0.5,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pr: 1,
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="cardHeader"
                    color="initial"
                    sx={{ mx: 1 }}
                  >
                    Select Table
                  </Typography>
                  <Box>
                    <Button
                      startIcon={
                        <BiSelectMultiple
                          style={{
                            // fontSize: '1.4rem',
                            color: "#444",
                          }}
                        />
                      }
                      sx={{
                        textTransform: "none",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        color: "#444",
                      }}
                    >
                      ALL
                    </Button>
                    <Button
                      startIcon={
                        <CgCloseR
                          style={{
                            fontSize: "1rem",
                            color: "#444",
                          }}
                        />
                      }
                      sx={{
                        textTransform: "none",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        color: "#444",
                      }}
                    >
                      Unselect
                    </Button>
                  </Box>
                </Stack>
              </Stack>
              {list.map((items) => (
                <Box sx={{ width: "98%", margin: "0 auto" }} key={items.title}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox sx={{ color: "#401B60" }} />{" "}
                    <Typography variant="cardHeader" color="initial">
                      {items.title}
                    </Typography>
                  </Stack>
                </Box>
              ))}
            </Paper>
          ) : null}
          {/*  */}
          <Typography
            variant="cardHeader"
            color="initial"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            START
          </Typography>{" "}
          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                borderColor: "#E2BA9F",
              }}
              startIcon={<GrCalendar />}
            >
              Oct 17, 2021
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                borderColor: "#E2BA9F",
              }}
              startIcon={<FiClock />}
            >
              4:00 PM
            </Button>
          </Stack>
          <Typography
            variant="cardHeader"
            color="initial"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            END
          </Typography>{" "}
          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                borderColor: "#E2BA9F",
              }}
              startIcon={<GrCalendar />}
            >
              Oct 17, 2022
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                borderColor: "#E2BA9F",
              }}
              startIcon={<FiClock />}
            >
              5:00 PM
            </Button>
          </Stack>
          <Typography
            variant="cardHeader"
            color="initial"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            Reason of Blocking
          </Typography>
          <InputBase
            placeholder={"Message.."}
            multiline
            minRows={3}
            fullWidth
            sx={{ border: "1px solid #E2BA9F", p: 1, borderRadius: "3px" }}
          />
          <Button variant="contained" color="primary" fullWidth>
            block
          </Button>
          <Button variant="contained" color="primary" fullWidth>
            Unblock
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default DrawerForFloorAndTableBlock;

/**
 * Using this drawer VA can block certain amount of table and specific table.
 *
 * Created by noman :)
 * Created by masum :)
 */
