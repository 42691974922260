// Other's section API
import { useQuery, useMutation, useQueryClient } from "react-query";
import http from "../httpService";

export const deleteNotes = () => {};

// Get all notes

const getAllNotes = async (vendorId) => {
	// const vendorId = queryKey[1];
	return await http.get(`/restaurant-profile/${vendorId}/notes`);
};

export const useGetAllNotesData = (vendorId) => {
	return useQuery(["all-notes", vendorId], () => getAllNotes(vendorId), {
		enabled: !!vendorId,
	});
};

// POST NOTES DATA

export const usePostNotesData = () => {
	const queryClient = useQueryClient();

	return useMutation(postNotes, {
		onSuccess: () => {
			queryClient.invalidateQueries("all-notes");
			// queryClient.setQueryData("all-notes", (oldData) => {
			//   // console.log(oldData.data);
			//   return {
			//     // ...oldData,
			//     data: [...oldData.data, data.data],
			//   };
			// });
		},
	});
};

const postNotes = ({ id, data }) => {
	return http.post(`/restaurant-profile/${id}/notes`, {
		userName: id,
		...data,
	});
};

// Update notes

export const useUpdateNote = () => {
	const queryClient = useQueryClient();
	return useMutation(updateNote, {
		onSuccess: () => {
			queryClient.invalidateQueries("all-notes");
		},
	});
};

const updateNote = ({ vendorId, data }) => {
	return http.put(`/restaurant-profile/${vendorId}/notes`, data);
};

// delete notes

export const useDeleteNote = () => {
	const queryClient = useQueryClient();
	return useMutation(deleteNote, {
		onSuccess: () => {
			queryClient.invalidateQueries("all-notes");
		},
	});
};

const deleteNote = ({ vendorId, data }) => {
	return http.delete(`/restaurant-profile/${vendorId}/notes`, {
		data: data,
	});
};
