/**
 *  TODO:
 *     1. dashboard status api call
 *
 *
 */
import { useQuery } from "react-query";
import http, { updateInstanceAuthorization } from "../httpService";

const getDashboardStatus = ({ queryKey }) => {
	const date = queryKey[1];
	const vID = queryKey[2];
	return http.get(
		`/restaurant-profile/vendor-reservation-reports?VendorId=${vID}`
	);
};

export const useGetDashboardStatus = (date, vID) => {
	return useQuery(["reservation-status", date, vID], getDashboardStatus, {
		select: (data) => data.data,
	});
};
