import React, { useContext } from "react";
import { Stack, Typography, Button, Alert } from "@mui/material";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { throttle } from "throttle-debounce";

//theme component
import theme from "../theme";
import CustomisedInput1 from "../CustomisedInput1";
//icons
import { FaLock } from "react-icons/fa";
import { IoIosEye } from "react-icons/io";
import { BsFillEyeSlashFill } from "react-icons/bs";

// Context API
import snackContext from "../../context/SnackProvider";

// API
import { createPasswordValidation } from "../../service/validations/chnagePassword";
import { newPssForForgetUser } from "../../service/userService/authService";
import { responseHandler } from "../../utilities/responseHandler";

//Changepassword component area start
const ChangePassword = ({ setOpenChangePassword }) => {
  const snack = React.useContext(snackContext);
  //headle password show
  const [error, setError] = React.useState("");
  const [showPass, setShowPass] = React.useState(false);
  const [showPass1, setShowPass1] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(createPasswordValidation),
  });
  // console.log(errors);

  const submit = async (data) => {
    delete data.ConfirmPassword;
    const res = await responseHandler(() => newPssForForgetUser(data), 204);
    console.log(res);
    if (res.status) {
      snack.setSaverity("success");
      snack.setMessage(
        "Changed Password for " + localStorage.getItem("phone") + "."
      );
      snack.handleClick();
      setOpenChangePassword(false);
      sessionStorage.removeItem("phone");
      sessionStorage.removeItem("VendorFPT");
    } else {
      snack.setSaverity("error");
      snack.setMessage("Your password is not correct.");
      snack.handleClick();
    }
  };

  return (
    <>
      {/* stack */}
      <Stack
        direction="column"
        spacing={2}
        component="form"
        onSubmit={handleSubmit(submit)}
      >
        {/* Typography */}
        <Typography variant="subtitle2" color="initial">
          Create a New Password
          <hr width="100%" color={theme.palette.primary.main} />
          <Typography variant="subtitle2" color="light">
            Your New Password must be Different from previous one.
          </Typography>
        </Typography>
        {/* Typography */}
        {/* stack */}

        <Stack spacing={1} direction={"column"}>
          {/* Typography */}
          <Typography variant="cardHeader" color="initial">
            New Password
          </Typography>
          {/* stack */}

          {/* passwordField from passwordfield component */}
          <CustomisedInput1
            required
            {...register("password")}
            startAdornment={
              <FaLock color="#000" style={{ margin: "0 10px" }} />
            }
            type={showPass ? "text" : "password"}
            placeholder="Password"
            endAdornment={
              showPass ? (
                <IoIosEye
                  color="#000"
                  fontSize={"1.8rem"}
                  style={{ margin: "0 10px", cursor: "pointer" }}
                  onClick={() => setShowPass(!showPass)}
                />
              ) : (
                <BsFillEyeSlashFill
                  color="#000"
                  fontSize={"1.5rem"}
                  style={{ margin: "0 10px", cursor: "pointer" }}
                  onClick={() => setShowPass(!showPass)}
                />
              )
            }
          />
          {errors.password && (
            <Alert
              severity="error"
              sx={{
                width: "auto",
                maxWidth: "505px",
                mx: "auto",
              }}
            >
              {errors.password.message}
            </Alert>
          )}
        </Stack>
        {/* stack */}
        <Stack spacing={3} direction={"column"}>
          <Stack direction={"column"} spacing={1}>
            <Typography variant="cardHeader" color="initial">
              Confirm New Password
            </Typography>
            {/* stack */}
            {/* passwordField from passwordfield component */}
            {/* <PsswordField /> */}
            <CustomisedInput1
              {...register("ConfirmPassword")}
              // error={!!(errors.cPassword || error)}
              startAdornment={
                <FaLock color="#000" style={{ margin: "0 10px" }} />
              }
              type={showPass1 ? "text" : "password"}
              placeholder="Password"
              endAdornment={
                showPass1 ? (
                  <IoIosEye
                    color="#000"
                    fontSize={"1.8rem"}
                    style={{ margin: "0 10px", cursor: "pointer" }}
                    onClick={() => setShowPass1(!showPass1)}
                  />
                ) : (
                  <BsFillEyeSlashFill
                    color="#000"
                    fontSize={"1.5rem"}
                    style={{ margin: "0 10px", cursor: "pointer" }}
                    onClick={() => setShowPass1(!showPass1)}
                  />
                )
              }
            />
            {errors.ConfirmPassword && (
              <Alert
                severity="error"
                sx={{
                  width: "80vw",
                  maxWidth: "505px",
                  mx: "auto",
                }}
              >
                {errors.ConfirmPassword.message}
              </Alert>
            )}

            {error && (
              <Alert
                severity="error"
                sx={{
                  width: "80vw",
                  maxWidth: "505px",
                  mx: "auto",
                }}
              >
                {error}
              </Alert>
            )}
          </Stack>
          {/* Typography */}

          {/* stack */}

          {/* Button */}
          <Button
            variant="contained"
            fullWidth
            color="primary"
            // onClick={() => setOpenChangePassword(false)}
            type="submit"
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default ChangePassword;
/*
## TODO:

1. Responsive ChangePassword component.
2. Make responsive input password box.
3.change the password automatic back to current page.




Done by - masum {:).


*/
