import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Components
import Title from "../../../components/Title";
import RestaurantProfileOpeningHoursInput from "./RestaurantProfileOpeningHoursInput";

// service
import { useGetSchedule } from "../../../service/schelduleService";
import userContext from "../../../context/userInfo";

const weeks = [
	{
		day: "Saturday",
		status: "Closed",
		openingTime: "00:00",
		closingTime: "00:00",
		breakStartTime: "00:00",
		breakEndTime: "00:00",
	},
	{
		day: "Sunday",
		status: "Closed",
		openingTime: "00:00",
		closingTime: "00:00",
		breakStartTime: "00:00",
		breakEndTime: "00:00",
	},
	{
		day: "Monday",
		status: "Closed",
		openingTime: "00:00",
		closingTime: "00:00",
		breakStartTime: "00:00",
		breakEndTime: "00:00",
	},
	{
		day: "Tuesday",
		status: "Closed",
		openingTime: "00:00",
		closingTime: "00:00",
		breakStartTime: "00:00",
		breakEndTime: "00:00",
	},
	{
		day: "Wednesday",
		status: "Closed",
		openingTime: "00:00",
		closingTime: "00:00",
		breakStartTime: "00:00",
		breakEndTime: "00:00",
	},
	{
		day: "Thursday",
		status: "Closed",
		openingTime: "00:00",
		closingTime: "00:00",
		breakStartTime: "00:00",
		breakEndTime: "00:00",
	},
	{
		day: "Friday",
		status: "Closed",
		openingTime: "00:00",
		closingTime: "00:00",
		breakStartTime: "00:00",
		breakEndTime: "00:00",
	},
];

const RestaurantProfileOpeningHours = () => {
	// context

	const { userInfo } = React.useContext(userContext);

	// state

	const [weekday, setWeekday] = React.useState(weeks);

	// get all schedule
	const { data: scheduleData } = useGetSchedule(userInfo.VendorId);

	React.useEffect(() => {
		if (scheduleData) {
			var tempSchedule = weekday;
			scheduleData.data.map((schedule) => {
				tempSchedule.filter((a, index) => {
					if (a.day === schedule.day) {
						tempSchedule = [
							...tempSchedule.slice(0, index),
							{
								...tempSchedule[index],
								...schedule,
							},
							...tempSchedule.slice(index + 1),
						];
					}
				});
			});
			setWeekday(tempSchedule);
		}
	}, [scheduleData]);

	return (
		<div>
			<Title text="Restaurant opening hours" />
			{/* Table design */}
			<TableContainer
				component={Paper}
				elevation={0}
				sx={{ width: "100%", my: 2, mb: 4 }}
			>
				<Table
					sx={{ minWidth: 760 }}
					aria-label="simple table"
				>
					<TableHead>
						<TableRow>
							<TableCell align="center">Day</TableCell>
							<TableCell align="right">Status</TableCell>
							<TableCell align="center">Opening time</TableCell>
							<TableCell align="center">Closing time</TableCell>
							<TableCell align="center">Break start time</TableCell>
							<TableCell align="center">Break end time</TableCell>
							<TableCell align="center">Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{weekday.map((data, index) => (
							<RestaurantProfileOpeningHoursInput
								data={data}
								key={index}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default RestaurantProfileOpeningHours;

/**
 *
 * implemented by noman :)
 *
 * This coponent usign mui table component for show all information about restaurant time maintainance.
 */
