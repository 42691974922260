import React from "react";
import { Box, Popover, Button } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import DatePicker from "sassy-datepicker";
import {
  MdCalendarToday,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import theme from "./theme";

const CustomisedDatePicker = ({ name }) => {
  const [date, setDate] = React.useState(new Date());
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Create Unique id method for datepicker
  function gen4() {
    return Math.random().toString(16).slice(-4);
  }

  function simpleUniqueId(prefix) {
    return (prefix || "").concat(
      [gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4(), gen4()].join("")
    );
  }

  // create unique id for datepicker
  const datePickerId = simpleUniqueId("datepicker-");

  const formatDate = (DateObj) => {
    const d = DateObj ? DateObj : new Date();
    let month = months[d.getMonth()];
    return month + " " + d.getDate() + ", " + d.getFullYear();
  };

  const onChange = (newDate) => {
    // console.log(`New date selected - ${newDate.toString()}`);
    setDate(newDate);
  };

  // Get the Date Range
  const getDateRange = () => {
    var minDate = new Date();
    minDate.setDate(minDate.getDate() - 1);
    var maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 15); // max day of advance reservation is 15 days
    return { minDate: minDate, maxDate: maxDate };
  };

  React.useEffect(() => {
    // Update the date value of
    document.querySelector(
      "#" + datePickerId
    ).value = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
      -2
    )}-${("0" + date.getDate()).slice(-2)}`;
  }, [date]);

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              color: "#000",
              "& > *": { color: "#000" },
              // border: "1px solid " + theme.palette.secondary.main,
              // "&:hover": {
              //   border: "1px solid " + theme.palette.secondary.main,
              // },
            }}
            {...bindTrigger(popupState)}
            startIcon={<MdCalendarToday style={{ fontSize: "1rem" }} />}
            endIcon={
              !popupState.isOpen ? (
                <MdKeyboardArrowDown
                  style={{ fontSize: "1rem", color: "#666" }}
                />
              ) : (
                <MdKeyboardArrowUp
                  style={{ fontSize: "1rem", color: "#666" }}
                />
              )
            }
          >
            {formatDate(date)}
          </Button>
          <input
            id={datePickerId}
            type={"date"}
            name={name}
            style={{ display: "none" }}
          />
          <Popover
            {...bindPopover(popupState)}
            PaperProps={{
              sx: {
                borderRadius: "8px",
                mt: 1,
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box
              sx={{
                width: "fit-content",
                mx: "auto",
                borderRadius: "10px",
                overflow: "hidden",
                // Style for sassy datepicker
                "& .sdp": {
                  boxShadow: "0 0 5px #00000033",
                },
                "& .sdp--square-btn__shadowed": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                },
                "& .sdp--date-btn__selected": {
                  backgroundColor: theme.palette.primary.main,
                },
                "& .sdp--text__inactive:not(.sdp--date-btn)": {
                  color: theme.palette.primary.light,
                  fontWeight: "bold",
                  fontSize: "0.9em",
                },
              }}
            >
              <DatePicker
                onChange={(newDate) => {
                  onChange(newDate);
                  popupState.close();
                }}
                selected={date}
                minDate={getDateRange().minDate}
                // maxDate={getDateRange().maxDate}
              />
            </Box>
          </Popover>
        </Box>
      )}
    </PopupState>
  );
};

export default CustomisedDatePicker;
