import React from "react";
import { Outlet } from "react-router-dom";
import authContext from "../../context/authContext";

// component
import Header from "../../components/header";
import Login from "../login";
import { Box, Hidden } from "@mui/material";
import AppFooter from "../../components/Footer/AppFooter";
// hook

// export const useAuth = () => {
//   const { userInfo } = useContext(userContext);
//   const user = { isLoggedin: true };

//   if (userInfo) {
//     user.isLoggedin = true;
//   } else {
//     return (user.isLoggedin = false);
//   }

//   return user.isLoggedin;
// };

const ProtectedRoute = () => {
	const authCntxt = React.useContext(authContext);
	// console.log("is Authenticated: ", isAuth);
	return authCntxt.isLoggedIn ? (
		<>
			<Hidden mdDown>
				<Header />
			</Hidden>
			<Box
				// Maintain the Content Box
				minHeight={{ xs: "calc(100vh - 64px)", sm: "calc(100vh - 72px)" }}
				sx={{
					overflowX: "hidden",
					overflowY: "auto",
					mt: { xs: 0, md: 9 },
					mb: { xs: 9, md: 0 },
				}}
			>
				<Outlet />
			</Box>
			<AppFooter />
		</>
	) : (
		<Login />
	);
};

export default ProtectedRoute;
