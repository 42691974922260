import { useContext } from "react";
import userContext from "../context/userInfo";

export const useAuth = () => {
  const { userInfo } = useContext(userContext);
  const user = { isLoggedin: true };

  if (userInfo) {
    user.isLoggedin = true;
  } else {
    return (user.isLoggedin = false);
  }

  return user.isLoggedin;
};
