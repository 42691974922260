const Joi = require("joi");

export const updatePassword = Joi.object({
  currentPassword: Joi.string().min(6).messages({
    "string.min": "Your password must be have at least 6 character long.",
  }),
  password: Joi.string().min(6).messages({
    "string.min": "Your password must be have at least 6 character long.",
  }),
  confirmPassword: Joi.any().valid(Joi.ref("password")).required().messages({
    "any.only": "Password must have to match.",
  }),
});

// const passError = (errors) => {
//   console.log(errors);
//   errors.forEach((err) => {
//     switch (err.code) {
//       case "string.min":
//         err.message = "Your password must be have at least 8 character long.";
//         break;
//       default:
//         break;
//     }
//   });
//   return errors;
// };
