import React from "react";
import { TableRow, TableCell, Stack, IconButton } from "@mui/material";

// icons
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import DiscountEditDrawer from "./DiscountEditDrawer";

// Receive some props from ../Component/Dashboard.js file.
const EditDiscountInfoDrawerTableRow = ({ info }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow
        key={info.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell align="center" component="th" scope="row">
          {info.id}
        </TableCell>
        <TableCell align="left">{info.CampaignName}</TableCell>
        <TableCell align="center">{info.ItemName}</TableCell>
        <TableCell align="center" sx={{ color: "green" }}>
          {info.Status}
        </TableCell>
        <TableCell align="center">{info.type}</TableCell>
        <TableCell align="center">{info.PercentageOrAmount}</TableCell>
        <TableCell align="center">{info.StartDate}</TableCell>
        <TableCell align="center">{info.EndDate}</TableCell>
        <TableCell align="left">{info.Description.slice(0, 30)}...</TableCell>
        <TableCell>
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1.5,
              color: "#C4C4C4",
            }}
          >
            <IconButton onClick={() => setOpen(!open)}>
              <BiEdit style={{ fontSize: "18px" }} />
            </IconButton>
            <IconButton>
              <RiDeleteBin5Line style={{ fontSize: "18px" }} />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <DiscountEditDrawer
        open={open}
        setOpen={setOpen}
        name={info.CampaignName}
        type={info.type}
        startDate={info.StartDate}
        endDate={info.EndDate}
        foodName={info.ItemName}
      />
    </>
  );
};

export default EditDiscountInfoDrawerTableRow;

/**
 *
 *
 * create by no-man :)
 */
