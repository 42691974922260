import React from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { FormControl, MenuItem, Select, Stack, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomisedInput1 from "./CustomisedInput1";

// Icons
import { MdClose } from "react-icons/md";
import CustomisedDatePicker from "./CustomisedDatePicker";

const CampaignDrawer = ({ open, setOpen }) => {
  const [type, setType] = React.useState("Type");

  const handleType = (event) => {
    setType(event.target.value);
  };
  const [food, setFood] = React.useState("Food Item List");

  const handleFoodList = (event) => {
    setFood(event.target.value);
  };
  const [value, setValue] = React.useState();
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            // drawer width
            width: { xs: "90vw", xms: 300, sm: 400 },
          },
        }}
        // drawer animation
        transitionDuration={100}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#F8F8F8",
            width: "100%",
            py: 1,
          }}
        >
          <Stack direction={"column"} px={2}>
            <Typography variant="header1" color="initial">
              Create Campaign
            </Typography>
            <Typography variant="cardLocation1" color="initial">
              Add your information from here
            </Typography>
          </Stack>

          <Stack>
            <IconButton
              // Close Drawer Icon
              onClick={() => setOpen(false)}
              sx={{
                mr: 1,
                my: { xs: 1, sm: 1.5 },
                backgroundColor: "#fff",
                color: "#F26B8C",
              }}
              size={"small"}
            >
              <MdClose />
            </IconButton>
          </Stack>
        </Box>
        <Box pr={2} px={2} mt={2}>
          <Stack
            direction={"row"}
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            spacing={1}
          >
            <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
              <Typography variant="cardHeader" color="initial">
                Campaign Name
              </Typography>
              <CustomisedInput1
                placeholder="Enter campaign name"
                sx={{
                  height: "38px",
                  boxShadow: "none",
                  border: "1px solid #FBB03B",
                }}
              />
            </Stack>
            <Stack direction={"column"} spacing={1}>
              <Typography variant="cardHeader" color="initial">
                Type
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  onChange={handleType}
                  size={"small"}
                  //   sx={{
                  //     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  //       borderColor: "#FBB03B",
                  //     },
                  //     "&..MuiOutlinedInput-notchedOutline": {
                  //       borderColor: "1px solid #FBB03B",
                  //     },
                  //   }}
                >
                  <MenuItem disabled value="Type">
                    Type
                  </MenuItem>
                  <MenuItem value={10}>Discount</MenuItem>
                  <MenuItem value={20}>Gift</MenuItem>
                  <MenuItem value={30}>Discount</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            mt={2}
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
            spacing={1}
          >
            <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
              <Typography variant="cardHeader" color="initial">
                Start Date
              </Typography>
              <CustomisedDatePicker />
            </Stack>
            <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
              <Typography variant="cardHeader" color="initial">
                End Date
              </Typography>
              <CustomisedDatePicker />
            </Stack>
          </Stack>

          <Stack direction={"column"} spacing={1} mt={2}>
            <Typography variant="cardHeader" color="initial">
              Food Item List
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={food}
                onChange={handleFoodList}
                size={"small"}
                sx={{ mb: 2 }}
              >
                <MenuItem disabled value="Food Item List">
                  Food Item List
                </MenuItem>
                <MenuItem value={10}>Pizza</MenuItem>
                <MenuItem value={20}>Rice</MenuItem>
                <MenuItem value={30}>Chicken</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary">
              save
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default CampaignDrawer;
/**
 * create by md masum
 */
