// Other's section API
import { useQuery, useMutation, useQueryClient } from "react-query";
import http from "./httpService";

export const getGallery = (vendorId) => {
	return http.get(`/restaurant-profile/${vendorId}/gallery`);
};

export const useGetGallery = (vendorId) => {
	return useQuery(["get-gallery", vendorId], () => getGallery(vendorId), {
		enabled: !!vendorId,
	});
};

export const photoUpload = ({ id, file, type }) => {
	var formData = new FormData();
	formData.append("File", file);
	formData.append("FileType", type);
	formData.append("UserName", "SYSTEM");

	return http.post(`/restaurant-profile/${id}/file/upload`, formData, {
		Headers: { "Content-Type": "multipart/form-data" },
	});
};

export const usePhotoUpload = () => {
	const queryClient = useQueryClient();
	return useMutation(photoUpload, {
		onSuccess: () => {
			queryClient.invalidateQueries("get-gallery");
		},
	});
};

export const deletePhotos = ({ id, filename }) => {
	const data = {
		fileName: filename,
		userName: "SYSTEM",
	};

	return http.delete(`/restaurant-profile/${id}/file/delete`, {
		data: data,
	});
};

export const useDeletePhotos = () => {
	const queryClient = useQueryClient();
	return useMutation(deletePhotos, {
		onSuccess: () => {
			queryClient.invalidateQueries("get-gallery");
		},
	});
};
