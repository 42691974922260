import React from "react";
import Modal from "@mui/material/Modal";
import { Box, IconButton } from "@mui/material";
import { MdClose } from "react-icons/md";
const ModalComponent = ({ open, setOpen, content }) => {
  return (
    <>
      {/* modal for sign-in */}
      <Modal
        sx={{
          height: "100vh",
          overflowY: "auto",
          display: "flex",
          justifyContent: "center",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "relative",
            width: "90vw",
            maxWidth: "520px",
            height: "fit-content",
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            py: 2,
            my: 5,
            overflowY: "hidden",
            // outline: "none",
          }}
        >
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.black,
            }}
          >
            <MdClose />
          </IconButton>
          {/* content:component conect with loginForm component */}
          <Box
            sx={{
              width: "80vw",
              maxWidth: "466px",
              margin: "0 auto",
              py: 2.5,
            }}
          >
            {content}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ModalComponent;

/*
## TODO:

1. Responsive Modal Component.
2. Drawer for Responsive View.
3. Up side drawer for search.
4. Make responsive input box.
5. Integrate with react-hook-form.
6. make a post request.


[N.B. Use SVG of Icons for reduce the load time.]

Done by - masum mollah {:) .
*/
