import { useMutation } from "react-query";
import http from "../httpService";
import jwt_decode from "jwt-decode";

if (localStorage.getItem("token")) {
}

const changePassword = ({ data }) => {
  var token = localStorage.getItem("token");
  var userDataFromToken = jwt_decode(token);

  delete data.confirmPassword;
  const phone = userDataFromToken.Phone;
  const formData = { ...data, phone, token: localStorage.getItem("token") };
  return http.put("/update-password", formData);
};

export const useChangePassword = () => {
  return useMutation(changePassword);
};
