import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import ImageIcon from "../assets/ImageUploadIconPreview.svg";
import { MdDelete } from "react-icons/md";
import snackContext from "../context/SnackProvider";
import AreYouSure from "./AreYouSure";

// API

// vendorId, onChange and showCurrentImage props comming from RestaurantProfileOverview.js file

const ImageUploader = ({
	onChange,
	maxSize,
	extension,
	defaultValue,
	onDelete,
	disabled,
	...others
}) => {
	const snack = React.useContext(snackContext);
	const [files, setFiles] = useState([]);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: extension || "image/*",
		maxFiles: 1,
		minSize: 0,
		maxSize: maxSize || 5242880,
		multiple: false,
		onDropAccepted: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
			if (typeof onChange === "function") {
				resOnChange(acceptedFiles[0]);
			}
		},
		onDropRejected: (rejectedFiles) => {
			// console.log(rejectedFiles[0].errors);
			console.log(rejectedFiles);
			snack.setSaverity("error");
			snack.setMessage(rejectedFiles[0].errors[0].message);
			snack.handleClick();
		},
	});

	const resOnChange = async (file) => {
		const res = await onChange(file);
		if (!res) {
			setFiles([]);
		}
	};

	// useEffect(() => {
	// 	// console.log(files);
	// 	if (files.length && !(files[0].preview === defaultValue?.preview)) {
	// 		if (typeof onChange === "function") {
	// 			resOnChange(files[0]);
	// 		}
	// 	}
	// }, [files]);

	useEffect(() => {
		if (defaultValue) setFiles([defaultValue]);
		else setFiles([]);
	}, [defaultValue]);
	/*
  useEffect(() => {
    const getPhoto = async () => {
      try {
        const res = await viewPhotos(showCurrentImage, vendorId);
        console.log(res);
      } catch (ex) {}
    };
    getPhoto();
  });
  console.log(showCurrentImage);
*/
	// console.log(files[0]);

	const [openAYS, setOpenAYS] = React.useState(false);
	const handleAYS = () => setOpenAYS((openAYS) => !openAYS);

	return (
		<Box
			sx={{
				position: "relative",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				height: "125px",
				width: "125px",
				border: "2px dashed",
				borderColor: "#666",
				rowGap: 0.5,
				...others.sx,
			}}
			{...others}
		>
			<DragFileHere
				getRootProps={getRootProps}
				getInputProps={getInputProps}
				isDragActive={isDragActive}
				isFileAdded={!!files.length}
			/>
			{files.length ? (
				<Box
					sx={{
						position: "absolute",
						height: "125px",
						width: "125px",
						p: 1,
						"&:hover > div": {
							display: "flex",
						},
					}}
				>
					<Avatar
						src={files[0].preview}
						alt={files[0].name}
						style={{
							height: "108px",
							width: "108px",
							m: "12px",
							borderRadius: 0,
						}}
					/>
					<Box
						sx={{
							display: "none",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "row",
							height: "100%",
							width: "100%",
							background: "#2D323Faa",
							position: "absolute",
							top: 0,
							left: 0,
						}}
					>
						{/* <IconButton color={"white"}>
              <MdFileUpload />
            </IconButton> */}
						<IconButton
							color={"white"}
							onClick={() => handleAYS()}
							disabled={!!disabled}
						>
							<MdDelete />
						</IconButton>
						<AreYouSure
							cancelOpen={openAYS}
							handleOpen={() => handleAYS()}
							message={"You want to delete picture?"}
							handleFunc={async () => {
								if (typeof onDelete === "function") {
									const res = await onDelete(files[0]);
									if (res) setFiles([]);
									handleAYS();
								}
							}}
						/>
					</Box>
				</Box>
			) : (
				<></>
			)}
		</Box>
	);
};

const DragFileHere = ({
	getRootProps,
	getInputProps,
	isDragActive,
	isFileAdded,
}) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				padding: "15px",
			}}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			{!isDragActive ? (
				!isFileAdded ? (
					<>
						<img
							src={ImageIcon}
							alt={"drag file here"}
							height={"45px"}
							style={{
								marginLeft: "8px",
								pointerEvents: "none",
								userSelect: "none",
							}}
						/>
						<Typography
							variant={"overline"}
							sx={{
								textTransform: "capitalize",
								color: "#666",
							}}
						>
							Drag File Here
						</Typography>
					</>
				) : (
					<></>
				)
			) : (
				<>
					<Typography
						variant={"overline"}
						sx={{
							textTransform: "capitalize",
							color: "#666",
						}}
					>
						DROP HERE!
					</Typography>
				</>
			)}
		</div>
	);
};

export default ImageUploader;
