// Other's section API
import { useQuery, useMutation, useQueryClient } from "react-query";
import http from "./httpService";

export const getSchedule = (vendorId) => {
	return http.get(`/restaurant-profile/${vendorId}/schedule`);
};

export const useGetSchedule = (vendorId) => {
	return useQuery(["get-schedule", vendorId], () => getSchedule(vendorId), {
		enabled: !!vendorId,
	});
};

export const postSchedule = ({ vendorId, data }) => {
	return http.put(`/restaurant-profile/${vendorId}/schedule`, data);
};

export const usePostSchedule = () => {
	const queryClient = useQueryClient();
	return useMutation(postSchedule, {
		onSuccess: () => {
			// Invalidate and refetch
			queryClient.invalidateQueries("get-schedule");
		},
	});
};
