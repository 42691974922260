import React, { useContext } from "react";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import "./App.css";
import theme from "./components/theme";
import Route from "./pages/pageRoute/Route";

// context provider
import { SnackProvider } from "./context/SnackProvider";
import { AuthProvider } from "./context/authContext";
import { UserProvider } from "./context/userInfo";
import TawkTo from "tawkto-react";
import { AccessProvider } from "@tam11a/react-use-access";

function App() {
	const queryClient = new QueryClient();
	React.useEffect(() => {
		var tawk = new TawkTo("638ecdc2b0d6371309d2cf3e", "1gjit7gik");
		tawk.hideWidget();
	}, []);

	return (
		<AccessProvider>
			<SnackProvider>
				<QueryClientProvider client={queryClient}>
					{/* <ReactQueryDevtools /> */}
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<GlobalStyles
							styles={{
								"*": {
									// Disable Blue Highlight when Touch/Press object with cursor: 'pointer' in Android
									WebkitTapHighlightColor: "transparent",
									// scrollbar
									scrollbarWidth: "5px",
									scrollbarColor: `${theme.palette.primary.light} #00000033`,
									outline: "none !important",
								},
								"*::-webkit-scrollbar": {
									width: "5px",
								},
								"*::-webkit-scrollbar-track": {
									background: "#00000033",
								},
								"*::-webkit-scrollbar-thumb": {
									background: theme.palette.primary.light,
								},
								"*::-webkit-scrollbar-thumb:hover": {
									background: theme.palette.primary.main,
								},
								// App Body Scrollbar
								"&::-webkit-scrollbar": {
									width: "5px",
								},
								"&::-webkit-scrollbar-track": {
									background: "#00000033",
								},
								"&::-webkit-scrollbar-thumb": {
									background: theme.palette.primary.light,
								},
								"&::-webkit-scrollbar-thumb:hover": {
									background: theme.palette.primary.main,
								},
							}}
						/>
						<AuthProvider>
							<UserProvider>
								<Route />
							</UserProvider>
						</AuthProvider>
					</ThemeProvider>
				</QueryClientProvider>
			</SnackProvider>
		</AccessProvider>
	);
}

export default App;
