import http, { updateInstanceAuthorization } from "../httpService";
import jwt_decode from "jwt-decode";
import { useQuery } from "react-query";

export const loginUser = async (data) => {
	return http.post("/login", { ...data, type: "Vendor" });
};

export const logoutUser = () => {
	localStorage.removeItem("token");
};

export const getCurrentUser = () => {
	try {
		let jwt = localStorage.getItem("token");
		// console.log(jwt);
		return jwt_decode(jwt);
	} catch (ex) {
		return null;
	}
};

export const sendOTP = (phoneNo) => {
	// console.log(phoneNo);
	const confirmData = { ...phoneNo, type: "Vendor" };
	return http.put("/send-otp", confirmData);
};

export const verifyOTP = (OTPcode) => {
	// console.log(OTPcode);
	const confirmData = {
		...OTPcode,
		type: "Vendor",
	};
	// console.log(confirmData);
	return http.post("/verify-otp", confirmData);
};

export const newPssForForgetUser = (data) => {
	// console.log(data);
	const confirmData = {
		...data,
		phone: sessionStorage.getItem("phone"),
		token: sessionStorage.getItem("VendorFPT"),
	};
	return http.put("/forget-password", confirmData);
};

const getValidateUser = () => {
	updateInstanceAuthorization();
	return http.get("/currentuser");
};

export const useGetValidation = (token) => {
	return useQuery(["validate", token], getValidateUser, {
		enabled: !!token,
	});
};
