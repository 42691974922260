const Joi = require("joi");

const errorMessage = (errors) => {
  errors.forEach((err) => {
    // console.log(err.code)
    switch (err.code) {
      case "string.pattern.base":
        err.message = "Invalid URL.";
        break;
      default:
        break;
    }
  });
  return errors;
};

export const contactInfoValidationSchema = Joi.object({
  address: Joi.string().required().max(1000),
  latitude: Joi.string().max(1000),
  logitude: Joi.string(),
  userName: Joi.string(),
  websiteURL: Joi.string()
    .regex(
      /[(http(s)?):(www\.)?a-zA-Z0-9@:%._~#=]{2,256}[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/
    )
    .allow("")
    .error(errorMessage),
  facebookURL: Joi.string()
    .regex(
      /^(?:http|https?:\/\/)?(?:www\.|m\.|touch\.)?(?:facebook\.com|fb(?:\.me|\.com))\/(\w+)/
    )
    .allow("")
    .error(errorMessage),
  instagramURL: Joi.string()
    .regex(
      /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/
    )
    .allow("")
    .error(errorMessage),
  twitterURL: Joi.string()
    .regex(/(?:http:\/\/)?(?:www\.)?twitter\.com\/(\w+)/)
    .allow("")

    .error(errorMessage),
});

// https://react-hook-form.com/api/usecontroller/controller/#:~:text=work%20with%20them.-,Props,-The%20following%20table
