// Other's section API
import { useQuery, useMutation, useQueryClient } from "react-query";
import http from "./httpService";

// get all floor data

export const getAllFloor = (vendorId) => {
	return http.get(`/restaurant-profile/${vendorId}/get-floors`);
};

export const useGetAllFloor = (vendorId) => {
	return useQuery(["get-floors", vendorId], () => getAllFloor(vendorId), {});
};

// create floor

export const createFloor = ({ vendorId, data }) => {
	return http.post(`/restaurant-profile/${vendorId}/create-floor`, data);
};

export const useCreateFloor = () => {
	const queryClient = useQueryClient();
	return useMutation(createFloor, {
		onSuccess: () => {
			// Invalidate and refetch
			queryClient.invalidateQueries("get-floors");
		},
	});
};

// update floor
export const updateFloor = ({ vendorId, data }) => {
	return http.put(`/restaurant-profile/${vendorId}/update-floor`, data);
};

export const useUpdateFloor = () => {
	const queryClient = useQueryClient();
	return useMutation(updateFloor, {
		onSuccess: () => {
			// Invalidate and refetch
			queryClient.invalidateQueries("get-floors");
		},
	});
};

// delete floor
export const deleteFloor = ({ vendorId, floorId }) => {
	return http.delete(`/restaurant-profile/${vendorId}/delete-floor/${floorId}`);
};

export const useDeleteFloor = () => {
	const queryClient = useQueryClient();
	return useMutation(deleteFloor, {
		onSuccess: () => {
			// Invalidate and refetch
			queryClient.invalidateQueries("get-floors");
		},
	});
};

// get table list of floor
const getFloorInfo = ({ vendorId, floorId }) => {
	if (floorId)
		return http.get(`/restaurant-profile/${vendorId}/get-tables/${floorId}`);
};

export const useGetFloorInfo = ({ vendorId, floorId }) => {
	return useQuery(
		["get-floor-info", floorId],
		() => getFloorInfo({ vendorId, floorId }),
		{ enabled: !!vendorId }
	);
};
