import React, { useState } from "react";

// dependancy
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";

// Icons
import { BiMenuAltRight } from "react-icons/bi";

import theme from "../theme";
import { Link } from "react-router-dom";

// ../customisedLibraries/rit-icons
import { Reserveit, Settings, Signout } from "rit-icons";

import { Avatar, Button, Chip } from "@mui/material";
import ModalComponent from "../ModalComponent";
import ConfirmDialogBox from "./ConfirmDialogBox";

import { Icon as Iconify } from "@iconify/react";
import userContext from "../../context/userInfo";
import {
	getProfileOverviewInfo,
	useGetProfileOverviewInfo,
} from "../../service/profileOverviewService";
import authContext from "../../context/authContext";
import AreYouSure from "../AreYouSure";
import { MdOpenInNew } from "react-icons/md";

// this handleDrawer props comming from Header component

const NavigationDrawer = ({ open, handleDrawer }) => {
	// context

	const authCntxt = React.useContext(authContext);
	const { userInfo } = React.useContext(userContext);

	const [info, setInfo] = React.useState();

	// get overview data
	const { data: overviewData } = useGetProfileOverviewInfo(userInfo?.VendorId);

	React.useEffect(() => {
		if (overviewData) {
			// console.log(userInfo);
			setInfo(overviewData.data);
			document.title = overviewData.data?.name;
		}
	}, [overviewData]);

	const [openAlart, setOpenAlart] = useState(false);
	// navigation list.. this can be dynamic useing setNavigationList for ACL
	const handleAlert = () => {
		setOpenAlart(!openAlart);
	};
	const [navigationList, setNavigationList] = React.useState([
		{
			title: "Restaurant & Service",
			subList: [
				{
					icon: <Iconify icon="carbon:dashboard" />,
					title: "Dashboard",
					to: "dashboard",
				},
				{
					icon: <Iconify icon="charm:book" />,
					title: "Restaurant Profile",
					to: "restaurant-profile",
				},
				{
					icon: <Iconify icon="material-symbols:table-restaurant-outline" />,
					title: "Floors & Tables",
					to: "floor-and-table-creation",
				},
				// {
				//   icon: <Iconify icon="bxs:discount" />,
				//   title: "Discount",
				//   to: "discount",
				// },
			],
		},
		{
			title: "General",
			subList: [
				{
					icon: <Iconify icon="emojione-monotone:fork-and-knife-with-plate" />,
					title: "User Accounts",
					to: "create-user-acl",
					// disabled: true,
				},
				{
					icon: <Iconify icon="fluent:phone-key-20-regular" />,
					title: "Access List",
					to: "create-acl",
					// disabled: true,
				},
				// {
				// 	icon: <Iconify icon="healthicons:i-note-action-outline" />,
				// 	title: "Activity Log",
				// 	to: "activity-log",
				// 	disabled: true,
				// },
				{
					icon: <Settings />,
					title: "Setting",
					to: "setting",
				},
				{
					icon: <Signout />,
					title: "Signout",
					func: () => handleAlert(),
				},
			],
		},
	]);

	return (
		<>
			<SwipeableDrawer
				open={open}
				onClose={handleDrawer}
				onOpen={handleDrawer}
				PaperProps={{
					sx: {
						// drawer width
						width: { xs: "90vw", xms: 300, sm: 400 },
					},
				}}
				// drawer animation
				transitionDuration={100}
			>
				<List>
					<ListItem
						disablePadding
						disableGutters
					>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Avatar
								// Restaurant Profile Picture
								src={
									info && info.profileImage
										? `https://api.reserveitbd.com/api/restaurant-profile/${userInfo.VendorId}/file/${info.profileImage}`
										: ""
								}
								sx={{ ml: "10px", my: { xs: 1, sm: 1.5 } }}
							/>
							<Stack
								sx={{ flexGrow: 1, ml: 2, textTransform: "uppercase" }}
								direction="column"
								justifyContent={"center"}
							>
								{/* Restaurant Name */}
								<Typography sx={{ fontWeight: "600" }}>
									{info ? info.name : ""}
								</Typography>
								{/* Restaurant Id */}
								{/* <Typography
									variant="caption"
									sx={{ fontWeight: "600", color: "#999" }}
								>
									{userInfo ? userInfo.name : ""}
								</Typography> */}
								<Chip
									icon={<MdOpenInNew />}
									color={"primary"}
									label={"Restaurant App"}
									size={"small"}
									sx={{
										p: 1,
										width: "fit-content",
									}}
									onClick={() => {}}
									component={"a"}
									href={"https://restaurant.reserveitbd.com"}
								/>
							</Stack>

							<IconButton
								// Close Drawer Icon
								onClick={handleDrawer}
								sx={{ mr: "10px", my: { xs: 1, sm: 1.5 } }}
							>
								<BiMenuAltRight />
							</IconButton>
						</Box>
					</ListItem>
				</List>
				{/* Mapping The Navigation List Here*/}
				{navigationList?.map((navList, index) => (
					<React.Fragment key={index}>
						<ListItem
							sx={{
								background: theme.palette.primary.main,
								color: "#fff",
								textTransform: "uppercase",
							}}
						>
							{/* Navigation Parent Title Here */}
							<ListItemText primary={navList.title} />
						</ListItem>
						<List>
							{/* Navigation Sublist Mapping Here */}
							{navList.subList?.map((sub, index) => (
								<ListItem
									disablePadding
									key={index}
								>
									<ListItemButton
										component={sub.to ? Link : Button}
										// Navigation Link
										to={sub.to}
										sx={{
											textTransform: "none",
										}}
										// Click Handler
										onClick={() => {
											if (!sub.to) {
												sub.func();
											}
											handleDrawer();
										}}
										disabled={sub.disabled}
									>
										<ListItemIcon
											sx={{
												minWidth: "2rem",
												fontSize: "1.5rem",
												color: "primary.main",
											}}
										>
											{/* Sublist Item Icon */}
											{sub.icon}
										</ListItemIcon>
										<ListItemText
											// Sublist Item Title
											primary={sub.title}
											primaryTypographyProps={{
												sx: { fontSize: "1.1rem", pl: 2 },
											}}
										/>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</React.Fragment>
				))}
				<Typography sx={{ textAlign: "center", mt: "auto", fontSize: ".7rem" }}>
					Power by reserveitbd.com
				</Typography>
			</SwipeableDrawer>
			{/* alart message for vendor */}
			{/* <ModalComponent
				open={openAlart}
				setOpen={setOpenAlart}
				content={<ConfirmDialogBox handleAlert={handleAlert} />}
			/> */}
			<AreYouSure
				cancelOpen={openAlart}
				handleOpen={handleAlert}
				message={"You want to logout?"}
				handleFunc={() => {
					authCntxt.logout();
					handleAlert();
				}}
			/>
		</>
	);
};

export default NavigationDrawer;

/**
 * 
 * created by Noman :)
 * 
 * 
 * update - tam
 * [Note From Tam]: All the Navigation is in state list including icon, title, and navigation link
 * 

*/
