import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import CustomisedInput1 from "../../components/CustomisedInput1";
import TableBody from "@mui/material/TableBody";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

// Icons
import { MdClose } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import theme from "../../components/theme";

const CampaignDrawer1 = ({ open, setOpen }) => {
  const handleType = (event) => {
    setType(event.target.value);
  };
  const [type, setType] = React.useState("Category");
  const [info, setInfo] = useState([
    {
      Select: true,
      Category: "Bangla",
      CusineName: "Fish, Rice",
      CurrentPrice: 100,
      DiscountPrice: 90,
    },
    {
      Select: true,
      Category: "Indian",
      CusineName: "Biryani",
      CurrentPrice: 130,
      DiscountPrice: 117,
    },
    {
      Select: false,
      Category: "Bangla",
      CusineName: "Fish, Rice",
      CurrentPrice: 100,
      DiscountPrice: 90,
    },
  ]);
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            // drawer width
            width: { xs: "90vw", xms: "90vw", sm: "90vw", lg: "90vw" },
            maxWidth: "760px",
          },
        }}
        // drawer animation
        transitionDuration={100}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#F8F8F8",
            margin: "0 auto",
            width: "100%",
            py: 1,
          }}
        >
          <Stack direction={"column"} px={2}>
            <Typography variant="header1" color="initial">
              Create Campaign
            </Typography>
            <Typography variant="cardLocation1" color="initial">
              Add your information from here
            </Typography>
          </Stack>

          <Stack>
            <IconButton
              // Close Drawer Icon
              onClick={() => setOpen(false)}
              size={"small"}
              sx={{
                mr: 1,
                my: { xs: 1, sm: 1.5 },
                backgroundColor: "#fff",
                color: "#F26B8C",
              }}
            >
              <MdClose />
            </IconButton>
          </Stack>
        </Box>

        <Box mt={2}>
          <Stack
            direction={"row"}
            sx={{
              margin: "0 auto",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Stack direction={"column"} spacing={1} width={"100%"} ml={2}>
              <Typography variant="cardHeader" color="initial">
                Food List Name
              </Typography>
              <CustomisedInput1
                placeholder="Enter Food list name"
                sx={{
                  height: "38px",

                  boxShadow: "none",
                  border: "1px solid #FBB03B",
                }}
              />
            </Stack>
            <Stack direction={"column"} spacing={1} mr={2}>
              <Typography variant="cardHeader" color="initial">
                Category
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  onChange={handleType}
                  size={"small"}
                  sx={{ width: "100%" }}
                  //   sx={{
                  //     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  //       borderColor: "#FBB03B",
                  //     },
                  //     "&..MuiOutlinedInput-notchedOutline": {
                  //       borderColor: "1px solid #FBB03B",
                  //     },
                  //   }}
                >
                  <MenuItem disabled value="Category">
                    Category
                  </MenuItem>
                  <MenuItem value={10}>All</MenuItem>
                  <MenuItem value={20}>Bangla</MenuItem>
                  <MenuItem value={30}>Indian</MenuItem>
                  <MenuItem value={40}>Chinese</MenuItem>
                  <MenuItem value={50}>Thai</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
        <Box width={"100%"}>
          <CustomisedInput1
            sx={{
              width: { lg: "95%", xs: "93% ", sm: "96%", margin: "0 auto" },
              mx: "auto",
              my: 2,
              borderRadius: "2rem",
              border: "none",
              boxShadow: "none",
              backgroundColor: "#F2F2F2",
              height: "40px",
              px: 2,
            }}
            placeholder="Search food item"
          />
        </Box>
        <Box sx={{ margin: "0 auto", width: "95%" }}>
          <TableContainer
            component={Paper}
            sx={
              {
                /*"-ms-overflow-style": { xs: "none", md: "unset" },
          scrollbarWidth: { xs: "none", md: "unset" },
          "&::-webkit-scrollbar": { display: { xs: "none", md: "unset" } },*/
              }
            }
          >
            <Table
              sx={{
                width: "100%",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#E3E3E3" }}>
                  <TableCell align="center">Select</TableCell>
                  <TableCell align="center">Category</TableCell>
                  <TableCell align="center">Cusine Name</TableCell>
                  <TableCell align="center">Current Price</TableCell>
                  <TableCell align="center">Discount %</TableCell>
                  <TableCell align="center">Discount Price</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {info.map((info, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      <Checkbox checked={info.Select} />
                    </TableCell>
                    <TableCell align="center">{info.Category}</TableCell>
                    <TableCell align="center">{info.CusineName}</TableCell>
                    <TableCell align="center">{info.CurrentPrice}</TableCell>
                    <TableCell align="center">
                      <CustomisedInput1
                        type="number"
                        placeholder="Discount % "
                        sx={{
                          boxShadow: "none",
                          borderColor: theme.palette.secondary.main,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">{info.DiscountPrice}</TableCell>
                    <TableCell align="center">
                      <Stack
                        direction={"row"}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1.5,
                          color: "#C4C4C4",
                        }}
                      >
                        <IconButton>
                          <RiDeleteBin5Line style={{ fontSize: "18px" }} />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 auto",
            width: "95%",
            mt: 2,
            gap: 1,
          }}
        >
          <Button variant="contained" color="secondary">
            Cancel
          </Button>

          <Button variant="contained" color="primary">
            Save
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default CampaignDrawer1;
/**
 * create by md masum
 */
