import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Stack, Button, Box } from "@mui/material";
import DateRangePicker from "../../components/DateRangePicker";
import moment from "moment";

// component
import CampaignDrawer from "../../components/CampaignDrawer";
import EditDiscountInfoDrawerTableRow from "./EditDiscountInfoDrawerTableRow";

const Dashboard = () => {
  const [value, setValue] = React.useState();
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState([
    {
      id: 1,
      CampaignName: "Food day",
      ItemName: "Pizza",
      Status: " Active",
      type: "Discount",
      PercentageOrAmount: 10,
      StartDate: "Wed, Jun 24, 2021 ",
      EndDate: "Wed, Jun 24, 2021",
      Description:
        "The DataGrid component is designed for use-cases that are focused on handling large amounts of tabular data. While it comes with a more rigid structure, in exchange, you gain more powerful features.",
    },
    {
      id: 2,
      CampaignName: "Valentine day",
      ItemName: "Rice",
      Status: " Active",
      type: "Gift",
      PercentageOrAmount: 100,
      StartDate: "Wed, Jun 24, 2021 ",
      EndDate: "Wed, Jun 24, 2021",
      Description:
        "The DataGrid component is designed for use-cases that are focused on handling large amounts of tabular data. While it comes with a more rigid structure, in exchange, you gain more powerful features.",
    },
    {
      id: 3,
      CampaignName: "Food day",
      ItemName: "Pizza",
      Status: " Active",
      type: "Discount",
      PercentageOrAmount: 10,
      StartDate: "Wed, Jun 24, 2021 ",
      EndDate: "Wed, Jun 24, 2021",
      Description:
        "The DataGrid component is designed for use-cases that are focused on handling large amounts of tabular data. While it comes with a more rigid structure, in exchange, you gain more powerful features.",
    },
  ]);
  const [range, setRange] = React.useState({
    startDate: moment().add(-7, "days").toDate(),
    endDate: moment().toDate(),
  });
  return (
    <>
      <Stack
        direction={"row"}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "90vw",
          margin: "0 auto",
          // mr: { lg: 24, xs: 4.5, sm: 7.8, xms: 7.2 },
        }}
        spacing={1}
        mb={1}
      >
        <DateRangePicker
          startDate={range.startDate}
          endDate={range.endDate}
          setDateRange={setRange}
          buttonProps={{ sx: { width: "fit-content", my: 2 }, color: "black" }}
        />
        <Button
          variant="contained"
          color="primary"
          open={open}
          onClick={() => setOpen(true)}
        >
          ADD NEW
        </Button>
      </Stack>
      <Box sx={{ width: "90vw", margin: "0 auto" }}>
        <TableContainer
          component={Paper}
          sx={
            {
              /*"-ms-overflow-style": { xs: "none", md: "unset" },
          scrollbarWidth: { xs: "none", md: "unset" },
          "&::-webkit-scrollbar": { display: { xs: "none", md: "unset" } },*/
            }
          }
        >
          <Table
            sx={{
              width: "100%",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#E3E3E3" }}>
                <TableCell align="center">ID</TableCell>
                <TableCell align="left">Campaign Name </TableCell>
                <TableCell align="center">Item name</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Percentage % Or Amount</TableCell>
                <TableCell align="center">Start date</TableCell>
                <TableCell align="center">End Date</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {info.map((info) => (
                <EditDiscountInfoDrawerTableRow info={info} key={info.id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CampaignDrawer open={open} setOpen={setOpen} />
    </>
  );
};

export default Dashboard;
/**
 * create by md masum
 *
 * updated by noman :)
 */
