import http from "./httpService";

export const profileImageUpload = (vendorId, file, type, username) => {
  var formData = new FormData();
  formData.append("File", file);
  formData.append("FileType", type);
  formData.append("UserName", username);

  return http.post(`/restaurant-profile/${vendorId}/file/upload`, formData, {
    Headers: { "Content-Type": "multipart/form-data" },
  });
};

export const viewPhotos = async (filename, vendorId) => {
  const res = await http.get(
    `/restaurant-profile/${vendorId}/file/${filename}`
  );
  return res;
};

export const deletePhotos = async (filename, vendorId) => {
  const res = await http.delete(`/restaurant-profile/${vendorId}/file/delete`, {
    data: {
      fileName: filename,
      userName: "system",
    },
  });
  return res;
};
