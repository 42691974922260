import React, { useState } from "react";
import {
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";

// component
import theme from "../../components/theme";
import UpdateTableFormForFloorManagement from "./UpdateTableFormForFloorManagement";
// import EditUserInfoDrawer from "./EditUserInfoDrawer";

/**
 * Need some props comming from Floor&TableCreation.js.
 *
 */

const TableView = ({
  info,
  tableSelected,
  setTableSelected,
  vendorId,
  floorId,
  ...others
}) => {
  // for open dialog
  const [openDia, setOpenDia] = useState(false);
  // console.log(info);

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        px={2}
        py={1}
        sx={{
          background: openDia ? theme.palette.primary.main : "transparent",
          boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        onClick={() => {
          setTableSelected(info.tableNo);
          setOpenDia(!openDia);
        }}
        {...others}
      >
        <Typography
          variant="cardHeader"
          sx={{
            color: openDia ? "#fff" : "#000",
          }}
        >
          Tbl - {info.tableNo}
        </Typography>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent={"flex-end"}
        >
          <Typography
            variant="cardHeader"
            p={0}
            sx={{
              color: openDia ? "#fff" : theme.palette.primary.main,
            }}
          >
            {info.minParty}-{info.maxParty}
          </Typography>
          <Typography
            variant="cardHeader"
            sx={{
              color: openDia ? "#fff" : "rgba(0, 0, 0, 0.5)",
              fontWeight: "400",
            }}
          >
            party size
          </Typography>
        </Stack>
      </Stack>
      <Dialog
        open={openDia}
        onClose={() => {
          setOpenDia(!openDia);
          setTableSelected("");
        }}
        PaperProps={{ sx: { width: "100%" } }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Tbl - {info.tableNo}
        </DialogTitle>
        <DialogContent>
          <UpdateTableFormForFloorManagement
            info={info}
            vendorId={vendorId}
            floorId={floorId}
            handleClose={() => setOpenDia(!openDia)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TableView;

/**
 *
 * create by noman :)
 *
 */
