// Other's section API
import { useQuery, useMutation, useQueryClient } from "react-query";
import http from "./httpService";

const getRoles = (vendorID) => {
	return http.get(`/access-right/roles/Vendor`, {
		params: {
			vendorID,
		},
	});
};

export const useGetRoles = (vendorID) => {
	return useQuery(["get-roles", vendorID], () => getRoles(vendorID), {
		enabled: !!vendorID && vendorID !== "undefined",
		select: (data) => data?.data || [],
	});
};

const getAccessRights = () => {
	return http.get(`/access-right/list/Vendor`);
};

export const useGetAccessRights = () => {
	return useQuery(["get-accesses"], getAccessRights, {
		select: (d) => d?.data || [],
	});
};

const createRole = (data) => {
	return http.post(`/access-right/role/Vendor`, data);
};

export const useCreateRole = () => {
	const queryClient = useQueryClient();
	return useMutation([], createRole, {
		onSuccess: () => queryClient.invalidateQueries("get-roles"),
	});
};

const updateRole = ({ roleId, data }) => {
	return http.patch(`/access-right/role/${roleId}`, data);
};

export const useUpdateRole = () => {
	const queryClient = useQueryClient();
	return useMutation([], updateRole, {
		onSuccess: () => {
			queryClient.invalidateQueries("get-roles");
			queryClient.invalidateQueries("validate");
		},
	});
};

const deleteRole = ({ roleId }) => {
	return http.delete(`/access-right/roles/${roleId}`);
};

export const useDeleteRole = () => {
	const queryClient = useQueryClient();
	return useMutation([], deleteRole, {
		onSuccess: () => queryClient.invalidateQueries("get-roles"),
	});
};

const getUsers = (vendorID) => {
	return http.get(`/user/vendor-users/${vendorID}`);
};

export const useGetUsers = (vendorID) => {
	return useQuery(["get-users", vendorID], () => getUsers(vendorID), {
		enabled: !isNaN(vendorID),
		select: (data) => data?.data || [],
	});
};

// Create user for restaurant.
const createUser = (data) => {
	return http.post("/signup", data);
};

export const useCreateUser = () => {
	const queryClient = useQueryClient();
	return useMutation(createUser, {
		onSuccess: () => queryClient.invalidateQueries("get-users"),
	});
};

// Update user for restaurant.
const updateUser = ({ id, data }) => {
	return http.put(`/update-user/${id}`, data);
};

export const useUpdateUser = () => {
	const queryClient = useQueryClient();
	return useMutation(updateUser, {
		onSuccess: () => {
			queryClient.invalidateQueries("validate");
			queryClient.invalidateQueries("get-users");
		},
	});
};
