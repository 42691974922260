import React, { useContext } from "react";
import {
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  Avatar,
} from "@mui/material";

import { useGetSpecialities } from "./../service/specialitiesService";
import { MdClose } from "react-icons/md";
import { backendURL } from "../service/httpService";

const AddSpecialDialog = ({
  parmaList,
  open,
  handleClose,
  specialitiesData,
  setSpecialitiesData,
  specialListChanges,
  setSpecialListChanges,
  ...others
}) => {
  // Add Specialities Dialog Box
  const [tempList, setTempList] = React.useState([]);

  // Fetch All Specialities
  const { data: getSpecialities } = useGetSpecialities();

  React.useState(() => {
    if (tempList.length) return;

    setTempList(specialitiesData);
  }, [specialitiesData]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "95vw",
          // minHeight: "95vh",
        },
      }}
      {...others}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div />
        <Typography variant={"cardHeader"}>
          Select Special Information
        </Typography>
        <IconButton size={"small"} onClick={handleClose}>
          <MdClose />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          pt: 1,
          mt: 0,
        }}
      >
        {tempList ? (
          getSpecialities?.data?.map((perCategory) => {
            return (
              <React.Fragment key={perCategory.id}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ columnGap: "7px" }}
                >
                  <Avatar
                    src={
                      backendURL +
                      "/additional-info/speciality/icon/" +
                      perCategory.id
                    }
                    alt={perCategory.name}
                    sx={{
                      height: "1rem",
                      width: "1rem",
                    }}
                  />
                  <Typography variant="tabText">{perCategory.name}</Typography>
                </Stack>
                <Autocomplete
                  // disablePortal
                  limitTags={2}
                  defaultValue={perCategory.specialitySubCategory.filter(
                    (perSub) => tempList.includes(perSub.id)
                  )}
                  options={perCategory.specialitySubCategory}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions={true}
                  sx={{
                    my: 0.5,
                  }}
                  size={"small"}
                  multiple={true}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(event, value, reason, details) => {
                    // console.log(value, perCategory);
                    if (reason === "removeOption") {
                      setTempList(
                        tempList.filter((elem) => elem !== details.option.id)
                      );
                    } else if (reason === "selectOption") {
                      setTempList([...tempList, details.option.id]);
                    }
                  }}
                />
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant={"contained"}
          onClick={() => {
            // console.log(Math.abs(specialitiesData.length - tempList.length));
            // setSpecialListChanges(
            //   specialListChanges - specialitiesData.length + tempList.length
            // );
            const addList = tempList.filter((x) => !parmaList.includes(x));
            const rmvList = parmaList.filter((x) => !tempList.includes(x));
            setSpecialListChanges({
              add: addList.length,
              remove: rmvList.length,
            });
            setSpecialitiesData(tempList);
            handleClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSpecialDialog;
