import React, { useContext } from "react";
import {
	Box,
	Chip,
	Grid,
	Stack,
	Typography,
	Button,
	IconButton,
	Paper,
	Collapse,
	Drawer,
	Avatar,
	Alert,
} from "@mui/material";
import Title from "../../../components/Title";
import { FaHashtag } from "react-icons/fa";
import { MdAdd, MdOutlineFastfood, MdClose, MdRemove } from "react-icons/md";
import { GiAmpleDress } from "react-icons/gi";
import { BiDish } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { useForm, Controller } from "react-hook-form";
import { throttle } from "throttle-debounce";

// component
import ImageUploader from "../../../components/ImageUploader";
import CustomisedInput1 from "../../../components/CustomisedInput1";
import theme from "../../../components/theme";

// contexts
import userContext from "../../../context/userInfo";
import snackContext from "../../../context/SnackProvider";

// API service
import { profileImageUpload, deletePhotos } from "../../../service/fileService";
import {
	postProfileOverviewInfo,
	useGetProfileOverviewInfo,
} from "../../../service/profileOverviewService";
import {
	useGetAllNotesData,
	usePostNotesData,
	useUpdateNote,
	useDeleteNote,
} from "../../../service/useQuery/useNotes";
import { responseHandler } from "../../../utilities/responseHandler";
import { useQueryClient } from "react-query";
import AddSpecialDialog from "../../../components/AddSpecialDialog";
import { backendURL } from "../../../service/httpService";
import { useAccessContext } from "@tam11a/react-use-access";

const RestaurantProfileOverview = () => {
	// context
	const { userInfo } = useContext(userContext);
	const { checkAccess } = useAccessContext();

	const snack = useContext(snackContext);
	const [data, setData] = React.useState({});
	// state
	const [otherForm, setOtherForm] = React.useState(false);

	// get overview data
	const { data: overviewData } = useGetProfileOverviewInfo(userInfo.VendorId);

	// specialities list
	const [specialitiesData, setSpecialitiesData] = React.useState([]);
	const [specialitiesShowList, setSpecialitiesShowList] = React.useState([]);
	const [specialListChanges, setSpecialListChanges] = React.useState({
		add: 0,
		remove: 0,
	});

	// getnotes data
	const { data: noteData } = useGetAllNotesData(userInfo.VendorId);
	const queryClient = useQueryClient();

	React.useEffect(() => {
		if (overviewData) {
			setData(overviewData.data);

			// data.specialities
			setSpecialitiesData(overviewData.data.specialities.map((spc) => spc.id));
			setSpecialitiesShowList(overviewData.data.specialities);

			//  store data to input
			const keylist = Object.keys(overviewData.data);
			keylist.map((key) => {
				if (overviewData.data[key]) {
					resetField(key, { defaultValue: overviewData.data[key] });
				}
			});
			// ----
		}
	}, [overviewData]);

	const [specialDialogToggle, setSpecialDialogToggle] = React.useState(false);
	const handleSpecialDialogToggle = () => {
		setSpecialDialogToggle(!specialDialogToggle);
	};

	// Profile image uploader
	const imageUpload = async (file, type) => {
		if (!checkAccess("VendorOverviewRead&Write")) {
			snack.setSaverity("error");
			snack.setMessage("You don't have permission to update.");
			snack.handleClick();
			return;
		}
		try {
			const res = await profileImageUpload(
				userInfo.VendorId,
				file,
				type,
				userInfo.UserId
			);
			if (res.status === 201) {
				snack.setSaverity("success");
				snack.setMessage("Image Uploaded Successfully!!");
				snack.handleClick();
				queryClient.invalidateQueries("profile-overview");
				return true;
			} else {
				snack.setSaverity("error");
				if (typeof res.response.data === "object")
					snack.setMessage(
						res.response.data[Object.keys(res.response.data)[0]][0]
					);
				else snack.setMessage(res.response.data);
				snack.handleClick();
				return false;
			}
		} catch (ex) {
			// ex.response.data[Object.keys(ex.response.data)[0]][0]
			snack.setSaverity("error");
			try {
				snack.setMessage(ex.response.data[Object.keys(ex.response.data)[0]][0]);
			} catch {
				snack.setMessage("Something Went Wrong!!");
			}
			snack.handleClick();
			return false;
		}
	};

	const deleteImage = async (vendorID, fileName) => {
		if (!checkAccess("VendorOverviewRead&Write")) {
			snack.setSaverity("error");
			snack.setMessage("You don't have permission to update.");
			snack.handleClick();
			return;
		}
		try {
			const res = await deletePhotos(fileName, vendorID);
			if (res.status === 200) {
				snack.setSaverity("success");
				snack.setMessage("Image Deleted Successfully!!");
				snack.handleClick();
				queryClient.invalidateQueries("profile-overview");
				return true;
			} else {
				snack.setSaverity("error");
				if (typeof res.response.data === "object")
					snack.setMessage(
						res.response.data[Object.keys(res.response.data)[0]][0]
					);
				else snack.setMessage(res.response.data);
				snack.handleClick();
				return false;
			}
		} catch (ex) {
			snack.setSaverity("error");
			if (typeof ex.response.data === "object")
				snack.setMessage(ex.response.data[Object.keys(ex.response.data)[0]][0]);
			else snack.setMessage(ex.response.data);
			snack.handleClick();
			return false;
		}
	};

	const {
		handleSubmit,
		formState: { errors },
		control,
		resetField,
	} = useForm({});

	// handleform
	const submitOverview = async (data) => {
		if (!checkAccess("VendorOverviewRead&Write")) {
			snack.setSaverity("error");
			snack.setMessage("You don't have permission to update.");
			snack.handleClick();
			return;
		}
		// data = ;
		// console.log(data);
		try {
			const res = await postProfileOverviewInfo(userInfo.VendorId, {
				...data,
				speciality: specialitiesData,
				userName: userInfo.VendorId,
			});
			// console.log(res);
			if (res.status === 200) {
				snack.setSaverity("success");
				snack.setMessage("Information updated successfully !");
				snack.handleClick();
				setSpecialListChanges({
					add: 0,
					remove: 0,
				});
				queryClient.invalidateQueries("profile-overview");
				return true;
			} else {
				snack.setSaverity("error");
				if (typeof res.response.data === "object")
					snack.setMessage(
						res.response.data[Object.keys(res.response.data)[0]][0]
					);
				else snack.setMessage(res.response.data);
				snack.handleClick();
				return false;
			}
		} catch (ex) {
			// console.log(ex);
			snack.setSaverity("error");
			try {
				if (typeof ex.response.data === "object")
					snack.setMessage(
						ex.response.data[Object.keys(ex.response.data)[0]][0]
					);
				else snack.setMessage(ex.response.data);
			} catch (ex) {
				snack.setMessage("something is wrong.");
			}
			snack.handleClick();
			return false;
		}
	};

	const throttleOverview = React.useCallback(
		throttle(1000, submitOverview, { noLeading: true })
	);

	// Submit newNotes form
	const {
		mutateAsync,
		isError: postNoteIsError,
		error: postNoteError,
	} = usePostNotesData();

	const {
		handleSubmit: noteSubmit,
		reset: resetNote,
		// formState: { formError },
		register: noteRegister,
	} = useForm();

	const newNotes = async (data) => {
		if (!checkAccess("VendorOverviewRead&Write")) {
			snack.setSaverity("error");
			snack.setMessage("You don't have permission to update.");
			snack.handleClick();
			return;
		}
		const res = await responseHandler(
			() => mutateAsync({ id: userInfo.VendorId, data: data }),
			201
		);
		// console.log(res);
		if (res.status) {
			snack.setSaverity("success");
			snack.setMessage("Information added successfully !");
			snack.handleClick();
			resetNote();
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	const throttleAddNotes = React.useCallback(
		throttle(2000, newNotes, { noLeading: true }),
		[]
	);

	return (
		<Box
			sx={{
				width: "95vw",
				maxWidth: { xs: "95vw", md: "710px" },
			}}
			display={"flex"}
			flexDirection={"column"}
			alignItems={"flex-start"}
			justifyContent={"flex-start"}
			rowGap={1}
			mb={3}
		>
			<Title text={"OVERVIEW"} />
			<Box // FORM for PUT ->> /api/restaurant-profile/{vendorId}/overview
				component={"form"}
				sx={{
					width: "95vw",
					maxWidth: { xs: "95vw", md: "710px" },
				}}
				display={"flex"}
				flexDirection={"column"}
				alignItems={"flex-start"}
				justifyContent={"flex-start"}
				rowGap={1}
				mb={3}
				//--

				onSubmit={handleSubmit(throttleOverview)}
			>
				<Grid
					container
					rowGap={2}
					justifyContent={"center"}
				>
					<Grid
						item
						xs={6}
						md={3}
						sx={{
							display: "flex",
							alignItems: "center",
							flexDirection: "column",
							rowGap: 1,
						}}
					>
						<Typography
							variant="tabText"
							color="primary"
						>
							Profile Photo
						</Typography>
						<ImageUploader
							onChange={async (file) => imageUpload(file, "Profile")}
							onDelete={async (file) => deleteImage(file.vendorId, file.name)}
							defaultValue={
								data.profileImage
									? {
											preview: `${backendURL}/restaurant-profile/${data.vendorID}/file/${data.profileImage}`,
											name: data.profileImage,
											vendorId: data.vendorID,
											uploaded: true,
									  }
									: undefined
							}
							disabled={!checkAccess("VendorOverviewRead&Write")}
						/>
					</Grid>
					<Grid
						item
						xs={6}
						md={3}
						sx={{
							display: "flex",
							alignItems: "center",
							flexDirection: "column",
							rowGap: 1,
						}}
					>
						<Typography
							variant="tabText"
							color="primary"
						>
							Cover Photo
						</Typography>
						<ImageUploader
							onChange={async (file) => imageUpload(file, "Cover")}
							onDelete={async (file) => deleteImage(file.vendorId, file.name)}
							defaultValue={
								data.coverImage
									? {
											preview: `${backendURL}/restaurant-profile/${data.vendorID}/file/${data.coverImage}`,
											name: data.coverImage,
											vendorId: data.vendorID,
											uploaded: true,
									  }
									: undefined
							}
							disabled={!checkAccess("VendorOverviewRead&Write")}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						sx={{
							display: "flex",
							alignItems: { xs: "flex-start", md: "center" },
							flexDirection: "column",
							rowGap: 1,
						}}
					>
						<Typography
							variant="tabText"
							color="primary"
						>
							YouTube Video Link
						</Typography>
						<Controller
							control={control}
							name="video"
							rules={{
								pattern:
									/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
							}}
							render={({ field: { onChange, value, ref } }) => (
								<CustomisedInput1
									fullWidth
									placeholder="https://www.youtube.com/watch?v=*******"
									onChange={onChange}
									value={value}
									inputRef={ref}
								/>
							)}
						/>
						{errors.video && (
							<Alert
								severity="error"
								sx={{
									width: "100%",
								}}
							>
								Invalid YouTube URL
							</Alert>
						)}
					</Grid>
				</Grid>
				<Typography
					variant="tabText"
					color="primary"
					sx={{
						mt: 2,
					}}
				>
					Restaurant Name
				</Typography>
				<Controller
					control={control}
					name="name"
					render={({ field: { onChange, value, ref } }) => (
						<CustomisedInput1
							readOnly
							fullWidth
							placeholder="Enter your restaurant name"
							onChange={onChange}
							value={value}
							inputRef={ref}
							sx={{
								color: "#888",
							}}
						/>
					)}
				/>
				<Typography
					variant="tabText"
					color="primary"
					sx={{
						mt: 2,
					}}
				>
					Description
				</Typography>

				<Controller
					control={control}
					name="description"
					render={({ field: { onChange, value, ref } }) => (
						<CustomisedInput1
							multiline
							minRows={5}
							fullWidth
							placeholder="Aa..."
							onChange={onChange}
							value={value}
							inputRef={ref}
							sx={{
								py: 0.5,
							}}
						/>
					)}
				/>
				{/* numberOfSeat */}
				<Typography
					variant="tabText"
					color="primary"
					sx={{
						mt: 2,
					}}
				>
					Number of Seat*
				</Typography>
				<Controller
					control={control}
					name="numberOfSeat"
					render={({ field: { onChange, value, ref } }) => (
						<CustomisedInput1
							fullWidth
							placeholder="Enter number of seat"
							onChange={onChange}
							value={value}
							inputRef={ref}
						/>
					)}
				/>
				<Typography
					variant="tabText"
					color="primary"
					sx={{
						mt: 2,
					}}
				>
					Special
				</Typography>
				<Stack
					direction={"row"}
					rowGap={1}
					columnGap={1}
					flexWrap={"wrap"}
				>
					{specialitiesShowList?.map((tag) => (
						<Chip
							key={"Chip-" + tag.id}
							avatar={
								<Avatar
									alt={tag.name}
									src={
										backendURL +
										"/additional-info/speciality/icon/" +
										tag.specialityCategory.id
									}
								/>
							}
							// color={"primary"}
							label={tag.name}
						/>
					))}
					{!!specialListChanges.add && (
						<Chip
							label={`${specialListChanges.add} items`}
							icon={<MdAdd />}
							color={"success"}
						/>
					)}
					{!!specialListChanges.remove && (
						<Chip
							label={`${specialListChanges.remove} items`}
							icon={<MdRemove />}
							color={"error"}
						/>
					)}
					<Button
						sx={{
							width: "fit-content",
							borderRadius: "20px",
							textTransform: "unset",
							pr: 2,
							mb: 3,
						}}
						startIcon={<MdAdd style={{ marginRight: "-6px" }} />}
						color={"primary"}
						variant={"contained"}
						onClick={handleSpecialDialogToggle}
						size="small"
						disabled={!checkAccess("VendorOverviewRead&Write")}
					>
						Add Special
					</Button>
				</Stack>
				{specialDialogToggle && (
					<AddSpecialDialog
						parmaList={overviewData.data.specialities.map((spc) => spc.id)}
						open={specialDialogToggle}
						handleClose={handleSpecialDialogToggle}
						specialitiesData={specialitiesData}
						setSpecialitiesData={setSpecialitiesData}
						specialListChanges={specialListChanges}
						setSpecialListChanges={setSpecialListChanges}
					/>
				)}
				<Button
					variant={"contained"}
					type={"submit"}
					fullWidth
					disabled={!checkAccess("VendorOverviewRead&Write")}
				>
					Submit
				</Button>
			</Box>

			<Typography
				variant="tabText"
				color="primary"
				sx={{
					mt: 2,
				}}
			>
				Others
			</Typography>
			{/* TODO: notes api */}
			{noteData?.data.map((data, index) => (
				<OthersAndDrawer
					data={data}
					key={index}
				/>
			))}
			<Collapse
				in={otherForm}
				sx={{
					width: "100%",
				}}
				timeout="auto"
				unmountOnExit
			>
				{/* TODO: add new notes */}
				<Paper
					elevation={5}
					sx={{
						borderRadius: "4px",
						p: 1,
						px: 2,
						width: "100%",
						"& > *": {
							my: 1,
						},
					}}
					component="form"
					onSubmit={noteSubmit(throttleAddNotes)}
				>
					<Typography
						variant="tabText"
						color="primary"
						sx={{
							mt: 2,
						}}
					>
						{data.title}
					</Typography>
					<CustomisedInput1
						fullWidth
						required
						placeholder="Title"
						{...noteRegister("title")}
					/>
					{/* <Typography
            variant="tabText"
            color="primary"
            sx={{
              mt: 2,
            }}
          >
            Description
          </Typography> */}
					<CustomisedInput1
						fullWidth
						multiline
						minRows={5}
						placeholder="Description"
						required
						sx={{
							py: 0.5,
						}}
						{...noteRegister("description")}
					/>

					<Stack
						direction={"row"}
						spacing={2}
						justifyContent={"flex-end"}
					>
						<Button onClick={() => setOtherForm(!otherForm)}>Cancel</Button>
						<Button
							variant={"contained"}
							// onClick={() => setOtherForm(!otherForm)}
							type="submit"
							disabled={!checkAccess("VendorOverviewRead&Write")}
						>
							Save
						</Button>
					</Stack>
				</Paper>
			</Collapse>
			<Collapse
				in={!otherForm}
				sx={{
					width: "100%",
				}}
				timeout="auto"
				unmountOnExit
			>
				<Button
					sx={{
						width: "fit-content",
						textTransform: "unset",
						pr: 2,
					}}
					startIcon={<MdAdd style={{ marginRight: "-6px" }} />}
					color={"primary"}
					variant={"contained"}
					size="small"
					onClick={() => setOtherForm(!otherForm)}
					disabled={!checkAccess("VendorOverviewRead&Write")}
				>
					Add New
				</Button>
			</Collapse>
		</Box>
	);
};

// Note Edit Drawer
const OthersAndDrawer = ({ data }) => {
	// console.log(data);
	// context
	const { userInfo } = useContext(userContext);
	const snack = useContext(snackContext);
	// states
	const [openDrawer, setOpenDrawer] = React.useState(false);
	const { checkAccess } = useAccessContext();

	const {
		handleSubmit,
		formState: { error },
		control,
		resetField,
	} = useForm();

	const {
		mutate,
		mutateAsync,
		isError: updateNoteIsError,
		error: updateNoteError,
		reset,
	} = useUpdateNote();

	// handleform PUT
	const submit = async (formData) => {
		const putData = {
			id: data.id,
			...formData,
			userName: userInfo.VendorId,
		};
		// console.log(putData);
		const res = await responseHandler(
			() => mutateAsync({ vendorId: userInfo.VendorId, data: putData }),
			204
		);
		if (res.status) {
			snack.setSaverity("success");
			snack.setMessage("Information updated successfully !");
			snack.handleClick();
			reset();
			setOpenDrawer();
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	const throttleNote = React.useCallback(
		throttle(1000, submit, { noLeading: true }),
		[]
	);

	// Delete data

	const { mutateAsync: deleteNote } = useDeleteNote();

	const deleteNoteData = async () => {
		const res = await responseHandler(() =>
			deleteNote({
				vendorId: userInfo.VendorId,
				data: { id: data.id, userName: `${userInfo.VendorId}` },
			})
		);
		if (res.status) {
			snack.setSaverity("success");
			snack.setMessage("Information delete successfully !");
			snack.handleClick();
			reset();
			setOpenDrawer();
		} else {
			snack.setSaverity("error");
			snack.setMessage(res.data);
			snack.handleClick();
		}
	};

	// this useEffect helps us to put data on input field.
	React.useEffect(() => {
		// store data to input
		if (openDrawer) {
			setTimeout(() => {
				const keylist = Object.keys(data);
				keylist.map((key) => {
					if (data[key]) {
						resetField(key, { defaultValue: data[key] });
					}
				});
			}, 1);
		}
		// ----
	}, [openDrawer]);

	return (
		<>
			<Paper
				sx={{ boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)", width: "100%" }}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems={"flex-start"}
				>
					<Stack
						direction={"column"}
						spacing={1}
						padding={1.5}
					>
						<Typography variant="cardHeader">{data.title}</Typography>
						<Typography
							variant="normal"
							sx={{ fontFamily: theme.typography.fontFamily }}
						>
							{data.description}
						</Typography>
					</Stack>
					<IconButton
						size={"small"}
						onClick={() => setOpenDrawer(!openDrawer)}
						disabled={!checkAccess("VendorOverviewRead&Write")}
					>
						<FiEdit style={{ color: "#C4C4C4" }} />
					</IconButton>
				</Stack>
			</Paper>
			<Drawer
				anchor="right"
				open={openDrawer}
				onClose={() => setOpenDrawer(!openDrawer)}
				sx={{ width: "50rem" }}
				PaperProps={{ sx: { width: { xs: "90%", md: "80%", lg: "480px" } } }}
			>
				<Stack
					direction={"row"}
					justifyContent="space-between"
					alignItems="center"
					sx={{ bgcolor: "#F8F8F8", p: 2 }}
				>
					<Stack>
						<Typography variant="header1">Others</Typography>
						<Typography variant="cardLocation1">
							Update your information here
						</Typography>
					</Stack>
					<IconButton
						// Close Drawer Icons
						onClick={() => setOpenDrawer(!openDrawer)}
						sx={{
							mr: 1,
							my: { xs: 1, sm: 1.5 },
							backgroundColor: "#fff",
							color: "#F26B8C",
						}}
						size={"small"}
					>
						<MdClose />
					</IconButton>
				</Stack>
				<Stack sx={{ p: 2 }}>
					<Stack
						direction={"column"}
						spacing={1}
						padding={1}
						sx={{
							backgroundColor: "#FFFFFF",
							boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
						}}
					>
						<Typography variant="cardHeader">{data.title}</Typography>
						<Typography
							variant="normal"
							component={"pre"}
							sx={{ fontFamily: theme.typography.fontFamily }}
						>
							{data.description}
						</Typography>
					</Stack>
				</Stack>

				<Stack
					p={2}
					spacing={2}
					component={"form"}
					onSubmit={handleSubmit(throttleNote)}
				>
					<Typography
						variant="tabText"
						color="primary"
						sx={{
							mt: 2,
						}}
					>
						Title
					</Typography>
					<Controller
						control={control}
						name="title"
						render={({ field: { onChange, value, ref } }) => (
							<CustomisedInput1
								fullWidth
								placeholder="Title"
								required
								onChange={onChange}
								value={value}
								inputRef={ref}
							/>
						)}
					/>
					<Typography
						variant="tabText"
						color="primary"
						sx={{
							mt: 2,
						}}
					>
						Description
					</Typography>
					<Controller
						control={control}
						name="description"
						render={({ field: { onChange, value, ref } }) => (
							<CustomisedInput1
								fullWidth
								multiline
								minRows={5}
								placeholder="Aa..."
								required
								sx={{
									py: 0.5,
								}}
								onChange={onChange}
								value={value}
								inputRef={ref}
							/>
						)}
					/>
					<Stack
						direction={{ xs: "column", sm: "row", md: "row" }}
						width="100%"
						columnGap={1}
						rowGap={1.5}
					>
						<Button
							fullWidth
							color="primary"
							variant="contained"
							type="submit"
						>
							Update
						</Button>
						<Button
							fullWidth
							color={"error"}
							variant="contained"
							onClick={deleteNoteData}
						>
							Delete
						</Button>
					</Stack>
				</Stack>
			</Drawer>
		</>
	);
};

export default RestaurantProfileOverview;
