import http from "./httpService";
import { useQuery } from "react-query";

const getSpecialities = () => {
  return http.get(`/additional-info/specialities`);
};

export const useGetSpecialities = () => {
  return useQuery("get-specialities", () => getSpecialities());
};
