import L from "leaflet";
import pick from "../../../assets/pick.svg";

const customisedPicker = (size) =>
  new L.Icon({
    iconUrl: pick,
    iconSize: size,
    shadowSize: 400,
  });

export { customisedPicker };
