import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "./theme";

const Title = ({ text, ...others }) => {
  /**
   *
   * A Reusable Component for Titles of the Application
   * A Developer will need to pass the Title with the prop text
   * will Render Title with Responsive Underline
   *
   */
  return (
    <Box width={"fit-content"} marginX={"auto"} display={'flex'} flexDirection={'column'} alignItems={'center'} {...others}>
      <Typography variant="header1" sx={{ textTransform: "capitalize" }}>
        {text}
      </Typography>
      <Box
        width={{ xs: "40px", md: "80px" }}
        marginX={"auto"}
        marginY={1}
        sx={{
          border: {
            xs: `1px solid ${theme.palette.primary.main}`,
            md: `2px solid ${theme.palette.primary.main}`,
          },
          background: theme.palette.primary.main,
          borderRadius: "20px",
        }}
      ></Box>
    </Box>
  );
};

export default Title;

/**
 *
 *
 * Create - Tam
 * Desc: Added at the Top
 */
