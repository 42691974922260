import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import DateRangePicker from "../../components/DateRangePicker";
import moment from "moment";

// icons
import { MdArrowDropDown } from "react-icons/md";

const ActivityLog = () => {
  const [info] = useState([
    {
      date: "Wed, Jun 24, 2021 ",
      user: "Vendor  Admin ",
      name: "Table Create",
      change:
        "In publishing and graphic design, Lorem ipsum. Ea in laborum laboris ipsum id adipisicing qui commodo proident qui.",
      status: "Published",
    },
    {
      date: "Wed, Jun 24, 2021 ",
      user: "Vendor  Admin ",
      name: "Table Create",
      change: "In publishing and graphic design, Lorem ipsum.",
      status: "Published",
    },
    {
      date: "Wed, Jun 24, 2021 ",
      user: "Vendor  Admin ",
      name: "Table Create",
      change: "In publishing and graphic design, Lorem ipsum.",
      status: "Published",
    },
  ]);
  const [range, setRange] = React.useState({
    startDate: moment().add(-7, "days").toDate(),
    endDate: moment().toDate(),
  });

  return (
    <>
      {" "}
      <Box sx={{ width: "90vw", margin: "0 auto" }}>
        <DateRangePicker
          startDate={range.startDate}
          endDate={range.endDate}
          setDateRange={setRange}
          buttonProps={{ sx: { width: "fit-content", my: 2 }, color: "black" }}
        />
        <TableContainer
          component={Paper}
          sx={
            {
              /*"-ms-overflow-style": { xs: "none", md: "unset" },
    scrollbarWidth: { xs: "none", md: "unset" },
    "&::-webkit-scrollbar": { display: { xs: "none", md: "unset" } },*/
              // width: { xs: 600 },
            }
          }
        >
          <Table
            sx={{
              width: { xs: "700px", md: "100%" },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#E3E3E3" }}>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">User</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Previous event</TableCell>
                <TableCell align="center">Changed event</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {info.map((info, id) => (
                <TableRow
                  key={id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {info.date}
                  </TableCell>
                  <TableCell align="center">{info.user}</TableCell>
                  <TableCell align="center">{info.name}</TableCell>
                  <TableCell align="center" sx={{ width: { xs: "400px" } }}>
                    <Accordion sx={{ width: "100%", mx: "auto" }}>
                      <AccordionSummary
                        expandIcon={
                          <MdArrowDropDown style={{ fontSize: "1.2rem" }} />
                        }
                      >
                        <Typography
                          sx={{ fontSize: { xs: 12, sm: 14 } }}
                          color="red"
                        >
                          {info.change.slice(0, 30)}...
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          textAlign={"center"}
                          sx={{ fontSize: { xs: 12, sm: 14 } }}
                        >
                          {info.change}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                  <TableCell align="center" sx={{ width: { xs: "400px" } }}>
                    <Accordion sx={{ width: "100%", mx: "auto" }}>
                      <AccordionSummary
                        expandIcon={
                          <MdArrowDropDown style={{ fontSize: "1.2rem" }} />
                        }
                      >
                        <Typography
                          sx={{ fontSize: { xs: 12, sm: 14 } }}
                          color="green"
                        >
                          {info.change.slice(0, 30)}...
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>{info.change}</AccordionDetails>
                    </Accordion>
                  </TableCell>
                  <TableCell align="center">{info.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ActivityLog;
/**
 * create by masum
 */
