import React, { createContext } from "react";
import authContext from "./authContext";

const userContext = createContext();

export const UserProvider = ({ children }) => {
	const authCntxt = React.useContext(authContext);

	return (
		<userContext.Provider
			value={{
				userInfo: {
					...authCntxt?.user,
					VendorId: String(authCntxt?.user?.vendorId),
					UserId: String(authCntxt?.user?.userId),
				},
			}}
		>
			{children}
		</userContext.Provider>
	);
};

export default userContext;
