import React from "react";
import { Divider, Stack, Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";

// components
import CustomisedInput1 from "../../components/CustomisedInput1";
// context
import snackContext from "../../context/SnackProvider";

// API
import { useUpdateTable, useDeleteTable } from "../../service/tableService";
import { responseHandler } from "../../utilities/responseHandler";
/**
 *
 * Here need a props, comming from TableView.js file.
 * Using this props upload some table information through api end point.
 *
 */
const UpdateTableFormForFloor = ({
  info,
  vendorId,
  floorId,
  handleClose,
  ...others
}) => {
  const snack = React.useContext(snackContext);
  const { handleSubmit, register } = useForm();
  const { mutateAsync } = useUpdateTable();
  const { mutateAsync: deleteTable } = useDeleteTable();

  const updateTableInfo = async (data) => {
    // console.log(info.tableId);
    // mutateAsync({ vendorId, floorId, data });
    const res = await responseHandler(
      () =>
        mutateAsync({
          vendorId,
          floorId,
          data: {
            ...data,
            tableId: info.tableId,
            isBlocked: false,
          },
        }),
      200
    );
    if (res.status) {
      handleClose();
      snack.setSaverity("success");
      snack.setMessage("Updated successfully.");
      snack.handleClick();
    } else {
      snack.setSaverity("error");
      snack.setMessage(res.data);
      snack.handleClick();
    }
  };

  const deleteTableFun = async (vendorId, floorId, info) => {
    // console.log(vendorId, floorId, info.tableId);
    const res = await responseHandler(
      () => deleteTable({ vendorId, floorId, tableId: info.tableId }),
      204
    );
    if (res.status) {
      handleClose();
      snack.setSaverity("success");
      snack.setMessage("Deleted successfully.");
      snack.handleClick();
    } else {
      snack.setSaverity("error");
      snack.setMessage(res.data);
      snack.handleClick();
    }
  };

  return (
    <>
      {/* <form> */}
      <Stack
        {...others}
        component={"form"}
        onSubmit={handleSubmit(updateTableInfo)}
      >
        <Typography variant="cardHeader">Table Properties</Typography>
        <Divider sx={{ my: 1 }} />
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="cardHeader">Table No.</Typography>
          <CustomisedInput1
            sx={{ width: "3rem" }}
            type="number"
            required
            defaultValue={info.tableNo}
            {...register("tableNo", { valueAsNumber: true })}
          />
        </Stack>
        <Divider sx={{ my: 1 }} />
        {/* <Stack spacing={2}>
          <Typography variant="cardHeader">Table Type</Typography>
          <Select fullWidth size={"small"} value={info.tblType}>
            <MenuItem value="regular">Regular</MenuItem>
            <MenuItem value="special">Special</MenuItem>
            <MenuItem value="irregular">Irregular</MenuItem>
          </Select>
        </Stack>
        <Divider sx={{ my: 1 }} /> */}
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="cardHeader">Min Party</Typography>
          <CustomisedInput1
            sx={{ width: "3rem" }}
            type={"number"}
            required
            defaultValue={info.minParty}
            {...register("minParty", { valueAsNumber: true })}
          />
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="cardHeader">Max Party</Typography>
          <CustomisedInput1
            sx={{ width: "3rem" }}
            type={"number"}
            required
            defaultValue={info.maxParty}
            {...register("maxParty", { valueAsNumber: true })}
          />
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Button variant="contained" fullWidth type="submit">
          Update
        </Button>
        <Button
          variant="contained"
          color={"error"}
          fullWidth
          sx={{
            marginTop: 1,
          }}
          onClick={() => deleteTableFun(vendorId, floorId, info)}
        >
          Delete
        </Button>
      </Stack>
      {/* </form> */}
    </>
  );
};

export default UpdateTableFormForFloor;

/**
 *
 * this file export for create table section for floor management section.
 *
 * developed by noman :)
 */
