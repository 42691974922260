import React, { useState } from "react";
import {
	Box,
	Button,
	Grid,
	Hidden,
	Stack,
	Dialog,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// icons
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

// components
import CreateTableFormForFloor from "./CreateTableFormForFloor";
import DrawerForFloorAndTableBlock from "./DrawerForFloorAndTableBlock";
import RitSelect from "../../components/RitSelect";
import Tables from "./TableView";
// contex
import userContext from "../../context/userInfo";
import snackContext from "../../context/SnackProvider";

// API
import {
	useGetAllFloor,
	useCreateFloor,
	useUpdateFloor,
	useDeleteFloor,
} from "../../service/floorService";
import { responseHandler } from "../../utilities/responseHandler";
import { useGetFloorInfo } from "../../service/floorService";
import { FaBullseye } from "react-icons/fa";

const FloorAndTableCreation = () => {
	// auth context
	const { userInfo } = React.useContext(userContext);

	// snack context
	const snack = React.useContext(snackContext);

	// state for floor select menu.

	const [floor, setFloor] = React.useState();

	// Show and hide table creation section
	const [showTableCreation, setShowTableCreation] = useState(true);
	// Shop and hide table for md and small screen.
	const [open, setOpen] = React.useState(false);
	// State for select a specifiq table
	const [tableSelected, setTableSelected] = React.useState("");

	// State for drawer open and close for Block floor and table.
	const [drawerOpen, setDrawerOpen] = useState(false);
	const handleDrawer = () => {
		setDrawerOpen(!drawerOpen);
	};

	// make responsive condition.
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down("md"));

	const [floorList, setFloorList] = React.useState([]);
	const [tableList, setTableList] = React.useState([]);

	// get all floor use query
	const { data: allFloorData } = useGetAllFloor(userInfo.VendorId);

	// create floor
	const { mutateAsync: createFloor } = useCreateFloor();

	// update floor
	const { mutateAsync: updateFloor } = useUpdateFloor();

	// delete floor
	const { mutateAsync: deleteFloor } = useDeleteFloor();

	React.useEffect(() => {
		if (allFloorData) {
			const tempList = [];
			allFloorData.data.map((flr) => {
				tempList.push({
					title: flr.floorName,
					value: flr.id,
				});
			});
			setFloorList(tempList);
		}
	}, [allFloorData]);

	// get all floor use query
	const { data: getFloorInfo } = useGetFloorInfo({
		vendorId: userInfo.VendorId,
		floorId: floor ? floor.value : undefined,
	});

	React.useEffect(() => {
		setShowTableCreation(true);
	}, [floor]);

	React.useEffect(() => {
		if (!getFloorInfo) return;
		setTableList(getFloorInfo.data);
	}, [getFloorInfo]);

	return (
		<Box sx={{ width: "98%", maxWidth: "2000px", m: "0 auto" }}>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				alignItems={"center"}
				mt={2}
				mb={2.8}
			>
				<RitSelect
					menuList={floorList}
					placeholder={"Select Floor"}
					id={"floor-creation"}
					onSelect={(data) => {
						setFloor(data);
						setShowTableCreation(true);
					}}
					onAdd={async (data) => {
						if (data.title) {
							const res = await responseHandler(
								() =>
									createFloor({
										vendorId: userInfo.VendorId,
										data: {
											floorName: data.title,
										},
									}),
								201
							);
							if (res.status) {
								setFloor();
								snack.setSaverity("success");
								snack.setMessage("Floor Created Successfully!!");
								snack.handleClick();
							} else {
								snack.setSaverity("error");
								snack.setMessage(res.data);
								snack.handleClick();
							}
						}
					}}
					onUpdate={async (data) => {
						if (data.title) {
							setFloor();
							const res = await responseHandler(() =>
								updateFloor({
									vendorId: userInfo.VendorId,
									data: {
										floorId: data.info.value,
										floorName: data.title,
									},
								})
							);
							if (res.status) {
								snack.setSaverity("success");
								snack.setMessage("Updated Successfully!!");
								snack.handleClick();
							} else {
								snack.setSaverity("error");
								snack.setMessage(res.data);
								snack.handleClick();
							}
						}
					}}
					onDelete={async (data) => {
						if (data.value) {
							const res = await responseHandler(
								() =>
									deleteFloor({
										vendorId: userInfo.VendorId,
										floorId: data.value,
									}),
								204
							);
							if (res.status) {
								snack.setSaverity("success");
								snack.setMessage("Floor: " + data.title + " Deleted!!");
								snack.handleClick();
							} else {
								snack.setSaverity("error");
								snack.setMessage(res.data);
								snack.handleClick();
							}
						}
					}}
				/>
				{/* <Stack
          direction="row"
          spacing={{ xs: 0, md: 2 }}
          justifyContent="space-around"
        > */}
				{floor ? (
					<>
						<Stack
							direction={"row"}
							spacing={1}
						>
							{/* <Button
                variant="contained"
                color="secondary"
                size={mdDown ? "small" : "large"}
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                Block
              </Button> */}
							<Button
								endIcon={
									!mdDown && !showTableCreation ? (
										<MdArrowDropUp style={{ fontSize: "1.5rem" }} />
									) : (
										<MdArrowDropDown style={{ fontSize: "1.5rem" }} />
									)
								}
								size={mdDown ? "small" : "large"}
								onClick={() => {
									setShowTableCreation(!showTableCreation);
									if (mdDown) {
										setOpen(true);
									} else {
										setOpen(false);
									}
								}}
								variant={"outlined"}
								sx={{
									fontWeight: "bold",
									"& .MuiButton-endIcon": {
										display: { xs: "none", md: "inherit" },
									},
								}}
							>
								Create table
							</Button>
						</Stack>
					</>
				) : (
					<></>
				)}
				{/* </Stack> */}
			</Stack>
			<Grid
				container
				direction={"row"}
				justifyContent={"center"}
				// alignItems={"center"}
				sx={
					{
						// height: "calc(100vh - 300px)",
						// mt: 1,
					}
				}
				columnSpacing={3}
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={showTableCreation ? 12 : 8}
					lg={showTableCreation ? 12 : 9}
				>
					<Box
						sx={{
							boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
							height: { xs: "calc(100vh - 150px)", md: "calc(100vh - 170px)" },
							p: 2,
						}}
					>
						{floor ? (
							<>
								<Stack
									direction="row"
									flexWrap="wrap"
									alignItems={"center"}
									justifyContent={{ xs: "center", md: "flex-start" }}
									justifyItems={"center"}
									flexBasis="content"
									rowGap={1}
									columnGap={2}
								>
									{tableList?.map((table) => (
										<Tables
											key={table.tableId}
											info={table}
											vendorId={userInfo.VendorId}
											floorId={floor ? floor.value : undefined}
											tableSelected={tableSelected}
											setTableSelected={setTableSelected}
										/>
									))}
								</Stack>
							</>
						) : (
							<Stack
								direction={"column"}
								height={"100%"}
								alignItems={"center"}
								justifyContent={"center"}
							>
								<Typography
									variant={"button"}
									sx={{ fontSize: "1.5rem" }}
								>
									Select Floor
								</Typography>
								<Typography variant={"button"}>TO VIEW TABLES</Typography>
							</Stack>
						)}
					</Box>
				</Grid>
				{/* Create table form */}
				<Hidden mdDown>
					<Grid
						item
						xs={0}
						sm={0}
						md={4}
						lg={3}
						sx={{
							// bgcolor: "blue",
							display: `${showTableCreation ? "none" : "show"}`,
							transitionDelay: "5000ms",
						}}
					>
						<Box
							sx={{
								boxShadow: "0px 4px 33px rgba(0, 0, 0, 0.1)",
								height: "content-height",
								p: 2,
							}}
						>
							<CreateTableFormForFloor
								vendorId={userInfo.VendorId}
								floor={floor ? floor.value : undefined}
								onClose={() => setShowTableCreation(!showTableCreation)}
							/>
						</Box>
					</Grid>
				</Hidden>
			</Grid>
			<Hidden mdUp>
				<Dialog
					open={!showTableCreation}
					onClose={() => setShowTableCreation(!showTableCreation)}
					PaperProps={{
						sx: { width: "100%", padding: "16px", margin: "10px" },
					}}
				>
					<CreateTableFormForFloor
						vendorId={userInfo.VendorId}
						floor={floor ? floor.value : undefined}
						onClose={() => setShowTableCreation(!showTableCreation)}
					/>
					<Button
						variant={"outlined"}
						color={"error"}
						sx={{
							marginTop: 1,
						}}
						onClick={() => setShowTableCreation(!showTableCreation)}
					>
						Cancel
					</Button>
				</Dialog>
			</Hidden>
			{/* Drawer component. */}
			<DrawerForFloorAndTableBlock
				open={drawerOpen}
				close={handleDrawer}
			/>
		</Box>
	);
};

export default FloorAndTableCreation;

/**
 *
 * using this component vendor admin can add table, block certain amount of table and floor for specipic date.
 * All action happend in a api call.
 *
 * developed by Noman :)
 */
