const toggleModal = (
  closingModalState, // Modal need to be close's state
  openingModalState // Modal need to be open's state
) => {
  closingModalState(false); // Close a modal
  openingModalState(true); // Open a modal
};

export default toggleModal;
/**
 *
 * Toggle Modal Handler
 * Desc: Added in the code
 *
 *  Create - Tam
 *
 */
