import { Box, Typography, Dialog } from "@mui/material";
import React, { useState } from "react";
import CBox from "../../../components/CBox";
import ChatBox from "./ChatBox";
import Title from "../../../components/Title";

const Feedback = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Title text={"Feedback"} />
      <Box width={"80vw"} margin="0 auto">
        <Typography
          variant="header1"
          color="initial"
          sx={{ textAlign: "left" }}
        >
          Message List
        </Typography>
        <CBox sx={{ mt: 1 }}>
          <Box
            sx={{
              display: "flex",
              gap: { lg: 10, md: 10, xs: 3 },
              backgroundColor: "#F1F1F1",
              p: 0.7,
              py: 0.5,
              borderRadius: "2px",
            }}
          >
            <Typography variant="legend" color="initial">
              Contact Person
            </Typography>
            <Typography variant="legend" color="initial">
              Message
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: { lg: 12.7, md: 12.7, sm: 6, xs: 5 },
              p: 0.7,
            }}
          >
            <Typography variant="legend" color="initial">
              ReserveitBD
            </Typography>
            <Typography
              //   variant="cardLocation"
              sx={{
                width: "100%",
                fontSize: {
                  xs: "12px",
                  mxs: "14px",
                  md: "16px",
                },
              }}
              color="#1D2EFF"
              onClick={handleOpen}
            >
              {` Et veniam commodo voluptate pariatur. Sit nostrud voluptate duis
              commodo consectetur. Minim officia laboris veniam ut qui eiusmod
              est consectetur aliquip esse aute fugiat proident. Elit officia et
              exercitation enim ullamco. Dolore commodo labore incididunt nulla
              aute.`}
            </Typography>
          </Box>
        </CBox>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={"body"}
          PaperProps={{
            sx: {
              m: 0,
              width: "100vw",
              maxWidth: "600px",
            },
          }}
        >
          <ChatBox onClose={handleClose} />
        </Dialog>
      </Box>
    </>
  );
};

export default Feedback;
/**
 * create by masum
 */
