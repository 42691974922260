import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Paper,
  Stack,
} from "@mui/material";
import moment from "moment";
import React from "react";
import DatePicker from "sassy-datepicker";
import theme from "./theme";
import { FiArrowRight } from "react-icons/fi";

const DateRangePicker = ({
  startDate,
  endDate,
  setDateRange,
  buttonProps,
  paperProps,
  onSubmit,
  ...others
}) => {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState("Last 7 days");
  const [rerender, setRerender] = React.useState(true);
  const handleClose = () => setOpen(!open);
  const [range, setRange] = React.useState({
    startDate: startDate || moment().add(-7, "days").toDate(),
    endDate: endDate || moment().toDate(),
  });
  // console.log("...");
  // console.log(startDate);
  // console.log(endDate);
  const [rangeList, setRangeList] = React.useState([
    {
      title: "Today",
      func: () => {
        setRange({
          startDate: moment().toDate(),
          endDate: moment().toDate(),
        });
      },
    },
    {
      title: "Last 7 days",
      func: () => {
        setRange({
          startDate: moment().subtract(7, "days").toDate(),
          endDate: moment().toDate(),
        });
      },
    },
    {
      title: "Last 14 days",
      func: () => {
        setRange({
          startDate: moment().subtract(14, "days").toDate(),
          endDate: moment().toDate(),
        });
      },
    },
    {
      title: "Last 30 days",
      func: () => {
        setRange({
          startDate: moment().subtract(30, "days").toDate(),
          endDate: moment().toDate(),
        });
      },
    },
    {
      title: "Last 3 Months",
      func: () => {
        setRange({
          startDate: moment().subtract(3, "months").toDate(),
          endDate: moment().toDate(),
        });
      },
    },
    {
      title: "Last 6 Months",
      func: () => {
        setRange({
          startDate: moment().subtract(6, "months").toDate(),
          endDate: moment().toDate(),
        });
      },
    },
    {
      title: "Last 1 year",
      func: () => {
        setRange({
          startDate: moment().subtract(1, "years").toDate(),
          endDate: moment().toDate(),
        });
      },
    },
    {
      title: "All",
      func: () => {
        setRange({
          startDate: moment("Jan 01 2000").toDate(),
          endDate: moment().toDate(),
        });
      },
    },
  ]);

  React.useEffect(() => {
    if (!rerender) setRerender(true);
  }, [rerender]);

  return (
    <>
      <Button variant={"outlined"} onClick={handleClose} {...buttonProps}>
        {moment(startDate).format("MM/DD/YYYY")} -{" "}
        {moment(endDate).format("MM/DD/YYYY")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: { xs: "95vw", sm: "fit-content" },
            maxWidth: { xs: "380px", sm: "680px" },
            mx: "auto",
          },
          ...paperProps,
        }}
        {...others}
      >
        <DialogContent sx={{ p: 0 }}>
          <Stack direction={"row"}>
            <Stack
              direction={"column"}
              alignItems={"flex-start"}
              justifyContent={"center"}
              px={1}
            >
              {rangeList?.map((item, index) => (
                <Button
                  color={"black"}
                  disabled={selected === item.title}
                  disableRipple
                  sx={{
                    textTransform: "none",
                    textAlign: "left",
                    py: 0.5,
                    px: { xs: 0, sm: 1 },
                  }}
                  onClick={() => {
                    item.func();
                    setSelected(item.title);
                    setRerender(false);
                  }}
                  key={index}
                >
                  {item.title}
                </Button>
              ))}
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack direction={"column"}>
              <Stack direction={{ xs: "column", sm: "row" }}>
                <Box
                  sx={{
                    width: "fit-content",
                    mx: "auto",
                    borderRadius: "10px",
                    overflow: "hidden",
                    // Style for sassy datepicker
                    "& .sdp": {
                      boxShadow: "none",
                    },
                    "& .sdp--square-btn__shadowed": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                    "& .sdp--date-btn__selected": {
                      backgroundColor: theme.palette.primary.main,
                    },
                    "& .sdp--text__inactive:not(.sdp--date-btn)": {
                      color: theme.palette.primary.light,
                      fontWeight: "bold",
                      fontSize: "0.9em",
                    },
                  }}
                >
                  {rerender ? (
                    <DatePicker
                      selected={range.startDate}
                      onChange={(newDate) => {
                        setRange({
                          ...range,
                          startDate: moment(newDate).toDate(),
                        });
                        setSelected("");
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box
                  sx={{
                    width: "fit-content",
                    mx: "auto",
                    borderRadius: "10px",
                    overflow: "hidden",
                    // Style for sassy datepicker
                    "& .sdp": {
                      boxShadow: "none",
                    },
                    "& .sdp--square-btn__shadowed": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                    "& .sdp--date-btn__selected": {
                      backgroundColor: theme.palette.primary.main,
                    },
                    "& .sdp--text__inactive:not(.sdp--date-btn)": {
                      color: theme.palette.primary.light,
                      fontWeight: "bold",
                      fontSize: "0.9em",
                    },
                  }}
                >
                  {rerender ? (
                    <DatePicker
                      selected={range.endDate}
                      onChange={(newDate) => {
                        setRange({
                          ...range,
                          endDate: moment(newDate).toDate(),
                        });
                        setSelected("");
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              </Stack>
              <Divider flexItem />
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={1}
                sx={{
                  p: 1,
                }}
              >
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Button variant={"outlined"} color={"black"} size={"small"}>
                    {moment(range.startDate).format("MM/DD/YYYY")}
                  </Button>
                  <FiArrowRight />
                  <Button variant={"outlined"} color={"black"} size={"small"}>
                    {moment(range.endDate).format("MM/DD/YYYY")}
                  </Button>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  width={{ xs: "100%", sm: "fit-content" }}
                  justifyContent={{ xs: "space-evenly", sm: "center" }}
                  spacing={1}
                >
                  <Button size={"small"} onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant={"contained"}
                    size={"small"}
                    onClick={() => {
                      // setStartDate(range.startDate);
                      // setEndDate(range.endDate);
                      setDateRange({
                        startDate: range.startDate,
                        endDate: range.endDate,
                      });
                      if (typeof onSubmit === "function") {
                        onSubmit(range.startDate, range.endDate);
                      }
                      handleClose();
                    }}
                  >
                    Set Date
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DateRangePicker;
